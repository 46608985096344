
























import Vue from 'vue';
import Editor from '@tinymce/tinymce-vue';
import { mdiContentSave, mdiSync } from '@mdi/js';
import PDFController from '@/controller/pdf';
import { PDFDesign } from '@/models';
import UI from '@/controller/ui';
import { PDFDesingType } from '@/enums';

export default Vue.extend({
  name: 'PDFDesigner',
  components: {
    Editor,
  },
  data: () => ({
    icons: {
      mdiContentSave,
      mdiSync,
    },
    editorConfig: {
      plugins: [
        'save',
        'autolink lists',
        'image',
        'preview table link',
      ],
      save_onsavecallback: function () {
        console.log('Saved');
      },
      height: 800,
      toolbar: [
        'formatselect | fontselect | removeformat | checklist | casechange | link',
        'bold italic strikethrough forecolor backcolor formatpainter | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent ',
      ],
      content_style: `p {
  margin: 5px 0 0 0;
}`,
    },
    controller: new PDFController(),
    design: new PDFDesign(),
  }),
  async mounted() {
    const pdfDesing = await this.controller.getPDFDesign(PDFDesingType.Invoice);
    if (!pdfDesing) {
      this.design.template = await this.controller.getDefaultPDFDesign(PDFDesingType.Invoice);
      this.design.type = PDFDesingType.Invoice;
      this.design = await this.controller.pdfDesignInsert(this.design);
    } else {
      this.design = pdfDesing;
    }
  },
  methods: {
    async save() {
      await this.controller.pdfDesignUpdate(this.design);
      UI.snackOpen(__('Success'));
    },
    async reset() {
      if (confirm('Möchten Sie wirklich zurücksetzen?')) {
        this.design.template = await this.controller.getDefaultPDFDesign(PDFDesingType.Invoice);
        await this.save();
      }
    },
  },
});
