var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"headers":_vm.headers,"editable":false,"deletable":false,"creatable":false,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading,"page":_vm.page},on:{"update":_vm.update,"delete":_vm.deleting,"create":_vm.create,"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('DatePicker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('BranchSelector',{model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{attrs:{"fab":"","color":"primary","small":""},on:{"click":_vm.send}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiEmailSendOutline)+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-checkbox',{attrs:{"label":"Wählen Sie Alle"},on:{"change":_vm.selectAll}})],1)],1)],1)]},proxy:true},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.change(item)}},model:{value:(item.meta.isActive),callback:function ($$v) {_vm.$set(item.meta, "isActive", $$v)},expression:"item.meta.isActive"}})]}},{key:"end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.dateMergerTime(item.end_date, item.end_time))+" ")]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.first_name + ' ' + item.customer.last_name)+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(_vm.checkError(item))?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm._f("trans")('ReportStatus.Error'))+" ")]):(!item.inform_sended)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm._f("trans")('ReportStatus.Waiting'))+" ")]):_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm._f("trans")('ReportStatus.Success')))])]}},{key:"result_pdf",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","color":"info","small":""},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileDownloadOutline)+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }