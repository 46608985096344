import { repoName } from '@/controller/reflections'
import { Import } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('ImportRepository')
export default class ImportRepository extends Repository<Import> {
  constructor() {
    super('imports')
  }
  public convertType(value: object): Import {
    return Object.assign(new Import(), value)
  }
}
