








import Vue from 'vue'
import BranchGroupTable from './components/branch-group-table.vue'

export default Vue.extend({
  name: 'LocationGroups',

  components: {
    BranchGroupTable,
  },

  mounted() {},
})
