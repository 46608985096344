import { OrderBy, PublicationStatus } from '@/enums';
import IStrapiParam from './istrapi-param';

export default class Publication implements IStrapiParam {
  constructor(status: PublicationStatus = PublicationStatus.Unpublished) {
    this.value = status;
  }

  public paramType: string = 'Publication';
  public name: string = 'publicationState';
  public value: string = 'preview';
  public index: number = 0;
  public url(): string {
    return `${this.name}=${this.value}`;
  }
}
