import { repoName } from '@/controller/reflections'
import BranchPermission from '@/models/branch-permission'
import { Repository } from '@/uow/unit-of-work'

@repoName('BranchPermissionRepository')
export default class BranchPermissionRepository extends Repository<BranchPermission> {
  constructor() {
    super('branch-permissions')
  }
  public convertType(value: object): BranchPermission {
    return Object.assign(new BranchPermission(), value)
  }
}
