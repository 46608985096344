












































































































































































import Cache from '@/controller/cache';
import Session from '@/controller/session';
import {
  mdiAccountOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiFileCogOutline,
  mdiHelpCircleOutline,
  mdiInformationOutline,
  mdiLogoutVariant,
  mdiReact,
} from '@mdi/js';
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    avatar: true,
    icons: {
      mdiAccountOutline: mdiAccountOutline,
      mdiFileCogOutline: mdiFileCogOutline,
      mdiCheckboxMarkedOutline: mdiCheckboxMarkedOutline,
      mdiReact: mdiReact,
      mdiCogOutline: mdiCogOutline,
      mdiCurrencyUsd: mdiCurrencyUsd,
      mdiHelpCircleOutline: mdiHelpCircleOutline,
      mdiLogoutVariant: mdiLogoutVariant,
      mdiInformationOutline: mdiInformationOutline,
    },
    isOnline: 'success',
    url: '',
  }),
  mounted() {
    if (Session.isCustomer()) {
      this.url = (Cache.User as any).branch.url;
    }
    this.$root.$eventBus.$on('refreshPP', this.refreshPP);
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
  },

  beforeDestroy() {
    this.$root.$eventBus.$off('refreshPP', this.refreshPP);
    window.removeEventListener('offline', this.setOffline);
    window.removeEventListener('online', this.setOnline);
  },
  methods: {
    logout() {
      Session.logout();
      this.$router.push({ name: 'Login', params: { url: this.url } });
    },
    setOffline() {
      this.isOnline = 'error';
    },
    setOnline() {
      this.isOnline = 'success';
    },
    refreshPP() {
      this.avatar = false;
      this.$nextTick(() => {
        this.avatar = true;
      });
    },
  },
});
