import { notMapped } from '@/controller/reflections';
import { PaymentMethodType } from '@/enums';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class PaymentMethod extends ICU {
  public code: string                  = '';
  public client_id: string             = '';
  public client_secret: string         = '';
  public sandbox_code: string          = '';
  public sandbox_client_id: string     = '';
  public sandbox_client_secret: string = '';
  public live: boolean                 = false;
  public status: boolean               = false;
  public type: PaymentMethodType       = PaymentMethodType.Paypal;
  @notMapped()
  public test_center: TestCenter = new ICU() as TestCenter;
}
