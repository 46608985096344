import Configs from '@/config/config';
import { display, hidden, notMapped } from '@/controller/reflections';
import { InvoceStatus } from '@/enums';
import moment from 'moment';
import Appointment from './appointment';
import Customer from './customer';
import File from './file';
import { ICU } from './imodels';
import JsonObject from './json-object';
import TestCenter from './test-center';

export default class InvoiceCustomer extends ICU {
  @display('#')
  public uid: string = '';
  @display('Model.StartDate', true)
  public start_date: string = moment().format(Configs.dateTimeServerFormat);
  @notMapped()
  @display('Model.Customer', true)
  public customer: Customer = new ICU() as Customer;
  @hidden()
  public meta: JsonObject = new JsonObject();
  @display('Model.Status', true)
  public status: InvoceStatus = InvoceStatus.Waiting;
  @display('Financial.Total', true)
  public total: number = 0;

  @display('PDF')
  @notMapped()
  public pdf: File = new File();
  //#region releations

  @notMapped()
  @hidden()
  public test_center: TestCenter = new ICU() as TestCenter;
  @notMapped()
  @hidden()
  public appointment: Appointment = new ICU() as Appointment;
  //#endregion
}
