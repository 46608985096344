import { display, hidden, notMapped, sanitize } from '@/controller/reflections';
import _ from 'lodash';
import { warn } from 'vue-class-component/lib/util';

export interface IModel {
  id: number;
}

export interface IRole extends IModel {
  name: string;
  description: string;
  type: string;
  created_at: Date;
  updated_at: Date;
}

export interface IThumbnail extends IModel {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path?: any;
  url: string;
}

export interface ISmall extends IModel {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path?: any;
  url: string;
}

export interface IFormats extends IModel {
  thumbnail: IThumbnail;
  small: ISmall;
}

export interface IImage extends IModel {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: IFormats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: string;
  provider: string;
  provider_metadata?: any;
  created_at: Date;
  updated_at: Date;
}

export class ICU implements IModel {
  @display('Model.ID')
  @hidden()
  public id: number = 0;

  @display('Model.CreatedAt', true)
  @hidden()
  @notMapped()
  public createdAt?: Date = new Date();

  @display('Model.UpdatedAt', true)
  @hidden()
  @notMapped()
  public updatedAt?: Date = new Date();

  // @hidden()
  // public createdBy?: number = -1

  // @hidden()
  // public updatedBy?: number = -1

  public compress(): this {
    return this.id as any;
  }

  public static create(): any {
    return new this();
  }
  public selfnormalize(): this {
    return { id: this.id, data: _.omit(this, 'id') } as any as this;
  }
  /**
   * Eğer package fonksiyonunu kullanıyorsan öğenlerini compress etmen gerekmez
   * @param all_clear
   * @param array_clear
   * @returns detaylardan arındırılmış modeli geri döner
   */
  public package(all_clear: boolean = false, array_clear: boolean = true): this {
    let result = sanitize(this, prop => {
      if (all_clear) {
        return undefined;
      }
      if (Object.prototype.hasOwnProperty.call(prop, 'id')) {
        try {
          if (prop) {
            if (prop.id == 0) {
              return undefined;
            }

            if (_.isObject(prop)) {
              return (prop as any).id;
            }
          }
          return prop;
        } catch (e) {
          console.error('package', e);

          return undefined;
        }
      } else if (_.isArray(prop) && array_clear) {
        return undefined;
      } else if (_.isArray(prop)) {
        return prop.map(x => {
          if (_.isObject(x)) return (x as any).id;
          return x;
        });
      } else {
        return prop;
      }
    });
    return result as this;
  }
}
