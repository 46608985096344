






































































































































































































































































































































































































































































































































import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';

import {
  mdiCalendarRemoveOutline,
  mdiCalendarOutline,
  mdiClockTimeNineOutline,
  mdiChevronRight,
  mdiClockRemoveOutline,
  mdiChevronLeft,
  mdiPrinter,
  mdiCalendar,
  mdiAccountOutline,
  mdiLogin,
  mdiLogout,
} from '@mdi/js';
import ThemeSwitcher from '@/layouts/components/theme-switcher.vue';
import Impressum from '@/components/impressum.vue';
import DatetimePicker from '@/components/datetime-picker.vue';
import ProductSelector from '@/components/product-selector.vue';
import StringUtils from '@/utils/string-utils';
import { Branch, Customer, PaymentMethod, Product, TimeSlot } from '@/models';
import moment from 'moment';
import Configs from '@/config/config';
import _ from 'lodash';
import UI from '@/controller/ui';
import BookingController from '@/controller/booking';
import CustomeFields from '@/components/custome-fields/index.vue';
import JsonObject from '@/models/json-object';
import { PaymentMethodType, RequiredPdfMode } from '@/enums';
import PaymentController from '@/controller/payment';
import * as py from '@paypal/paypal-js';

export default Vue.extend({
  name: 'Booking',
  components: {
    ThemeSwitcher,
    QrcodeVue,
    Impressum,
    CustomeFields,
    ProductSelector,
    DatetimePicker,
  },
  data: () => ({
    confirm_email: '',
    PaymentMethodType,
    page: {
      logo: null,
      title: null,
      hello_message: null,
      id: null,
    },
    step: 1,
    selectedDateItem: 0,
    selectedHourItem: 0,
    selectedTimeItem: 0,
    icons: {
      mdiAccountOutline,
      mdiLogin,
      mdiLogout,
      mdiChevronRight,
      mdiCalendarOutline,
      mdiCalendarRemoveOutline,
      mdiClockTimeNineOutline,
      mdiClockRemoveOutline,
      mdiChevronLeft,
      mdiPrinter,
      mdiCalendar,
    },
    dateMenu: false,
    date: '',
    dateSlots: <any[]>[],
    timeSlots: <any[]>[],
    hoursSlots: <any[]>[],
    branch: new Branch(),
    timeSlot: new TimeSlot(),
    qrCode: '',
    customer: new Customer(),
    emailrules: [(v: any) => !!v || 'E-mail ist nötig', (v: any) => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein'],
    requiredrules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    requiredradiorules: [(v: any) => v != PaymentMethodType.None || 'Dieses Feld ist erforderlich'],
    validation: false,
    controller: new BookingController(),
    counts: <any[]>[],
    meta: <any>null,
    product: <Product | null>null,
    payment_status: PaymentMethodType.None,
    paymentController: new PaymentController(),
    paypal_status: false,
    payment_method: new PaymentMethod(),
    redirect: false,
    customer_mode: false,
    term_pdf: '#',
    privacy_pdf: '#',
    required_pdf: '#',
    required_pdf_mode: RequiredPdfMode.None,
    RequiredPdfMode,
  }),
  computed: {
    allowStepper() {
      if (!this.allowProduct) return true;
      return this.product;
    },
    allowProduct() {
      return this.branch && this.branch.id != 0 && this.branch.test_center.setting.payment.status && this.step < 4;
    },
    allowCreateAppointment() {
      let salable = this.product ? this.product?.price > 0 : false;
      if (!this.allowProduct && (!this.branch.test_center.setting.payment.status || !salable)) return true;
      return false;
    },
    allowPaymentMethod() {
      let salable = this.product ? this.product?.price > 0 : false;
      return this.branch && this.branch.id != 0 && this.branch.test_center.setting.payment.status && salable;
    },
    allowCreateOrder() {
      return this.payment_status == PaymentMethodType.Spot;
    },
    confirm_email_rule() {
      return [(v: any) => v == this.customer.email || 'E-Mail-Adresse stimmt nicht überein'];
    },
    allowPaypalButton() {
      return this.payment_status == PaymentMethodType.Paypal && this.validation;
    },
  },
  watch: {
    product(n, o) {
      if (this.redirect) {
        this.redirectBooking();
      }
    },
  },
  mounted() {
    let branch_url = this.$route.params.id;
    let type_url = this.$route.params.type;
    let customer_uid = this.$route.params.customer;
    this.redirect = type_url == 'redirect';

    if (StringUtils.isEmpty(branch_url)) {
      this.$router.push('/error-404');
    }

    this.controller.init(branch_url).then(async (data: any) => {
      if (!StringUtils.isEmpty(customer_uid)) {
        this.customer_mode = true;

        this.controller.getCustomer(customer_uid).then(x => {
          if (x) this.customer = x;
          this.confirm_email = this.customer.email;
        });
      }

      let custome_fields = <any[]>[];
      const myFields = new JsonObject();
      myFields.custome_fields = custome_fields;
      this.meta = myFields;

      this.branch = data.branch;
      this.page = data.page;
      this.timeSlot = data.slot;
      this.dateSlots = data.resDateSlots;
      this.timeSlots = data.resTimeSlots;
      this.customer.branch = this.branch.compress();
      let has_property = _.has(this.branch, ['test_center', 'setting', 'payment', 'status']);
      let paid = has_property && this.branch.test_center.setting.payment.status;
      if (paid) {
        await this.paymentController.getPaymentMethods(this.branch.test_center.id).then(c => {
          let index = c.findIndex(x => x.type == PaymentMethodType.Paypal);
          if (index > -1) {
            let paypal = c[index];
            this.payment_method = paypal;
            this.paypal_status = true;
            let client_id = paypal.live ? paypal.client_id : paypal.sandbox_client_id;
            py.loadScript({ 'client-id': client_id, currency: 'EUR' }).catch(err => {
              this.paypal_status = false;
            });
          }
        });
      }
      if (type_url == 'redirect' && !paid) {
        this.redirectBooking();
      }

      if (this.branch.test_center.setting.term_pdf) {
        this.term_pdf = UI.getURLBase(this.branch.test_center.setting.term_pdf.url ?? '#');
      } else {
        // TODO landing page AGB page
        this.term_pdf = this.branch.test_center.setting.term_url ?? '#';
      }

      if (this.branch.test_center.setting.privacy_pdf) {
        this.privacy_pdf = UI.getURLBase(this.branch.test_center.setting.privacy_pdf.url ?? '#');
      } else {
        // TODO landing page AGB page
        this.privacy_pdf = this.branch.test_center.setting.privacy_url ?? '#';
      }
      this.required_pdf_mode = this.branch.test_center.setting.required_pdf_mode;
      if (this.branch.test_center.setting.required_pdf) {
        this.required_pdf = UI.getURLBase(this.branch.test_center.setting.required_pdf.url ?? '#');
      } else {
        // TODO landing page AGB page
        this.required_pdf = '/docs/Einverständniserklärung und Kundeninformation.pdf';
      }
    });
  },
  methods: {
    requiredRule(required: boolean) {
      if (required) return (v: any) => !!v || 'Dieses Feld ist erforderlich';
      else return true;
    },
    gotoLogin() {
      this.$router.push({ name: 'Login' });
    },
    update() {
      location.reload();
    },
    redirectBooking() {
      this.controller.loading = true;
      this.controller.redirect(this.dateSlots, this.calcTimeSlots, this.calcHoursSlots, this.calcMinute);
    },
    validateForm() {
      (this.$refs.customer as any).validate();
    },
    createAppointment() {
      (this.$refs.customer as any).validate();
      if (!this.validation) {
        UI.snackOpen('Füllen Sie bitte dieses Formular aus.', 'error');
        console.warn('Füllen Sie bitte dieses Formular aus.');
        return;
      }
      let time = this.hoursSlots[this.selectedHourItem].text;
      let date = this.dateSlots[this.selectedDateItem].text;
      this.customer.birth_date = this.date as any;
      this.controller
        .createAppointment(this.branch, this.customer, time, date, this.meta, this.payment_status, this.product as any)
        .then(appointment => {
          this.qrCode = appointment.qr_code;
          this.step = 5;
        })
        .catch(x => {
          //error
          console.warn(x);
          let message = x.message;
          message += '\nBitte melden Sie sich an oder wenden Sie sich an Ihr TestCenter';
          UI.snackOpen(message, 'error');

          return false;
        });
    },
    async calcTimeSlots(item: any) {
      this.controller.loading = true;

      let date = item.text;
      let _date = moment(date, Configs.dateFormat).format(Configs.dateServerFormat);
      let now = moment().format(Configs.dateFormat);

      this.counts = await this.controller.getAvailableCountRange('hourly', this.branch.id, _date).then(result => {
        return _.map(_.entries(result.data.attributes), ([k, v]) => ({
          hour: moment(k, Configs.timeServerFormat),
          count: v as number,
        }));
      });

      if (date == now) {
        this.timeSlots = this.timeSlots.map(x => {
          let hour = moment(x.text, 'HH:mm');
          console.log(x, hour);
          if (moment() > hour) {
            x.disabled = true;
            return x;
          }
          x.disabled = false;
          return x;
        });
      } else {
        this.timeSlots = this.timeSlots.map(x => {
          x.disabled = false;
          return x;
        });
      }
      // saatlerin listelendiği yer 08:00

      for (let i = 0; i < this.timeSlots.length; i++) {
        const timeSlot = this.timeSlots[i];
        const hour = moment(timeSlot.text, 'HH:mm');
        let counte = 0;
        let nextTimeSlot = <any>0;
        if (i + 1 < this.timeSlots.length) {
          nextTimeSlot = this.timeSlots[i + 1];
          let _next_hour = moment(nextTimeSlot.text, 'HH:mm');
          let filter = this.counts.filter(x => _next_hour > x.hour && x.hour >= hour).map(x => x.count);
          counte = _.sum(filter) ?? 0;
        } else {
          let filter = this.counts.filter(x => x.hour >= hour).map(x => x.count);
          counte = _.sum(filter) ?? 0;
        }

        let oneHourseMax = this.timeSlot.customer_per_time_slots * 4;
        counte = oneHourseMax - counte;
        counte = counte > 0 ? counte : 0;
        this.timeSlots[i].availableCount = counte;
      }

      this.controller.loading = false;
      this.step = 2;
      return this.timeSlots;
    },
    async calcHoursSlots(value: any) {
      this.controller.loading = true;
      const date = this.dateSlots[this.selectedDateItem];
      const time = value;
      this.hoursSlots = await this.controller.hourSlots(time, this.timeSlot, this.counts);
      this.controller.loading = false;
      this.step = 3;
      return this.hoursSlots;
    },
    async calcMinute(value: any) {
      let index = this.hoursSlots.indexOf(value);
      this.selectedHourItem = index;
      this.step = 4;
      this.$nextTick(() => {
        if (this.paypal_status) this.createPaypal();
      });
    },
    createPaypal() {
      this.paymentController.createPaypal(this.product as any, '#paypal-render', this.pay);
    },
    pay(data?: py.OnApproveData) {
      (this.$refs.customer as any).validate();
      if (!this.validation) {
        UI.snackOpen('Füllen Sie bitte dieses Formular aus.', 'error');
        console.warn('Füllen Sie bitte dieses Formular aus.');
        return;
      }
      if (this.payment_status == PaymentMethodType.Spot) this.createAppointment();
      if (this.payment_status == PaymentMethodType.Paypal) {
        this.meta.paypal_payment = {
          //? daha sonra ihtiyaç olabilir diye bir kayıt
          orderId: data?.orderID,
          payerId: data?.payerID,
        };
        this.createAppointment();
      }
    },
  },
});
