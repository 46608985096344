










































































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { Branch, Group, TestKit } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiEyeOutline, mdiDelete, mdiPencilOutline } from '@mdi/js';
import TestkitsDialog from './testkits-dialog.vue';
import TestkitController from '@/controller/testkit';

export default Vue.extend({
  components: {
    Editable,
    TestkitsDialog,
  },
  data() {
    return {
      title: 'Test-Kit-Verwaltung',
      show: false,
      edit: false,
      selected: new TestKit(),
      controller: new TestkitController(),
      items: <TestKit[]>[],
      icons: {
        mdiEyeOutline,
        mdiDelete,
        mdiPencilOutline,
      },
      activeItems: <TestKit[]>[],
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(TestKit);
      headers.splice(headers.length, 0, {
        value: 'active',
        text: __('Controls.Active'),
        divider: true,
      });
      headers.splice(headers.length, 0, {
        value: 'dcc',
        text: 'Herkunft',
        divider: true,
      });
      headers.splice(headers.length, 0, {
        value: 'edit',
        text: __('Controls.Edit'),
        divider: true,
      });
      headers.splice(headers.length, 0, {
        value: 'delete',
        text: __('Controls.Delete'),
      });
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init().then((x: any) => {
        let all: TestKit[] = x.all;
        let actives: TestKit[] = x.actives;
        this.activeItems = actives;
        this.items = all.map((x: TestKit) => {
          x.default = this.checkDefault(x);
          (x as any).dcc = x.test_center == null;
          return x;
        });
      });
    },
    clearform() {
      this.selected = new TestKit();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (item.default) {
        UI.snackOpen('Default cannot delete.', 'warning');
        return false;
      }
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        this.controller.deleteTestkit(item).then(() => {
          this.refresh();
        });
      }
    },
    create() {
      this.selected = new TestKit();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
    checkDefault(item: TestKit) {
      let kit = this.controller.testCenter.default_testkit;
      if (kit) {
        if (kit.id == item.id) {
          return true;
        }
        return false;
      } else {
        return item.default;
      }
    },
    setDefault(item: TestKit) {
      this.controller.setDefault(item).then(() => {
        this.refresh();
      });
    },
    setActive(event: any, item: TestKit) {
      this.controller.loading = true;
      if (item.default) {
        UI.snackOpen('Default cannot be disabled.', 'warning');
        _.delay(() => {
          location.reload();
        }, 2000);
        return false;
      }
      if (!event && this.activeItems.length == 1) {
        UI.snackOpen('At least one testkit must be active', 'warning');
        _.delay(() => {
          location.reload();
        }, 2000);

        return false;
      }
      this.controller.setActive(item, event).then(() => {
        this.refresh();
      });
    },
    getActive(item: TestKit): boolean {
      return this.activeItems.find(x => x.id == item.id) != null ? true : false;
    },
  },
});
