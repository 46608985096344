import FilterHelper from '@/helpers/filter-helper';
import { Product } from '@/models';
import BaseController from './base';
import Session from './session';

export default class ProductController extends BaseController {
  public async getProducts(branch_id?: number, test_center_id?: number, product_id?: number) {
    this.loading = true;
    let filter = new FilterHelper();
    if (branch_id === 0 || test_center_id === 0 || product_id === 0) {
      this.loading = false;
      return [];
    }
    if (Session.isTestCenter() && !branch_id) {
      filter = filter.equal('test_center.id', this.testCenter.id).lazyLoad('branches');
    } else {
      filter = filter
        .equal('test_center.id', test_center_id)
        .equal('status', true)
        .in('branches.id', [branch_id])
        .lazyLoad('branches');
    }
    if (product_id) filter = filter.equal('id', product_id);

    return await this.uow.Products.where(filter)
      .then(x => x ?? [])
      .finally(() => (this.loading = false));
  }

  public async updateProduct(product: Product) {
    this.loading = true;
    let product_packaged = product.package(false, false);
    return await this.uow.Products.update(product_packaged).finally(() => (this.loading = false));
  }

  public async insertProduct(product: Product) {
    this.loading = true;
    let product_packaged = product.package();
    return await this.uow.Products.insert(product_packaged).finally(() => (this.loading = false));
  }

  public async deleteProduct(product: Product) {
    return await this.uow.Products.unpublish(product.id);
  }
}
