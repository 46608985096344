import UI from '@/controller/ui';
import IUnitOfWork from './iunit-of-work';
import Repository from './repository';
import BranchRepository from './repositories/branch-repository';
import UserRepository from './repositories/user-repository';
import TestCenterRepository from './repositories/test-center-repository';
import CustomerRepository from './repositories/customer-repository';
import AppointmentRepository from './repositories/appointment-repository';
import TimeSlotRepository from './repositories/time-slot-repository';
import BranchPermissionRepository from './repositories/branch-permission-repository';
import TestkitRepository from './repositories/testkit-repository';
import GroupRepository from './repositories/group-repository';
import StorageRepository from './repositories/storage-repository';
import HolidayRepository from './repositories/holiday-repository';
import CustomeFieldRepository from './repositories/custome-field-repository';
import PDFDesignRepository from './repositories/pdf-design-repository';
import SettingRepository from './repositories/settgin-repository';
import ImportRepository from './repositories/import-repository';
import InvoiceRepository from './repositories/invoice-repository';
import PaymentMethodRepository from './repositories/payment-method-repository';
import InvoiceCustomerRepository from './repositories/invoice-customer-repository';
import ProductRepository from './repositories/product-repository';
//!Import Don't Deleted This Line

/**
 * UnitOfWork all repositories
 *  Code Example :
 *
 *
 *  let uow = UnitOfWork.instance;
 *  uow.create(UserRepository).delete(0);
 */
class UnitOfWork implements IUnitOfWork {
  //#region UnitOfWork
  private static _instance = new UnitOfWork();
  public static get instance(): UnitOfWork {
    if (this._instance) {
      return this._instance;
    }
    this._instance = new this();
    return this._instance;
  }

  private repositories: Array<[string, any]> = [];

  /**
   * create of ger repository
   * @param Type repository class
   * @returns repository
   */
  create<T>(Type: new () => T): T {
    const typeName = UI.getRepoName(Type);

    for (const index in this.repositories) {
      const element = this.repositories[index];

      if (element[0] == typeName) {
        return element[1] as T;
      }
    }

    const repo = new Type();
    this.repositories.push([typeName, repo]);

    return repo;
  }
  //#endregion

  //#region DbContext
  public get Branches(): BranchRepository {
    return this.create(BranchRepository);
  }
  public get Users(): UserRepository {
    return this.create(UserRepository);
  }
  public get TestCenters(): TestCenterRepository {
    return this.create(TestCenterRepository);
  }
  public get Customers(): CustomerRepository {
    return this.create(CustomerRepository);
  }
  public get Appointments(): AppointmentRepository {
    return this.create(AppointmentRepository);
  }
  public get TimeSlots(): TimeSlotRepository {
    return this.create(TimeSlotRepository);
  }
  public get BranchPermissions(): BranchPermissionRepository {
    return this.create(BranchPermissionRepository);
  }
  public get Testkits(): TestkitRepository {
    return this.create(TestkitRepository);
  }
  public get Groups(): GroupRepository {
    return this.create(GroupRepository);
  }
  public get Storage(): StorageRepository {
    return this.create(StorageRepository);
  }
  public get Holidays(): HolidayRepository {
    return this.create(HolidayRepository);
  }
  public get CustomeFields(): CustomeFieldRepository {
    return this.create(CustomeFieldRepository);
  }
  public get PDFDesigns(): PDFDesignRepository {
    return this.create(PDFDesignRepository);
  }
  public get Settings(): SettingRepository {
    return this.create(SettingRepository);
  }
  public get Imports(): ImportRepository {
    return this.create(ImportRepository);
  }
  public get Invoices(): InvoiceRepository {
    return this.create(InvoiceRepository);
  }
  public get PaymentMethods(): PaymentMethodRepository {
    return this.create(PaymentMethodRepository);
  }
  public get InvoiceCustomers(): InvoiceCustomerRepository {
    return this.create(InvoiceCustomerRepository);
  }
  public get Products(): ProductRepository {
    return this.create(ProductRepository);
  }
  //!Getter Don't Deleted This Line
  //#endregion
}

export {
  AppointmentRepository,
  BranchPermissionRepository,
  BranchRepository,
  CustomerRepository,
  GroupRepository,
  HolidayRepository,
  StorageRepository,
  TestCenterRepository,
  TestkitRepository,
  TimeSlotRepository,
  UserRepository,
  Repository,
  UnitOfWork,
  CustomeFieldRepository,
  PDFDesignRepository,
  SettingRepository,
  ImportRepository,
  InvoiceRepository,
  PaymentMethodRepository,
  InvoiceCustomerRepository,
  ProductRepository,
  //!Class Don't Deleted This Line
};
