










import Vue from 'vue'
import AppCustomerTable from './components/app-customer-table.vue'

export default Vue.extend({
  name: 'AppCustomers',
  components: {
    AppCustomerTable,
  },
  data: () => ({}),
  mounted() {},
  methods: {},
})
