







































import Vue from 'vue'
import Holidays from './components/holidays.vue'
import TimeSlots from './components/time-slots.vue'

import { mdiChevronRight, mdiDomain } from '@mdi/js'
import { Branch } from '@/models'
import _ from 'lodash'
import BranchController from '@/controller/branch'

export default Vue.extend({
  name: 'Appointments',

  components: {
    Holidays,
    TimeSlots,
  },

  data: () => ({
    e6: 0,
    icons: {
      mdiChevronRight,
      mdiDomain,
    },
    branches: <Branch[]>[],
    branchesWithGroup: <any[]>[],
    selectedBranch: 0,
    selectedId: 0,
    controller: new BranchController(),
  }),
  mounted() {
    this.controller.init().then(x => {
      let _items = x.map(x => {
        let group = x.group != null ? x.group.name : null
        return { text: x.company_name, value: x.id, group: group }
      })
      let items = _.groupBy(_items, 'group')

      for (const key in items) {
        if (Object.prototype.hasOwnProperty.call(items, key)) {
          if (key != 'null') this.branchesWithGroup.push({ text: key, header: key })
          this.branchesWithGroup.push(...items[key])
        }
      }
      this.branches = x
      this.selectedId = this.branchesWithGroup.findIndex(x => x.header == null)
      this.selectBranch(this.branchesWithGroup[this.selectedId])
    })
  },
  methods: {
    selectBranch(item: any) {
      if (item != null && item.value != null) {
        this.selectedBranch = this.branches.findIndex(x => x.id == item.value)
      }
    },
  },
})
