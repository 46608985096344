


































































































































import Vue from 'vue';
import { Product } from '@/models';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose } from '@mdi/js';
import ProductController from '@/controller/product';
import BranchSelector from '@/components/branch-selector.vue';

export default Vue.extend({
  name: 'ProductDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Product>,
      default: () => {
        return new Product();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  components: {
    BranchSelector,
  },
  data: () => ({
    Product,
    gross: 0,
    icons: {
      mdiClose,
    },
    product: new Product(),
    requiredRules: [(v: any) => !!v || v === 0 || 'Dieses Feld ist erforderlich'],
    controller: new ProductController(),
    validation: false,
    editmode: false,
    branches: [0],
  }),
  mounted() {
    if (this.value.id != 0) {
      this.product = Object.assign(new Product(), this.value);
      this.branches = this.product.branches?.map(x => x.id) as any;
      this.editmode = this.product.id != 0;
    }
  },
  methods: {
    calcTax(){
      this.gross = parseInt(UI.calcTax(this.product.price, this.product.tax));
    },
    caclGross() {
      this.gross = parseInt(UI.calcTax(this.product.price, this.product.tax));
    },
    calcNet() {
      this.product.price = Math.round((this.gross / (this.product.tax / 100 + 1)) * 100) / 100;
    },
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.group as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }
      this.branches = this.branches.filter(x => x != 0);
      this.product.branches = this.branches as any;
      if (!this.editmode) {
        this.controller
          .insertProduct(this.product)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Product not created : ' + x.message, 'error');
          });
      } else {
        this.controller
          .updateProduct(this.product)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Product not updated : ' + x.message, 'error');
          });
      }
    },
  },
});
