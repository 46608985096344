import FilterHelper from '@/helpers/filter-helper';
import BaseController from './base';
import Configs from '@/config/config';
import { Invoice, InvoiceCustomer } from '@/models';
import Env from '@/plugins/env';
import Session from './session';
import { FilterType, OrderBy } from '@/enums';
import moment from 'moment';

export default class InvoiceController extends BaseController {
  constructor() {
    super();
  }

  public async init() {
    let filter = new FilterHelper().lazyLoad();
    if (Session.isAdmin()) {
      filter = filter.publication();
    }
    return await this.uow.Invoices.where(filter).then(invoices => {
      return invoices ?? new Array<Invoice>();
    });
  }

  public async insertImport(invoice: Invoice) {
    this.loading = true;
    let invoice_packaged = invoice.package();
    return await this.uow.Invoices.insert(invoice_packaged).finally(() => (this.loading = false));
  }

  public async updateImport(invoice: Invoice) {
    return;
  }

  // public async deleteImport(invoice: Invoice) {
  //   this.loading = false;
  //   return await this.uow.Invoices.unpublish(invoice.id).finally(() => (this.loading = false));
  // }

  public async getInvoiceCustomer(start_date: string) {
    const now = moment(start_date, Configs.dateFormat);
    const _start_date = now.format(Configs.dateServerFormat);
    const yester_date = now.add(1, 'days').format(Configs.dateServerFormat);
    let filter = new FilterHelper()
      .lazyLoad()
      .equal('test_center.id', this.testCenter.id)
      .sort('id', OrderBy.desc)
      .and('start_date', _start_date, FilterType.$gte)
      .and('start_date', yester_date, FilterType.$lte);
    return await this.uow.InvoiceCustomers.where(filter).then(invoices => {
      return invoices ?? new Array<InvoiceCustomer>();
    });
  }
}
