




























































































































































































































































































































import Vue from 'vue';
import { Branch, TimeSlot } from '@/models';
import TimeSlotController from '@/controller/timeslot';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose, mdiCalendar, mdiClockTimeFourOutline } from '@mdi/js';
import Configs from '@/config/config';
import moment from 'moment';

export default Vue.extend({
  name: 'TimeSlotDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<TimeSlot>,
      default: () => {
        return new TimeSlot();
      },
    },
    branchValue: {
      type: Object as PropType<Branch>,
      default: () => {
        return new Branch();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    Branch,
    TimeSlot,
    icons: {
      mdiClose,
      mdiCalendar,
      mdiClockTimeFourOutline,
    },
    branch: new Branch(),
    timeslot: new TimeSlot(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    minMaxCustomer: [
      (v: any) => v > 0 || 'this field is must be greater than 0',
      (v: any) => v < 200 || 'this field is must be less than 200',
    ],
    minMaxDay: [
      (v: any) => v > 0 || 'this field is must be greater than 0',
      (v: any) => v < 30 || 'this field is must be less than 30',
    ],
    controller: new TimeSlotController(),
    validation: false,
    editmode: false,
    start_time_first_menu: false,
    start_time_first: '08:00',
    end_time_first_menu: false,
    end_time_first: '17:00',
    start_time_second_menu: false,
    start_time_second: '',
    end_time_second_menu: false,
    end_time_second: '',
    start_date_menu: false,
    start_date: moment(new Date()).format(Configs.dateServerFormat),
    end_date_menu: false,
    end_date: '',
  }),
  computed: {
    start_date_cmp(): string {
      let _start_date = moment(this.start_date);
      if (_start_date.isValid()) {
        return _start_date.format(Configs.dateFormat) as any;
      }
      return '';
    },
    end_date_cmp(): string {
      let _end_date = moment(this.end_date);
      if (_end_date.isValid()) {
        return _end_date.format(Configs.dateFormat) as any;
      }
      return '';
    },
  },
  watch: {
    start_date(n, o) {
      let _start_date = moment(n);
      if (_start_date.isValid()) {
        this.timeslot.start_date = _start_date.format(Configs.dateServerFormat) as any;
      }
    },
    end_date(n, o) {
      let _end_date = moment(n);
      if (_end_date.isValid()) {
        this.timeslot.end_date = _end_date.format(Configs.dateServerFormat) as any;
      }
    },
    start_time_first(n, o) {
      this.timeslot.start_time_first = UI.getTimeServer(n);
    },
    end_time_first(n, o) {
      this.timeslot.end_time_first = UI.getTimeServer(n);
    },
    start_time_second(n, o) {
      this.timeslot.start_time_second = UI.getTimeServer(n);
    },
    end_time_second(n, o) {
      this.timeslot.end_time_second = UI.getTimeServer(n);
    },
  },
  mounted() {
    if (this.value.id != 0) {
      this.timeslot = Object.assign(new TimeSlot(), this.value);
      this.branch = Object.assign(new Branch(), this.value.branch);
      this.start_date = this.timeslot.start_date.toString();
      this.end_date = this.timeslot.end_date?.toString() || '';
      this.start_time_first = this.getValiTime(this.timeslot.start_time_first);
      this.end_time_first = this.getValiTime(this.timeslot.end_time_first);
      this.start_time_second = this.getValiTime(this.timeslot.start_time_second);
      this.end_time_second = this.getValiTime(this.timeslot.end_time_second);
      this.editmode = true;
    } else {
      this.branch = Object.assign(new Branch(), this.branchValue);
    }
  },
  methods: {
    getValiTime(time: any) {
      let _time = moment(time, Configs.timeServerFormat);
      if (_time.isValid()) {
        return _time.format(Configs.timeFormat) as any;
      }
      return '';
    },
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.group as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }

      if (this.editmode) {
        if (this.timeslot.branch == null || this.timeslot.branch.id == 0) {
          UI.snackOpen(__('Error') + ' : Standorte is not valid', 'error');
          return;
        }
      }
      if (!this.editmode) {
        this.controller
          .insertTimeSlot(this.branch, this.timeslot)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Terminvargabe not created : ' + x.message, 'error');
          });
      } else {
        this.controller
          .updateTimeSlot(this.branch, this.timeslot)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Terminvargabe not updated : ' + x.message, 'error');
          });
      }
    },
    all(set = true) {
      this.timeslot.monday = set;
      this.timeslot.tuesday = set;
      this.timeslot.wednesday = set;
      this.timeslot.thursday = set;
      this.timeslot.friday = set;
      this.timeslot.saturday = set;
      this.timeslot.sunday = set;
    },
    midWeek() {
      this.all();
      this.timeslot.saturday = false;
      this.timeslot.sunday = false;
    },
    weekEnd() {
      this.all(false);
      this.timeslot.saturday = true;
      this.timeslot.sunday = true;
    },
    clearAll() {
      this.all(false);
    },
  },
});
