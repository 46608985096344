import FilterHelper from '@/helpers/filter-helper';
import { Branch, Customer, TestCenter, User } from '@/models';
import Env from '@/plugins/env';
import { UnitOfWork } from '@/uow/unit-of-work';
import _ from 'lodash';
import Vue from 'vue';
import API from './api';
import Cache from './cache';

/**
 *  @description optimized 19.04.2022
 */
export default class BaseController {
  private countdown: any;
  private _loading: boolean = false;

  public get branches(): Branch[] {
    return Cache.branches;
  }

  public get testCenter(): TestCenter {
    return Cache.testCenter;
  }

  public get user(): User {
    return Cache.User;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(val: boolean) {
    this._loading = val;
    if (this.loading == true)
      this.countdown = setTimeout(() => {
        if (this.loading) {
          this.loading = false;
          console.warn('long process');
          this.countdown = null;
        }
      }, 25000);
    else if (this.countdown != null) {
      clearTimeout(this.countdown);
    }
  }

  uow: UnitOfWork = new UnitOfWork();
  static root: Vue = (window as any).vueApp;

  constructor() {
    BaseController.root = (window as any).vueApp;
  }

  public async searchEmail(email: string) {
    this.loading = true;

    return await API.request('POST', '/app/customer/search_email', {
      data: { email },
    })
      .then(x => {
        if (Env.isDev) {
          this.loading = false;
        } else {
          _.delay(() => (this.loading = false), 2000);
        }

        return this.uow.Customers.denormalize(x, true, true);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public async saveSetting(data: any) {
    this.loading = true;
    return await this.uow.Settings.update(data).finally(() => {
      this.loading = false;
    });
  }
}
