



































import Vue from 'vue';

import { Branch, Holiday } from '@/models';
import { PropType } from 'vue/types/umd';
import Editable from '@/components/editable.vue';
import HolidayDialog from './holiday-dialog.vue';
import UI from '@/controller/ui';
import TimeSlotController from '@/controller/timeslot';

export default Vue.extend({
  components: {
    Editable,
    HolidayDialog,
  },
  props: {
    value: {
      type: Object as PropType<Branch>,
      required: true,
      default() {
        return <Branch>{ company_name: '' };
      },
    },
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Holiday);
      return headers;
    },
  },
  data: () => ({
    show: false,
    edit: false,
    // fazla kalabalığa gerek yok bu yüzden iki class için tek controller
    controller: new TimeSlotController(),
    items: <Holiday[]>[],
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.value != null && this.value.id != 0) {
        this.controller.getHolidays(this.value.id).then(x => {
          this.items = x ?? [];
        });
      } else {
        this.items = [];
      }
    },
    clearform() {
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      this.close();
    },
    deleting(item: any) {
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        this.controller.deleteHoliday(item).then(x => {
          UI.snackOpen('Deleted : ' + item.date);
          this.refresh();
        });
      }
    },
    create() {
      this.show = true;
    },
  },
});
