





































































import Vue from 'vue';
import Session from '@/controller/session';
import _ from 'lodash';
import UI from '@/controller/ui';
import StringUtils from '@/utils/string-utils';
import BranchController from '@/controller/branch';
import { Branch } from '@/models';

export default Vue.extend({
  name: 'ForgotPassword',
  data: () => ({
    email: '',
    emailRules: [(v: any) => !!v || 'E-mail ist nötig', (v: any) => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein'],
    loading: false,
    mailsend: false,
    validation: false,
    page: {
      logo: require('@/assets/images/logos/logo.png'),
      title: 'CoTest24',
    },
    customer_mode: false,
    controller: new BranchController(),
    branch: new Branch(),
    branch_url: '',
  }),
  mounted() {
    this.branch_url = this.$route.params.url as string;

    this.customer_mode = !StringUtils.isEmpty(this.branch_url);
    if (this.customer_mode) {
      this.controller.getBranchFromURL(this.branch_url).then(x => {
        if (!x) {
          UI.snackOpen('TestCenter is not available', 'error');
          return;
        }

        this.branch = x;
        this.page.title = x.company_name;
        this.page.logo = UI.getImageURL(x.test_center.logo);
      });
    }
  },
  methods: {
    remember() {
      (this.$refs.forgot as any).validate();
      if (!this.validation) {
        UI.snackOpen('Please Fill Forgot Password Form', 'error');
        return;
      }
      this.loading = true;

      Session.remember(this.email, this.customer_mode).then(res => {
        if (res) {
          this.mailsend = true;
          // _.delay(() => {
          //   this.$root.$router.push('/login')
          // }, 8000)
          return;
        } else {
          UI.snackOpen('Remember Password Failed', 'error');
        }
        this.loading = false;
      });
    },
  },
});
