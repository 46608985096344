







































import API from '@/controller/api';
import DownloadController from '@/controller/download';
import UI from '@/controller/ui';
import { mdiPaperclip, mdiEyeOutline } from '@mdi/js';
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'CompanySettingsAdvanced',
  data: () => ({
    file: null,
    optionsLocal: <any>{},
    radio: 0,
    icons: {
      mdiPaperclip,
      mdiEyeOutline,
    },
    upgraded: false,
    loading: false,
  }),
  computed: {
    fileRule() {
      const ruleFunction = (v: any) => v.type.indexOf('pdf') > 0 || 'Please upload in pdf format.';
      return [(v: any) => !v || ruleFunction(v)];
    },
  },
  mounted() {},
  methods: {
    upgrade() {
      this.loading = true;
      API.request('POST', '/app/upgrade')
        .then(x => {
          if (_.isObject(x.message)) {
            UI.snackOpen('Zu viele Versuche, bitte versuchen Sie es in einer Minute erneut', 'error');
            this.upgraded = true;
            return;
          }
          UI.snackOpen(x.message, 'success');
          this.upgraded = true;
        })
        .catch(err => {
          UI.snackOpen(err.message, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
