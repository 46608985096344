import Configs from '@/config/config';
import { AxiosRequestConfig } from 'axios';
import Strapi from 'strapi-sdk-javascript';
import UI from './ui';

/**
 *  @description optimized 19.04.2022
 */
export default class API {
  private static _strapi: Strapi = new Strapi(Configs.api_url);

  public static get strapi(): Strapi {
    return this._strapi;
  }

  public static async getEntries<T>(model: string): Promise<T[]> {
    let result = [] as T[];
    result = await API.strapi.getEntries(model).then(x => x as any[]);
    return result;
  }

  public static async request(method: string, url: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    try {
      const response = await this._strapi.axios.request(Object.assign({ method, url }, requestConfig as any));
      return response.data;
    } catch (error: any) {
      if (error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
  }

  public static async UploadImage(file: any) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('ref', 'image');
    formData.append('field', 'image');
    return await this.strapi
      .upload(formData)
      .then(x => x as any)
      .catch(x => {
        UI.snackOpen(__('Error') + '  : Netzwerkfehler', 'error');
        return x;
      });
  }
}
