





























































import _ from 'lodash';
import Vue, { PropType } from 'vue';
import { mdiDelete, mdiPencilOutline, mdiMagnify, mdiPlus } from '@mdi/js';
import { emit } from 'process';
import StringUtils from '@/utils/string-utils';

export default Vue.extend({
  name: 'Editable',

  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: [],
    },
    perPage: {
      type: Array,
      default: () => [50, 100], //
    },
    page: {
      type: Number,
      default: 1, //
    },
    loading: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    loadingText: {
      type: String,
      default: 'Loading... Please wait',
    },
    title: {
      type: String,
      default: 'Table',
    },
    group: {
      type: String,
    },
    icons: {
      type: Object as PropType<{ edit: any; delete_: any; create: any }>,
      default: () => {
        return { edit: null, delete_: null, create: mdiPlus };
      },
    },
  },

  data: () => ({
    innerHeaders: <any[]>[],
    search: '',
    inner_icons: {
      create: mdiPlus,
      mdiMagnify,
      delete_: mdiDelete,
      edit: mdiPencilOutline,
    },
    scannerInterval: null,
    qr_code: '',
  }),
  // watch: {
  //   search(n, o) {
  //     console.log(n);

  //     this.$emit('changeSearchText', n);
  //   },
  // },
  mounted() {
    let insertHeader = <any[]>[];
    if (this.editable) {
      insertHeader = _.concat(insertHeader, {
        text: __('Controls.Edit'),
        value: 'edit',
      });
    }
    if (this.deletable) {
      insertHeader = _.concat(insertHeader, {
        text: __('Controls.Delete'),
        value: 'delete',
      });
    }
    this.innerHeaders = _.concat(this.headers, insertHeader);
    this.innerHeaders = _.map(this.innerHeaders, function (user) {
      return _.omit(user, ['divider']);
    });
    this.inner_icons.create = this.icons.create ?? this.inner_icons.create;
    this.inner_icons.edit = this.icons.edit ?? this.inner_icons.edit;
    this.inner_icons.delete_ = this.icons.delete_ ?? this.inner_icons.delete_;
  },
  methods: {
    scanner(event: KeyboardEvent) {
      console.log(event);

      if (this.scannerInterval) {
        // @ts-ignore
        clearInterval(this.scannerInterval);
      }

      if (event.code == 'Enter') {
        if (!StringUtils.isEmpty(this.qr_code)) {
          this.$emit('scaned', this.search);
        }
        this.qr_code = '';
        return;
      }
      this.qr_code += event.key;
      this.scannerInterval = setInterval(() => (this.qr_code = ''), 200) as any;
    },
    callUpdate(item: any) {
      this.$emit('update', item);
    },
    callDelete(item: any) {
      this.$emit('delete', item);
    },
    callCreate() {
      this.$emit('create');
    },
    getName(name: any): string {
      return 'item.' + name;
    },
    hasSlot(name: any): boolean {
      return !!this.$slots[name];
    },
    getHeaders(): Array<any> {
      return this.headers.filter((x: any) => {
        return x.divider || Object.keys(this.$scopedSlots).includes(x.value);
      });
    },
    doubleclick(x: any, y: any) {
      const condition = !_.isEmpty(this.$listeners) && !_.isEmpty(this.$listeners.doubleclick);
      if (condition) {
        this.$emit('doubleclick', y.item);
      } else {
        this.callUpdate(y.item);
      }
    },
  },
});
