import Configs from '@/config/config';
import { display, hidden, notMapped } from '@/controller/reflections';
import moment from 'moment';
import Branch from './branch';
import { ICU } from './imodels';

export default class Holiday extends ICU {
  @display('Model.ID', true)
  @hidden()
  public id: number = 0;

  @display('Model.StartDate', true)
  public date: string = moment().format(Configs.dateServerFormat);

  @hidden()
  @notMapped()
  public branch: Branch = <Branch>new ICU();
}
