import StringUtils from '@/utils/string-utils';
import IStrapiParam from './istrapi-param';

export default class Field implements IStrapiParam {
  public paramType: string = 'Field';
  public name: string = '';
  public value: string = '';
  public index: number = 0;
  constructor(name?: string, index: number = 0) {
    this.name = name ?? '';
    this.index = index;
  }

  public url(): string {
    //* GET /api/users?fields[0]=title&fields[1]=body
    return `fields[${this.index}]=${this.name}`;
  }
}
