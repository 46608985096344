






































































































































































































import Configs from '@/config/config';
import BranchController from '@/controller/branch';
import ImportController from '@/controller/import';
import TestkitController from '@/controller/testkit';
import UI from '@/controller/ui';
import { Branch, Import } from '@/models';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { string } from 'yup/lib/locale';

export default Vue.extend({
  name: 'ImportProc',
  data: () => ({
    step: 1,
    testkits: new Array<any>(),
    import_date: moment().format(Configs.dateTimeServerFormat),
    controller: new ImportController(),
    branchController: new BranchController(),
    testkitController: new TestkitController(),
    icons: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiCalendar,
    },
    importModel: new Import(),
    branches: new Array<Branch>(),
    fileRules: [
      (value: any) => !!value || 'CSV file ist nötig!',
      (value: any) => value?.size < 2000000 || 'File size should be less than 2 MB!',
      (value: any) => value?.name.indexOf('csv') > -1 || 'File type should be text/csv!',
    ],
    requiredRules: [(value: any) => !!value || 'Dieses Feld ist erforderlich!'],
    file: null as any,
    validated: false,
    validated2: false,
    csv: null as any,
    errors: new Array<String>(),
  }),
  watch: {
    import_date(n, o) {
      const newDate = moment(n);
      if (newDate.isValid()) {
        this.importModel.start_date = newDate.format(Configs.dateServerFormat) as any;
        this.importModel.start_time = newDate.format(Configs.timeServerFormat) as any;
      }
    },
  },
  async mounted() {
    this.branches = await this.branchController.init();
    const default_testkit = this.controller.testCenter.default_testkit;
    this.importModel.testkit = default_testkit?.id as any;
    this.testkits = (this.controller.testCenter.active_testkits ?? []).map(x => {
      return { text: x.kit_name, value: x.id, default: x.default };
    });
  },
  methods: {
    async start_import() {
      (this.$refs.importForm as any).validate();
      if (this.validated2) {
        this.controller
          .insertImport(this.importModel)
          .then(_ => {
            UI.snackOpen(__('Success'));
            alert('Import läuft. Bitte sehen Sie in ein paar Minuten noch einmal nach.');
            this.$router.push({ name: 'Imports' });
          })
          .catch(err => {
            UI.snackOpen(err.message, 'error');
          });
      }
    },
    async file_validate() {
      (this.$refs.csv as any).validate();
      if (this.validated) {
        const _file = this.csv as File;
        this.importModel.content = await this.controller.convertJson(_file);
        this.errors = _.map(this.importModel.content, (x: any) => x.error_message).filter(x => x);
        this.importModel.count = _.size(this.importModel.content) - this.errors.length;
        this.step = 4;
      }
    },
  },
});
