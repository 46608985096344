var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"headers":_vm.headers,"editable":false,"deletable":false,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},on:{"update":_vm.update,"delete":_vm.deleting,"create":_vm.create,"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('DatePicker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.dateMergerTime(item.start_date, item.start_time))+" ")]}},{key:"end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.dateMergerTime(item.end_date, item.end_time))+" ")]}},{key:"result",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.result]}},[_vm._v(" "+_vm._s(_vm._f("trans")(('TestResult.' + item.result)))+" ")])]}},{key:"print",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.ticketPrint(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.status == 'Started')?_c('v-chip',{ref:"timer",staticClass:"font-weight-medium",attrs:{"small":"","color":'success'},on:{"click":function($event){return _vm.update(item)}}},[_vm._v(" "+_vm._s(_vm.UI.getTimeLeft(item.checkin))+" ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]},on:{"click":function($event){return _vm.update(item)}}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])}),(_vm.show)?_c('AppointmentDialog',{attrs:{"title":'Termin',"fullscreen":false,"show":_vm.show},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }