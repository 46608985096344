














import Vue from 'vue';
// demos
import Congratulation from './components/congratulation.vue';
import FilterCard from './components/filter-card.vue';
import AppointmentsTable from './components/appointments-table.vue';

export default Vue.extend({
  name: 'Home',

  components: {
    Congratulation,
    FilterCard,
    AppointmentsTable,
  },
  data: () => ({
    model: {
      target: 0,
      count: 0,
    },
  }),
  watch: {},
  mounted() {},
  methods: {},
});
