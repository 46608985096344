




































































































import Vue from 'vue';
import AppointmentDialog from '@/components/appointment-dialog.vue';
import Editable from '@/components/editable.vue';
import DatePicker from '@/components/datepicker.vue';
import BranchSelector from '@/components/branch-selector.vue';
import QrScannerDialog from '@/components/qr-scaner.vue';
import { Appointment } from '@/models';
import UI from '@/controller/ui';
import DashboardController from '@/controller/dashbord';
import moment from 'moment';
import Configs from '@/config/config';
import { mdiMagnify, mdiQrcodeScan, mdiPrinter } from '@mdi/js';
import Cache from '@/controller/cache';
import StringUtils from '@/utils/string-utils';
import _ from 'lodash';
import QrCodeVue from 'qrcode.vue';
import DownloadController from '@/controller/download';

export default Vue.extend({
  components: {
    Editable,
    AppointmentDialog,
    QrScannerDialog,
    DatePicker,
    BranchSelector,
    QrCodeVue,
  },
  data() {
    return {
      title: 'Labor',
      show: false,
      edit: false,
      deleted: false,
      selected: new Appointment(),
      searchText: '',
      statusColor: {
        Finished: 'success',
        Waiting: 'warning',
        Started: 'info',
        WaitingStart: 'secondary',
        Negative: 'success',
        Positive: 'error',
        NotClear: 'secondary',
        Cancel: 'error',
      },
      icons: {
        mdiQrcodeScan,
        mdiMagnify,
        mdiPrinter,
      },
      date: '',
      branch: [0],
      controller: new DashboardController(),
      items: <Appointment[]>[],
      timerInterval: <any>{},
      timerFlag: false,
      page: 1,
      qrScanerOpen: false,
      resetComponent: false,
    };
  },
  computed: {
    allowAutoScroll(): Boolean {
      const nowDate = moment(this.date, Configs.dateFormat).format(Configs.dateServerFormat);
      const timeLeft = moment().format(Configs.dateServerFormat);
      return timeLeft == nowDate;
    },
    headers() {
      let headers = UI.getModelHeader(Appointment);
      headers.splice(headers.length - 4, 0, {
        text: __('Model.FirstName'),
        value: 'first_name',
      });
      headers.splice(headers.length - 4, 0, {
        text: __('Model.LastName'),
        value: 'last_name',
      });
      headers.splice(headers.length - 4, 0, {
        text: __('Model.District'),
        value: 'district',
      });
      // headers.splice(headers.length - 1, 0, {
      //   text: __('Model.Branch'),
      //   value: 'branch.company_name',
      // });
      headers.splice(headers.length - 4, 0, {
        text: __('Model.Phone'),
        value: 'phone',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Print'),
        value: 'print',
      });
      return headers;
    },
    checkScroll: {
      get() {
        let autoScroll = Cache.getCookie('auto_scroll');
        if (StringUtils.isEmpty(autoScroll)) {
          return false;
        }
        return true;
      },
      set(value: any) {
        if (value) {
          Cache.setCookie('auto_scroll', '1', 10);
          this.gotoNowScroll(true);
        } else {
          Cache.deleteCookie('auto_scroll');
        }
      },
    },
  },
  watch: {
    date(n, o) {
      this.refresh();
    },
    branch(n, o) {
      this.refresh();
    },
  },
  mounted() {
    this.timerInterval = setInterval(this.timer, 1000);
    DashboardController.subscribeFilter(this.refresh);
    this.controller.init().then(x => {
      this.items = x.appointments;
      UI.$on('table.refresh', this.refresh);
      this.gotoNowScroll();
    });
  },
  beforeDestroy() {
    DashboardController.unsubscribeFilter(this.refresh);
    if (this.timerInterval) clearInterval(this.timerInterval);
    UI.$off('table.refresh', this.refresh);
  },
  methods: {
    isTimeOut(item: Appointment) {
      const left = UI.getTimeLeft(item.checkin, 'minute');

      return left >= 15;
    },
    timer() {
      this.timerFlag = !this.timerFlag;
    },
    refresh(async_data?: Appointment) {
      const newDate = moment(this.date, Configs.dateFormat).toDate();
      if (async_data) {
        const today = moment(async_data.start_date).diff(newDate, 'days') == 0;
        if (!today) return;
      }

      if (this.branch.includes(0)) {
        this.controller.getAppointmentsFromDate(newDate).then(x => {
          this.items = x.appointments;
          this.gotoNowScroll();
        });
      } else {
        this.controller.getAppointmentsFromBranchesDate(this.branch, newDate).then(x => {
          this.items = x.appointments;
          this.gotoNowScroll();
        });
      }
    },
    clearform() {
      this.selected = new Appointment();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      this.selected = item;
      this.deleted = true;
      // this.clearform()
    },
    create() {
      this.selected = new Appointment();
      this.show = true;
    },
    doubleclick(item: any) {
      this.update(item);
    },
    checkNow(start_date: Date, start_time: string) {
      const checkTime = moment(start_date + ' ' + start_time);
      const timeLeft = moment().diff(checkTime, 'minutes');

      return timeLeft <= 0 && this.allowAutoScroll;
    },
    gotoNowScroll(checkControl?: boolean) {
      const gotoFunction = () => {
        try {
          this.$vuetify.goTo('#now');
        } catch (error) {
          if (this.page > 1) return;
          this.page = 2;
          this.gotoNowScroll(true);
        }
      };
      if (checkControl || this.checkScroll) {
        _.delay(gotoFunction, 1000);
      }
    },
    decode(value: Array<any>) {
      this.qrScanerOpen = false;
      let result = value.pop() || '';
      let qrCode = result?.rawValue ?? '';
      if (!StringUtils.isEmpty(qrCode)) {
        this.controller.searchQR(qrCode).then(x => {
          if (x != null) {
            if (x.length > 0) {
              this.update(x[0]);
              return;
            }
          }
          UI.snackOpen('QR Not Found', 'error');
        });
        console.log(qrCode, 'Searching...');
      }
    },
    search() {
      if (StringUtils.isEmpty(this.searchText)) {
        UI.snackOpen('Suchtext ist leer', 'error');
        return;
      } else if (this.searchText.length < Configs.minSearchLen) {
        UI.snackOpen('Suchtext ist leer', 'error');
        return;
      }

      this.controller.search(this.searchText).then(x => {
        if (x != null) {
          if (x.length > 0) {
            this.items = x;
            return;
          }
        }
        UI.snackOpen('Termin nicht gefunden', 'error');
      });
      console.log(this.searchText, 'Searching...');
    },

    ticketPrint(item: Appointment) {
      let url = item.ticket_pdf?.url;
      if (!url) {
        return;
      }
      url = UI.getURLBase(url);
      DownloadController.downloadURL(url, item.ticket_pdf?.name, true);
    },
  },
});
