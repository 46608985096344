var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field-simplemask',{attrs:{"label":"Zeit *","properties":{
      prefix: '',
      suffix: '',
      readonly: false,
      disabled: false,
      outlined: true,
      placeholder: 'Only Hours',
      class: 'text-center',
      rules: _vm.rules,
    },"options":{
      inputMask: _vm.mask,
      outputMask: _vm.mask,
      empty: null,
      applyAfter: false,
      alphanumeric: true,
      lowerCase: false,
    }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }