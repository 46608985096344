import { repoName } from '@/controller/reflections'
import { Repository } from '@/uow/unit-of-work'
import CustomeField from '@/models/custome-field'

@repoName('CustomeFieldRepository')
export default class CustomeFieldRepository extends Repository<CustomeField> {
  constructor() {
    super('custome-fields')
  }

  public convertType(value: object): CustomeField {
    return Object.assign(new CustomeField(), value)
  }
}
