



































































import Vue from 'vue';
import {
  mdiAvTimer,
  mdiClose,
  mdiEmailOutline,
  mdiPrinter,
  mdiContentSave,
  mdiCalendar,
  mdiMagnify,
  mdiContentCopy,
  mdiPlus,
} from '@mdi/js';
import DashboardController from '@/controller/dashbord';
import StringUtils from '@/utils/string-utils';
import UI from '@/controller/ui';
import Configs from '@/config/config';
import { Appointment } from '@/models';
import Editable from '@/components/editable.vue';

export default Vue.extend({
  name: 'AppointmentTableDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: String,
      default: '',
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'SearchForm',
    },
  },
  components: { Editable },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Appointment);
      headers.splice(headers.length - 4, 0, {
        text: __('Model.FirstName'),
        value: 'customer.first_name',
      });
      headers.splice(headers.length - 4, 0, {
        text: __('Model.LastName'),
        value: 'customer.last_name',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.District'),
        value: 'customer.district',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Phone'),
        value: 'customer.phone',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Branch'),
        value: 'branch.company_name',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Print'),
        value: 'print',
      });
      return headers;
    },
  },
  data: () => ({
    ticket_count: 1,
    icons: {
      mdiContentSave,
      mdiContentCopy,
      mdiClose,
      mdiMagnify,
      mdiCalendar,
      mdiAvTimer,
      mdiPrinter,
      mdiEmailOutline,
      mdiPlus,
    },
    controller: new DashboardController(),
    items: [],
    statusColor: {
      Finished: 'success',
      Waiting: 'warning',
      Started: 'info',
      WaitingStart: 'secondary',
      Negative: 'success',
      Positive: 'error',
      NotClear: 'secondary',
      Cancel: 'error',
    },
  }),
  watch: {},
  mounted() {
    this.search();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    search() {
      if (StringUtils.isEmpty(this.value)) {
        UI.snackOpen('Suchtext ist leer', 'error');
        return;
      } else if (this.value.length < Configs.minSearchLen) {
        UI.snackOpen('Suchtext ist leer', 'error');
        return;
      }

      this.controller.search(this.value).then(x => {
        if (x != null) {
          if (x.length > 0) {
            this.items = x;
            return;
          }
        }
        UI.snackOpen('Termin nicht gefunden', 'error');
      });
      console.log(this.value, 'Searching...');
    },

    createNew(item: any) {
      this.$emit('createNew', item);
    },
  },
});
