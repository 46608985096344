import { repoName } from '@/controller/reflections'
import { PDFDesign } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('PDFDesignRepository')
export default class PDFDesignRepository extends Repository<PDFDesign> {
  constructor() {
    super('pdf-designs')
  }
  public convertType(value: object): PDFDesign {
    return Object.assign(new PDFDesign(), value)
  }
}
