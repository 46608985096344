








































































import Vue from 'vue';
import AppointmentDialog from '@/components/appointment-dialog.vue';
import Editable from '@/components/editable.vue';
import DatePicker from '@/components/datepicker.vue';
import BranchSelector from '@/components/branch-selector.vue';
import { Appointment } from '@/models';
import UI from '@/controller/ui';
import DashboardController from '@/controller/dashbord';
import moment from 'moment';
import Configs from '@/config/config';
import { mdiMagnify, mdiQrcodeScan, mdiPrinter, mdiPlus } from '@mdi/js';
import _ from 'lodash';
import DownloadController from '@/controller/download';
import Cache from '@/controller/cache';

export default Vue.extend({
  components: {
    Editable,
    AppointmentDialog,
    DatePicker,
    BranchSelector,
  },
  data() {
    return {
      title: 'Termine',
      show: false,
      edit: false,
      deleted: false,
      selected: new Appointment(),
      statusColor: {
        Finished: 'success',
        Waiting: 'warning',
        Started: 'info',
        WaitingStart: 'secondary',
        Negative: 'success',
        Positive: 'error',
        NotClear: 'secondary',
        Cancel: 'error',
      },
      icons: {
        mdiQrcodeScan,
        mdiMagnify,
        mdiPrinter,
        mdiPlus,
      },
      date: '',
      branch: [0],
      controller: new DashboardController(),
      items: <Appointment[]>[],
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Appointment);
      headers.splice(headers.length - 4, 0, {
        text: __('Model.FirstName'),
        value: 'customer.first_name',
      });
      headers.splice(headers.length - 4, 0, {
        text: __('Model.LastName'),
        value: 'customer.last_name',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.District'),
        value: 'customer.district',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Phone'),
        value: 'customer.phone',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Branch'),
        value: 'branch.company_name',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Print'),
        value: 'print',
      });
      return headers;
    },
  },
  watch: {
    date(n, o) {
      this.refresh();
    },
    branch(n, o) {
      this.refresh();
    },
  },
  mounted() {
    this.controller.getAppointmentsCustomer().then(x => {
      this.items = x ?? [];
      UI.$on('table.refresh', this.refresh);
    });
  },
  beforeDestroy() {
    UI.$off('table.refresh', this.refresh);
  },
  methods: {
    createNew(item: Appointment) {
      // this.close();
      this.$nextTick(() => {
        // Add the component back in
        this.show = true;
      });
    },
    refresh(async_data?: Appointment) {
      const newDate = moment(this.date, Configs.dateFormat).toDate();
      if (async_data) {
        const today = moment(async_data.start_date).diff(newDate, 'days') == 0;
        if (!today) return;
      }

      this.controller.getAppointmentsCustomer(newDate).then(x => {
        this.items = x ?? [];
      });
    },
    clearform() {
      this.selected = new Appointment();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      this.selected = item;
      this.deleted = true;
      // this.clearform()
    },
    create() {
      this.$router.push({
        name: 'Booking',
        params: {
          type: 'location',
          id: (Cache.User as any).branch.url,
          customer: Cache.User.uid,
        },
      });
      this.selected = new Appointment();
      this.show = true;
    },
    doubleclick(item: any) {
      this.update(item);
    },
    ticketPrint(item: Appointment) {
      let url = item.ticket_pdf?.url;
      if (!url) {
        return;
      }
      url = UI.getURLBase(url);
      DownloadController.downloadURL(url, item.ticket_pdf?.name, true);
    },
  },
});
