





































import DownloadController from '@/controller/download'
import { mdiPaperclip, mdiEyeOutline } from '@mdi/js'
import Vue from 'vue'

export default Vue.extend({
  name: 'CompanySettingsAdvanced',
  data: () => ({
    file: null,
    optionsLocal: <any>{},
    radio: 0,
    icons: {
      mdiPaperclip,
      mdiEyeOutline,
    },
  }),
  computed: {
    fileRule() {
      const ruleFunction = (v: any) => v.type.indexOf('pdf') > 0 || 'Please upload in pdf format.'
      return [(v: any) => !v || ruleFunction(v)]
    },
  },
  mounted() {},
  methods: {},
})
