















































































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import DatePicker from '@/components/datepicker.vue';
import { Branch, TestKit, User } from '@/models';
import TestCountsController from '@/controller/test-counts';
import _ from 'lodash';
import moment from 'moment';
import Configs from '@/config/config';
import Session from '@/controller/session';
import StringUtils from '@/utils/string-utils';
import { mdiPrinter, mdiFileExcelOutline } from '@mdi/js';
import UI from '@/controller/ui';
import Env from '@/plugins/env';
import DownloadController from '@/controller/download';
import Crypto from '@/utils/crypto';
import * as XLSX from 'xlsx';

export default Vue.extend({
  components: {
    Editable,
    DatePicker,
  },
  data() {
    return {
      icons: {
        mdiPrinter,
        mdiFileExcelOutline,
      },
      title: 'Testzahlen',
      items: [] as any[],
      controller: new TestCountsController(),
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      group: 'group',
      totals: {
        appointments: 0,
        success_appointment: 0,
        cancel_appointment: 0,
        negative: 0,
        notclear: 0,
        positive: 0,
        free: 0,
        paid: 0,
        self_paid: 0,
      },
      test_center_uid: '',
    };
  },
  computed: {
    headers() {
      let headers = [
        { text: 'Stanortgruppe', value: 'group' },
        { text: 'Standort', value: 'company_name' },
        { text: 'Anmeldungen', value: 'appointments' },
        { text: 'Durchgeführte Tests', value: 'success_appointment' },
        { text: 'Nachträglich storniert', value: 'cancel_appointment' },
        { text: 'Negativ', value: 'negative' },
        { text: 'Nicht eindeutig', value: 'notclear' },
        { text: 'Positiv', value: 'positive' },
        { text: 'Bürgertests kostenlos', value: 'free' },
        { text: 'Bürgertests mit Zuzahlung', value: 'paid' },
        { text: 'Selbstzahler', value: 'self_paid' },
      ];
      return headers;
    },
  },
  mounted() {
    if (Session.isAdmin()) {
      this.test_center_uid = this.$route.params.uid;
      if (StringUtils.isEmpty(this.test_center_uid)) {
        this.$router.push({ name: 'PageNotFound' });
        return;
      }
    }
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init(this.test_center_uid).then(x => {
        this.items = _.orderBy(x, ['group']);
        this.totals.appointments = _.sumBy(this.items, 'appointments');
        this.totals.success_appointment = _.sumBy(this.items, 'success_appointment');
        this.totals.cancel_appointment = _.sumBy(this.items, 'cancel_appointment');
        this.totals.negative = _.sumBy(this.items, 'negative');
        this.totals.notclear = _.sumBy(this.items, 'notclear');
        this.totals.positive = _.sumBy(this.items, 'positive');
        this.totals.free = _.sumBy(this.items, 'free');
        this.totals.paid = _.sumBy(this.items, 'paid');
        this.totals.self_paid = _.sumBy(this.items, 'self_paid');
      });
    },
    today() {
      let date = moment().format(Configs.dateFormat);
      this.start = date;
      this.end = date;
    },
    yesterday() {
      let date = moment().subtract(1, 'days').format(Configs.dateFormat);
      this.start = date;
      this.end = date;
    },
    lastMonths() {
      let date = moment().subtract(1, 'months');
      let start = date.startOf('months').format(Configs.dateFormat);
      let end = date.endOf('months').format(Configs.dateFormat);
      this.start = start;
      this.end = end;
    },
    thisMonths() {
      let date = moment();
      let start = date.startOf('months').format(Configs.dateFormat);
      let end = date.endOf('months').format(Configs.dateFormat);
      this.start = start;
      this.end = end;
    },
    apply() {
      let start = moment(this.start, Configs.dateFormat).format(Configs.dateServerFormat);
      let end = moment(this.end, Configs.dateFormat).format(Configs.dateServerFormat);

      this.controller.getCounts(start, end, this.test_center_uid).then(x => {
        this.items = _.orderBy(x, ['group']);
        this.totals.appointments = _.sumBy(this.items, 'appointments');
        this.totals.success_appointment = _.sumBy(this.items, 'success_appointment');
        this.totals.cancel_appointment = _.sumBy(this.items, 'cancel_appointment');
        this.totals.negative = _.sumBy(this.items, 'negative');
        this.totals.notclear = _.sumBy(this.items, 'notclear');
        this.totals.positive = _.sumBy(this.items, 'positive');
        this.totals.free = _.sumBy(this.items, 'free');
        this.totals.paid = _.sumBy(this.items, 'paid');
        this.totals.self_paid = _.sumBy(this.items, 'self_paid');
      });
    },
    print() {
      window.print();
    },
    exportExcel() {
      let start = moment(this.start, Configs.dateFormat).format(Configs.dateServerFormat);
      let end = moment(this.end, Configs.dateFormat).format(Configs.dateServerFormat);

      this.controller.loading = true;
      _.delay(
        () => {
          const fields = [
            'Vorname',
            'Nachname',
            'Strasse',
            'PLZ',
            'Ort',
            'Telefon',
            'Email',
            'Termine',
            'Teststart',
            'Status',
            'Testergebnis',
            'PDFPin',
            'Standorte',
            'StandorteGruppe',
            'Testkit',
          ];
          const opts = { fields };

          try {
            const unique = Crypto.NoSqlId();
            const wb = XLSX.utils.book_new();
            const filename = `Test-Counts_${unique}`;
            const ws = XLSX.utils.json_to_sheet(this.items);
            XLSX.utils.book_append_sheet(wb, ws, filename);
            const buffer = XLSX.write(wb, { type: 'buffer', bookType: 'xlsx' });
            DownloadController.downloadBlob(
              buffer,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              `${filename}.xlsx`,
            );
          } catch (err) {
            UI.snackOpen('Parser Error: ' + (err as Error).message, 'error');
            console.error(err);
          }
          this.controller.loading = false;
        },
        Env.isDev ? 0 : 2000,
      );
    },
  },
});
