var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"headers":_vm.headers,"editable":false,"creatable":_vm.value.id != null,"items":_vm.items,"title":'Feiertage - ' + _vm.value.company_name,"loading":_vm.controller.loading},on:{"delete":_vm.deleting,"create":_vm.create},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trans")(item.status.toString())))]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getDate(item.start_date)))]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getDate(item.end_date)))]}},{key:"start_time_first",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getTime(item.start_time_first)))]}},{key:"end_time_first",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getTime(item.end_time_first)))]}},{key:"comment",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(item.comment)+" ")])]}}])}),(_vm.show)?_c('HolidayDialog',{attrs:{"title":'Feiertage - ' + _vm.value.company_name,"fullscreen":false,"show":_vm.show},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }