import { display } from '@/controller/reflections';
import { ICU } from './imodels';

export default class Address extends ICU {
  public name: string = '';
  @display('Model.Description', true)
  public description: string = '';
  @display('Model.Street', true)
  public street: string = '';
  @display('Model.Postalcode', true)
  public postcode: string = '';
  @display('Model.District', true)
  public district: string = '';
  public city: string = '';
  public country: string = '';
  @display('Model.Email', true)
  public email: string = '';
  @display('Model.Website', true)
  public website: string = '';
  @display('Model.Phone', true)
  public phone: string = '';
}
