var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"deletable":false,"creatable":false,"headers":_vm.headers,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},on:{"update":_vm.update,"create":_vm.create,"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trans")(item.default.toString())))]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"blocked",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(_vm._f("trans")(item.blocked.toString()))+" ")])]}},{key:"invitation",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){return _vm.sendInvitation(item.uid)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiEmailOutline)+" ")])],1)]}},{key:"testcounts",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){return _vm.goTestCounts(item.uid)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileChartOutline)+" ")])],1)]}}])}),(_vm.show)?_c('AppCustomerDialog',{attrs:{"title":_vm.title,"fullscreen":false,"show":_vm.show,"test_center":_vm.selected_test_center},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }