






























import Vue from 'vue';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiCloseBox, mdiMinusBox, mdiStoreOutline } from '@mdi/js';
import ComponentController from '@/controller/component';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';

export default Vue.extend({
  name: 'branchPicker',
  model: { prop: 'value', event: 'input' },
  props: {
    value: { type: Array as PropType<number[]>, default: [0] },
    multiple: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      items: <any[]>[],
      innerValue: [0],
      menu: false,
      icons: {
        mdiStoreOutline,
        mdiCloseBox,
        mdiMinusBox,
        mdiCheckboxBlankOutline,
        mdiCheckboxMarked,
      },
      controller: new ComponentController(),
    };
  },
  watch: {
    innerValue(n, o) {},
  },
  computed: {
    internalValue: {
      get(): any {
        if (this.multiple) {
          return this.innerValue;
        } else {
          return this.innerValue[0];
        }
      },
      set(n: any) {
        if (_.isArray(n) && n.length > 0) {
          this.innerValue = n;
          if (n.length > 1 && n.includes(0)) {
            this.innerValue = n.filter((x: any) => x != 0);
          }
        } else if (_.isArray(n) && n.length == 0) {
          this.innerValue = [0];
        } else {
          this.innerValue = [n];
        }

        console.log('Set innerValue', this.innerValue);

        this.$emit('input', this.innerValue);
      },
    },
    all(): boolean {
      return this.innerValue.length == this.items.length;
    },
    afew(): boolean {
      return this.innerValue.length > 0 && !this.all;
    },
    allIcon(): any {
      if (this.all) return this.icons.mdiCloseBox;
      if (this.afew && !this.innerValue.includes(0)) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxMarked;
    },
  },
  /**
   * Eğer multiple false ise  ilk değer seçilir
   * örnek value = 1
   * Eğer multiple true ise tümünü seç adında özel buton vardır
   * ve öğeler başta şu şekildedi
   * value = [0]
   */
  mounted() {
    this.controller.initBranchSelector().then(branches => {
      let _items = <any[]>[];
      let list = _.groupBy(branches, 'group.id');

      //? groups
      for (const key in list) {
        if (Object.prototype.hasOwnProperty.call(list, key)) {
          const element = list[key];
          const first = element[0];
          const active_element_count = _.filter(element, x => x.status).length;
          // eğer hiç aktif şube yoksa grubu gizle
          if (active_element_count == 0) continue;

          _items.push({ text: first.group?.name, header: first.group?.name ?? 'Kein' });
          element.forEach(x => {
            // eğer aktif değilse gizle
            if (!x.status) return;

            _items.push({ text: x.company_name, value: x.id, group: first.group?.name });
          });
        }
      }

      this.items = _items;

      if (!this.multiple && this.items.length > 0) {
        if (this.value.includes(0)) {
          let firstOrDefault = this.items.find(x => x.value != null)?.value;
          this.internalValue = [firstOrDefault];
        } else {
          this.internalValue = this.value;
        }
      } else {
        this.internalValue = this.value;
      }
    });
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.all) {
          this.innerValue = [0];
        } else {
          this.innerValue = this.items.map(x => x.value).slice();
        }
      });
    },
  },
});
