import { FieldStatus, FieldType, OrderBy } from '@/enums';
import FilterHelper from '@/helpers/filter-helper';
import { CustomeField } from '@/models';
import _ from 'lodash';
import BaseController from './base';
import Session from './session';

/**
 *  @description created 27.04.2022
 */
export default class CustomeFieldController extends BaseController {
  constructor() {
    super();
  }

  public async init(test_center_uid?: string) {
    this.loading = true;
    let filter = new FilterHelper()
      .equal('test_center.uid', test_center_uid ?? this.testCenter.uid)
      .sort('index')
      .sort('id');
    return await this.uow.CustomeFields.where(filter).then(res => {
      this.loading = false;
      return res ?? [];
    });
  }

  public async insertCustomeField(customeField: CustomeField) {
    customeField.test_center = this.testCenter;
    let _customeField = customeField.package();
    return await this.uow.CustomeFields.insert(_customeField);
  }

  public async updateCustomeField(customeField: CustomeField) {
    customeField.test_center = this.testCenter;
    let _customeField = customeField.package();
    return await this.uow.CustomeFields.update(_customeField);
  }

  public async deleteCustomeField(customeField: CustomeField) {
    return await this.uow.CustomeFields.delete(customeField.id);
  }

  public add(customeField: CustomeField): any {
    return {
      type: customeField.field_type,
      label: customeField.name,
      value: customeField.field_type == FieldType.Select ? this.defaultValue(customeField.items) : '',
      status: customeField.status,
      required: customeField.required,
      shortcode: customeField.shortcode,
    };
  }

  public preprocess(items: string) {
    return items.replaceAll(/\*/g, '').split('\n');
  }

  public defaultValue(items: string) {
    const regx = /(.*)\*/g.exec(items);
    return regx ? regx[1] : '';
  }
  public perm(item: CustomeField, decomposition: boolean = false) {
    switch (item.status) {
      case FieldStatus.Active:
        return true;
      case FieldStatus.Deactive:
        return false;
      case FieldStatus.OnlyAdmin:
      /*
      *  booking ekranında bir ayrışma var
      *  eğer admin oturum açık iken randevu almaya çalışırsa
      *  admin role sahip olduğu için booking ekranında custome fieldlerin hepsini görür
      *  bu yanlıştır çünkü sadece son kullanıcı gibi bir görüşe sahip olmalıdır.
      */
      if (decomposition) return false;

        return Session.isTestCenter() || Session.isBranch();
      default:
        return true;
    }
  }
}
