

























import Vue from 'vue';
import Editable from '@/components/editable.vue';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiDelete, mdiPencilOutline, mdiHelpCircleOutline, mdiEmailOutline, mdiUpload } from '@mdi/js';
import InvoiceController from '@/controller/invoice';
import { ImportStatus } from '@/enums';
import { Invoice } from '@/models';

export default Vue.extend({
  components: {
    Editable,
  },
  data() {
    return {
      ImportStatus,
      title: 'Invoices',
      show: false,
      edit: false,
      selected: new Invoice(),
      controller: new InvoiceController(),
      items: new Array<Invoice>(),
      statusColor: {
        Error: 'error',
        Success: 'success',
        Processing: 'warning',
      },
      icons: {
        mdiEmailOutline,
        mdiDelete,
        mdiPencilOutline,
        mdiUpload,
        mdiHelpCircleOutline,
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Invoice);
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init().then((imports: Invoice[]) => {
        this.items = imports;
      });
    },
    clearform() {
      this.selected = new Invoice();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (item.default) {
        UI.snackOpen('Default cannot delete.', 'warning');
        return false;
      }
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        // this.controller.deleteImport(item).then(() => {
        //   this.refresh();
        // });
      }
    },
    showErrors(item: any) {
      const _errors = item.content.map((x: any) => x.error_message) as Array<string>;
      const errors = _errors.filter(x => !_.isEmpty(x));
      alert(errors.join('\r\n'));
    },
    create() {
      // this.selected = new Invoice();
      // this.show = true;
      this.$router.push({ name: 'ImportProc' });
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
    getStatusColor(item: Invoice) {
      if (!item.publishedAt) {
        return 'info';
      }
      if (!item.paid) {
        return 'warning';
      }
      return 'success';
    },
    getStatus(item: Invoice) {
      if (!item.publishedAt) {
        return 'DRAFT';
      }
      if (!item.paid) {
        return 'Waiting';
      }
      return 'Paid';
    },
  },
});
