var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"creatable":false,"editable":false,"deletable":false,"headers":_vm.headers,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},on:{"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('DatePicker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"info--text",attrs:{"cols":"12","sm":"3"}},[_vm._v(" Paypal : "+_vm._s(_vm.UI.getCurrency(_vm.totals.paypal))+" ")]),_c('v-col',{staticClass:"warning--text",attrs:{"cols":"12","sm":"3"}},[_vm._v(" POS-Terminal : "+_vm._s(_vm.UI.getCurrency(_vm.totals.pos)))]),_c('v-col',{staticClass:"primary--text",attrs:{"cols":"12","sm":"3"}},[_vm._v(" "+_vm._s(_vm._f("trans")("Financial.Cash"))+" : "+_vm._s(_vm.UI.getCurrency(_vm.totals.cash))+" ")]),_c('v-col',{staticClass:"success--text",attrs:{"cols":"12","sm":"3"}},[_vm._v(" "+_vm._s(_vm._f("trans")("Financial.Total"))+" : "+_vm._s(_vm.UI.getCurrency(_vm.totals.total))+" ")])],1)],1)]},proxy:true},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(_vm.UI.getDateTime(item.start_date))+" ")])]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(_vm.UI.getCurrency(item.total))+" ")])]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(item.customer.first_name + ' ' + item.customer.last_name)+" ")])]}},{key:"pdf",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","color":"info","small":""},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileDownloadOutline)+" ")])],1)]}},{key:"payment_method",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(item.appointment ? item.appointment.payment_status : '')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }