import Configs from '@/config/config';
import { customeColumn, display, hidden, notMapped } from '@/controller/reflections';
import moment from 'moment';
import File from './file';
import { ICU } from './imodels';
import JsonObject from './json-object';
import TestCenter from './test-center';

export default class Invoice extends ICU {
  @display('#')
  @notMapped(true)
  public uid: string = '';
  @display('Model.Customer', true)
  @customeColumn({ path: 'test_center.company_name' })
  @notMapped()
  public test_center: TestCenter = new ICU() as TestCenter;
  @display('System.Date', true)
  public date: string = moment().format(Configs.dateFormat);
  @hidden()
  public products: JsonObject[] = [];
  @display('Tax')
  public tax: number = 0;
  @display('Discount')
  public discount: number = 0;
  @display('Total')
  @notMapped(true)
  public total: number = 0; // server side
  @display('Status')
  public paid: boolean = false;
  @notMapped(true)
  public pdf?: File; // server side
  @hidden()
  public note: string = '';
  @hidden()
  public publishedAt?: Date;
}
