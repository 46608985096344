

















































import Configs from '@/config/config';
import moment from 'moment';
import Vue from 'vue';
import { mdiClose, mdiCalendar, mdiClockTimeFourOutline } from '@mdi/js';

export default Vue.extend({
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    rules: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  name: 'DatetimePicker',
  data: () => ({
    menu: false,
    date: '',
    date_text: '',
    icons: {
      mdiClose,
      mdiCalendar,
      mdiClockTimeFourOutline,
    },
  }),
  watch: {
    date_text(n, o) {
      if (n == '') return;
      if (n == o) return;
      const newDate = moment(n, Configs.dateFormat);
      if (newDate.isValid() && n.length > 9) {
        this.date = newDate.format(Configs.dateServerFormat);
        this.$emit('input', this.date) as any;
      }
    },
    date(n, o) {
      if (n == '') return;
      if (!n) return;
      if (n == o) return;
      const newDate = moment(n);
      if (newDate.isValid()) {
        this.date_text = newDate.format(Configs.dateFormat);
        this.$emit('input', this.date) as any;
      }
    },
    value(n, o) {
      if (n == o) return;
      const newDate = moment(n);
      if (newDate.isValid()) {
        this.date = newDate.format(Configs.dateServerFormat);
        this.date_text = newDate.format(Configs.dateFormat);
      }
    },
  },
  mounted() {
    const newDate = moment(this.value);
    if (newDate.isValid()) {
      this.date = newDate.format(Configs.dateServerFormat);
    }
  },
  methods: {
    save(nDate: any) {
      this.date = nDate;
      this.menu = false;
    },
  },
});
