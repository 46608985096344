import Cache from '@/controller/cache';
import { customeColumn, display, hidden, min, notMapped, required } from '@/controller/reflections';
import FilterHelper from '@/helpers/filter-helper';
import { UnitOfWork } from '@/uow/unit-of-work';
import BranchPermission from './branch-permission';
import File from './file';

import { ICU, IRole } from './imodels';
import TestCenter from './test-center';

export default class User extends ICU {
  @display('#')
  public id = 0;

  @display('Model.Username', true)
  @required()
  public username = '';

  @display('Model.FirstName', true)
  @required()
  public first_name = '';

  @display('Model.LastName', true)
  @required()
  public last_name = '';

  @display('Model.Phone', true)
  public phone = '';

  @display('Model.Password', true)
  @hidden()
  @min(8)
  @required({ or: 'id' })
  public password = '';

  @display('Model.Email', true)
  @required()
  public email = '';

  // @hidden()
  // @notMapped()
  // public provider = ''

  @display('Model.Confirmed', true)
  @hidden()
  public confirmed = true;

  @display('Model.IsActive', true)
  public blocked = false;

  @display('Model.Target', true)
  public target = 100;

  @display('Model.Role', true)
  @customeColumn({ path: 'role.name' })
  @required()
  @hidden()
  @notMapped(true)
  public role: IRole = <any>new ICU();

  @hidden()
  @notMapped()
  public test_center: TestCenter = new ICU() as TestCenter;

  @hidden()
  @notMapped()
  public branch_permission: BranchPermission = new ICU() as BranchPermission;

  public get branchPermissions() {
    let filter = new FilterHelper().equal('user.id', this.id ?? Cache.User.id).lazyLoad();
    let uow = UnitOfWork.instance;
    return uow.BranchPermissions.whereOne(filter);
  }
  @hidden()
  @notMapped()
  public get photo(): File | null {
    if (this.branch_permission) {
      let perm = this.branch_permission;
      return perm?.photo ?? null;
    }
    return null;
  }

  @hidden()
  @notMapped(true)
  public uid = '';
}
