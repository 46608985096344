









































































import Vue from 'vue';
import { Branch, Holiday, TimeSlot } from '@/models';
import TimeSlotController from '@/controller/timeslot';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose, mdiCalendar } from '@mdi/js';
import Configs from '@/config/config';
import moment from 'moment';

export default Vue.extend({
  name: 'HolidayDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Branch>,
      default: () => {
        return new Branch();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    Branch,
    Holiday,
    icons: {
      mdiClose,
      mdiCalendar,
    },
    branch: new Branch(),
    holiday: new Holiday(),
    controller: new TimeSlotController(),
    validation: false,
    start_date_menu: false,
    start_date: moment(new Date()).format(Configs.dateServerFormat),
  }),
  computed: {
    start_date_cmp(): string {
      let _start_date = moment(this.start_date);
      if (_start_date.isValid()) {
        return _start_date.format(Configs.dateFormat) as any;
      }
      return '';
    },
  },
  watch: {
    start_date(n, o) {
      let _start_date = moment(n);
      if (_start_date.isValid()) {
        this.holiday.date = _start_date.format(Configs.dateServerFormat) as any;
      }
    },
  },
  mounted() {
    this.branch = Object.assign(new Branch(), this.value);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.group as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }

      this.controller
        .insertHoliday(this.branch, this.holiday)
        .then((x: any) => {
          //success
          UI.snackOpen(__('Success'));
          this.$emit('save', x);
        })
        .catch((x: any) => {
          //error
          UI.snackOpen('Feiertage not created : ' + x.message, 'error');
        });
    },
  },
});
