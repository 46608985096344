import { OrderBy } from '@/enums';
import IStrapiParam from './istrapi-param';

export default class Sort implements IStrapiParam {
  constructor(value?: string, index?: number, orderBy?: OrderBy) {
    this.value = value ?? '';
    this.index = index ?? 0;
    this.orderBy = orderBy ?? OrderBy.none;
  }

  public paramType: string = 'Sort';
  public name: string = 'sort';
  public value: string = '';
  public index: number = 0;
  public orderBy: OrderBy = OrderBy.none;
  public url(): string {
    return `${this.name}[${this.index}]=${this.value}${this.orderBy}`;
  }
}
