








import Vue from 'vue'
import TestkitsTable from './components/testkits-table.vue'

export default Vue.extend({
  name: 'Testkits',

  components: {
    TestkitsTable,
  },

  mounted() {},
})
