import Cache from '@/controller/cache';
import { display, hidden, notMapped } from '@/controller/reflections';
import moment from 'moment';
import Branch from './branch';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class Product extends ICU {
  @display('Model.Name', true)
  public name: string = '';
  @display('Model.Price', true)
  public price: number = 0;
  @display('Model.TAX', true)
  public tax: number = 0;
  @display('Model.Description', true)
  public description: string = '';
  @display('Model.Status', true)
  public status: boolean = false;
  @hidden()
  public publishedAt?: string;

  //#region releations
  @hidden()
  @notMapped()
  public test_center: TestCenter = Cache.testCenter;
  @hidden()
  @notMapped()
  public branches: Branch[] = [];
  //#endregion
}
