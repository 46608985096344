import { notMapped } from '@/controller/reflections';
import { PDFDesingType, PinMode } from '@/enums';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class PDFDesign extends ICU {
  public template: string = '';
  @notMapped()
  public test_center: TestCenter = new TestCenter();
  public uid: string = '';
  public pin_mode: PinMode = PinMode.Birthday;
  public type: PDFDesingType = PDFDesingType.Result;
}
