








import Vue from 'vue'
import BranchTable from './components/branch-table.vue'

export default Vue.extend({
  name: 'Locations',

  components: {
    BranchTable,
  },

  mounted() {},
})
