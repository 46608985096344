




































































import Configs from '@/config/config';
import Cache from '@/controller/cache';
import DownloadController from '@/controller/download';
import UI from '@/controller/ui';
import FilterHelper from '@/helpers/filter-helper';
import { Setting, File as FileModel } from '@/models';
import { UnitOfWork } from '@/uow/unit-of-work';
import { RequiredPdfMode } from '@/enums';
import { mdiPaperclip, mdiSync, mdiEyeOutline } from '@mdi/js';
import Editor from '@tinymce/tinymce-vue';
import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'CompanySettingsAdvanced',
  components: { Editor },
  data: () => ({
    radio: 0,
    icons: {
      mdiPaperclip,
      mdiEyeOutline,
      mdiSync,
    },
    setting: new Setting(),
    uow: UnitOfWork.instance,
  }),
  computed: {
    fileRule() {
      const ruleFunction = (v: any) => v.type.indexOf('pdf') > 0 || 'Please upload in pdf format.';
      return [(v: any) => !v || ruleFunction(v)];
    },
  },
  mounted() {
    let filter = new FilterHelper().equal('test_center.id', Cache.testCenter.id).lazyLoad();
    this.uow.Settings.whereOne(filter).then((x: Setting | null) => {
      if (x) {
        this.setting = x;
      }
    });
  },
  methods: {
    save() {
      this.uow.Settings.update(this.setting).then(async (x: Setting | null) => {
        UI.snackOpen(__('Success'));

        await this.Session.reloadTestCenter();
        location.reload();
      });
    },
  },
});
