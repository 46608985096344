




















































































































































import Vue from 'vue';
import InvocePDFDesigner from './components/invoice-pdf-designer.vue';
import PaymentController from '@/controller/payment';
import { PaymentMethod } from '@/models';
import { PaymentMethodType } from '@/enums';
import Cache from '@/controller/cache';
import UI from '@/controller/ui';

export default Vue.extend({
  name: 'PaymentMethods',
  components: {
    InvocePDFDesigner,
  },
  data: () => ({
    exp: 0,
    isActive: true,
    validation: false,
    isPaypal: false,
    isPayOnSpot: true,
    requiredRules: (v: any) => !!v || 'Dieses Feld ist erforderlich!',
    controller: new PaymentController(),
    paypal: new PaymentMethod(),
    setting: {
      id: 0,
      payment: {
        pay_on_spot: false,
        pay_on_spot_text: 'Bezahlung bar oder elektronisch im Testzentrum',
        status: false,
        tax_number: '',
      },
    },
  }),
  mounted() {
    this.setting = { id: Cache.testCenter.setting.id, payment: Cache.testCenter.setting.payment };
    this.controller.getPaymentMethods().then(x => {
      this.paypal = x.find(x => x.type == PaymentMethodType.Paypal) ?? new PaymentMethod();
    });
  },
  methods: {
    requiredRulesLive(v: any) {
      return !!v || !this.paypal.live || 'Dieses Feld ist erforderlich!';
    },
    requiredRulesSandbox(v: any) {
      return !!v || this.paypal.live || 'Dieses Feld ist erforderlich!';
    },
    async save() {
      (this.$refs.payment as any).validate();
      if (this.validation) {
        await this.controller.saveSetting(this.setting).then(() => {
          this.Session.reloadTestCenter();
        });
        if (this.paypal.id != 0) {
          this.paypal = await this.controller.updatePaymentMethod(this.paypal);
        } else {
          this.paypal = await this.controller.insertPaymentMethod(this.paypal);
        }
        UI.snackOpen(__('Success'));
      }
    },
  },
});
