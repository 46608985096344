import FilterHelper from '@/helpers/filter-helper';
import { PaymentMethod, Product } from '@/models';
import BaseController from './base';
import * as py from '@paypal/paypal-js';
import UI from './ui';

export default class PaymentController extends BaseController {
  public async getPaymentMethods(test_center_id?: number) {
    this.loading = true;
    let filter = new FilterHelper().equal('test_center.id', test_center_id ?? this.testCenter.id);
    return await this.uow.PaymentMethods.where(filter)
      .then(x => x ?? [])
      .finally(() => (this.loading = false));
  }

  public async updatePaymentMethod(payment_method: PaymentMethod) {
    this.loading = true;
    let payment_method_packaged = payment_method.package();
    return await this.uow.PaymentMethods.update(payment_method_packaged).finally(() => (this.loading = false));
  }

  public async insertPaymentMethod(payment_method: PaymentMethod) {
    this.loading = true;
    payment_method.test_center = this.testCenter;
    let payment_method_packaged = payment_method.package();
    return await this.uow.PaymentMethods.insert(payment_method_packaged).finally(() => (this.loading = false));
  }

  public async createPaypal(product: Product, id: string, cb: Function) {
    let calc_tax = product.tax > 0 ? product.tax / 100 + 1 : 1;
    let calc_price = product.price * calc_tax;
    //@ts-ignore
    window.paypal
      .Buttons({
        createOrder(data: any, actions: any) {
          // Set up the transaction
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: calc_price,
                },
              },
            ],
          });
        },
        async onApprove(data: py.OnApproveData, actions: any) {
          cb(data);
          UI.snackOpen(__('Payment.Approved'));
        },
        onCancel(data) {
          UI.snackOpen(__('Payment.Cancel'), 'warning');
        },
        onError(err) {
          UI.snackOpen(__('Payment.Error'), 'error');
        },
      })
      .render(id);
  }
}
