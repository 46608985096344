















import Vue from 'vue';
import AppointmentTable from './components/positive-results-table.vue';
import PositiveResutsSetting from './components/positive-results-setting.vue';

export default Vue.extend({
  name: 'PositiveResults',
  components: {
    AppointmentTable,
    PositiveResutsSetting,
  },
  data: () => ({}),
  mounted() {},
  methods: {},
});
