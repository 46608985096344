








import Vue from 'vue'
import EmployeeTable from './components/employee-table.vue'

export default Vue.extend({
  name: 'LocationGroups',

  components: {
    EmployeeTable,
  },

  mounted() {},
})
