import Configs from '@/config/config';
import { customeColumn, display, hidden, notMapped } from '@/controller/reflections';
import { AppointmentResult, AppointmentStatus, PaymentFreeStatus, PaymentMethodType } from '@/enums';
import moment from 'moment';
import Branch from './branch';
import Customer from './customer';
import File from './file';
import { ICU } from './imodels';
import Import from './import';
import JsonObject from './json-object';
import Product from './product';
import TestKit from './testkit';

export default class Appointment extends ICU {
  @display('#')
  @hidden(false)
  public id: number = 0;
  @display('Model.StartDate', true)
  @hidden()
  public start_date: string = moment().format(Configs.dateServerFormat);
  @hidden()
  public end_date?: string = undefined;
  @display('Model.StartDate', true)
  public start_time: string = moment().format(Configs.timeServerFormat);
  @display('Model.EndDate', true)
  public end_time?: string = undefined;
  @hidden()
  @notMapped(true)
  public qr_code: string = '';
  @hidden()
  public checkin: Date | null = null;
  @display('Model.Customer', true)
  @customeColumn({ path: 'customer.first_name' })
  @hidden()
  @notMapped()
  public customer: Customer = <Customer>new ICU();
  @hidden()
  public price: number = 0;
  @display('Model.FirstName', true)
  @hidden()
  public first_name: string = '';
  @display('Model.LastName', true)
  @hidden()
  public last_name: string = '';
  @display('Model.Street', true)
  @hidden()
  public street: string = '';
  @display('Model.Postalcode', true)
  @hidden()
  public post_code: string = '';
  @display('Model.District', true)
  @hidden()
  public district: string = '';
  @display('Model.Email', true)
  @hidden()
  public email: string = '';
  @display('Model.BirthDate', true)
  @hidden()
  public birth_date: Date = new Date();
  @display('Model.IdentityNumber', true)
  @hidden()
  public identity_number: string = '';
  @display('Model.Phone', true)
  @hidden()
  public phone: string = '';
  @display('Model.Testkit', true)
  @hidden()
  @notMapped()
  public testkit: TestKit = <TestKit>new ICU();
  @display('Model.Result', true)
  public result: AppointmentResult = AppointmentResult.Waiting;
  @display('Branch', true)
  @customeColumn({ path: 'branch.company_name' })
  @notMapped()
  public branch: Branch = <Branch>new ICU();
  @display('Import')
  @customeColumn({ path: 'import.name' })
  @notMapped(true)
  public import: Import = new ICU() as Import;
  @display('Model.Status', true)
  public status: AppointmentStatus = AppointmentStatus.Waiting;
  @display('Model.Result', true)
  @hidden()
  @notMapped(true)
  public result_pdf?: File = undefined;
  @display('Model.Result', true)
  @hidden()
  @notMapped(true)
  public ticket_pdf?: File = undefined;
  @hidden()
  //! notMapped Yapmayın
  public meta?: JsonObject;
  @hidden()
  @notMapped(true)
  public pin: string = '';
  @hidden()
  public inform_sended: boolean = false;
  @hidden()
  @notMapped()
  public product?: Product = undefined;
  @hidden()
  public payment_status: PaymentMethodType = PaymentMethodType.None;
  @hidden()
  public payment_description: string = '';
  @hidden()
  public payment_free_status: PaymentFreeStatus = PaymentFreeStatus.None;
  @hidden()
  public updated_person: number | undefined = undefined;
}
