import Configs from '@/config/config';
import { PaymentMethodType } from '@/enums';
import FilterHelper from '@/helpers/filter-helper';
import { Appointment, Branch, Customer, Holiday, Product, TimeSlot } from '@/models';
import JsonObject from '@/models/json-object';
import Crypto from '@/utils/crypto';
import StringUtils from '@/utils/string-utils';
import _ from 'lodash';
import moment from 'moment';
import API from './api';
import BaseController from './base';
import Cache from './cache';
import DashboardController from './dashbord';
import Session from './session';
import UI from './ui';

/**
 *  @description optimized 19.04.2022
 */
export default class BookingController extends BaseController {
  constructor() {
    super();
  }

  public async init(branch_url: string) {
    let filter = new FilterHelper()
      .equal('url', branch_url)
      .lazyLoad('test_center', 'testkits', false, false)
      .lazyLoad('test_center', 'default_testkit', false, false)
      .lazyLoad('test_center', 'setting.payment', false, false)
      .lazyLoad('test_center', 'setting.term_pdf', false, false)
      .lazyLoad('test_center', 'setting.privacy_pdf', false, false)
      .lazyLoad('test_center', 'setting.required_pdf', false, false)
      .lazyLoad('test_center', 'logo', false, false)
      .lazyLoad('time_slots', '*', false, false)
      .lazyLoad('holidays', '*', false, false)
      .lazyLoad('address', '*', false, false);
    this.loading = true;
    // custome filter branch search
    // TODO: Root Endpoint
    return this.uow.Branches.whereOne(filter)
      .then(async (x: Branch | null) => {
        if (x == null) {
          UI.vue.$router.push('/error-404');
          return null;
        }
        let branch = x;
        let timeSlot = await this.timeSlotCalculate(branch.time_slots, branch.id, branch.holidays);
        let page = {
          logo: branch.test_center.logo?.url,
          title: branch.company_name,
          hello_message: branch.note,
          id: branch.url,
        };
        if (!Session.isAuth() || Session.isCustomer()) Cache.branches = [x];
        this.loading = false;
        return { branch: branch, ...timeSlot, page };
      })
      .catch(x => {
        UI.vue.$router.push('/error-404');
        this.loading = false;
        return {};
      });
  }

  public redirect(
    dateSlots: any[],
    callbackTime: (x: any) => Promise<any[]>,
    callbackHour: (x: any) => Promise<any[]>,
    callbackMinute: (x: any) => Promise<void>,
  ) {
    //  redirect Boş bir saat dilimi bul ve son adıma geç
    //  uygun saat dilimini burada hesapla
    console.log(dateSlots.find(x => !x.disabled));
    callbackTime(dateSlots.find(x => !x.disabled)).then(y => {
      console.log(y.find(c => !c.disabled));
      callbackHour(y.find(j => !j.disabled)).then(z => {
        console.log(z.find(k => !k.disabled));
        callbackMinute(z.find(k => !k.disabled));
      });
    });
  }

  /*
   TODO: eğer müşteri sistemde zaten kayıtlı ise epostası kontrol edilir ve eski müşteri bilgileri ile randevu kabul edilir.
   TODO: kimlik bilgilerini gizlemeyi unutma
  */
  public async createAppointment(
    branch: Branch,
    customer: Customer,
    time: string,
    date: string,
    meta?: JsonObject,
    payment_status?: PaymentMethodType,
    product?: Product,
  ) {
    this.loading = true;
    let customerPromises: Promise<Customer>;
    // await DashboardController.next(customer.email, moment(customer.birth_date).format(Configs.dateServerFormat));
    let availableCustomer = await this.searchEmail(customer.email).catch(x => {
      return null;
    });

    if (availableCustomer) {
      //! Exploit
      let customer_packaged = availableCustomer.package();
      customer_packaged.first_name = customer.first_name;
      customer_packaged.last_name = customer.last_name;
      customer_packaged.street = customer.street;
      customer_packaged.post_code = customer.post_code;
      customer_packaged.district = customer.district;
      customer_packaged.email = customer.email;
      customer_packaged.birth_date = customer.birth_date;
      customer_packaged.identity_number = customer.identity_number;
      customer_packaged.phone = customer.phone;
      // TODO: Root Endpoint
      customerPromises = this.uow.Customers.update(customer_packaged);
    } else {
      // TODO: Root Endpoint
      customerPromises = this.uow.Customers.insert(customer);
    }

    return customerPromises.then(async _customer => {
      let appointment = new Appointment();
      let appointment_date = moment(date, Configs.dateFormat);
      let appointment_time = moment(time, Configs.timeFormat);
      // @deprecated
      // appointment.qr_code = Crypto.NoSqlId(); //! Exploit js injection
      appointment.start_date = appointment_date.format(Configs.dateServerFormat);
      appointment.start_time = appointment_time.format(Configs.timeServerFormat);
      appointment.customer = _customer.compress();
      appointment.branch = branch.compress();
      appointment.import = undefined as any;

      appointment.first_name = customer.first_name;
      appointment.last_name = customer.last_name;
      appointment.street = customer.street;
      appointment.post_code = customer.post_code;
      appointment.district = customer.district;
      appointment.email = customer.email;
      appointment.birth_date = customer.birth_date;
      appointment.identity_number = customer.identity_number;
      appointment.phone = customer.phone;

      appointment.meta = meta;
      if (payment_status) appointment.payment_status = payment_status;
      if (product) appointment.product = product;
      let testkit = branch.test_center.default_testkit?.compress() ?? null;

      if (testkit == null) {
        throw new Error('Default TestKit Cannot found');
      }

      appointment.testkit = testkit;
      let appointment_packages = appointment.package();
      // TODO: Root Endpoint
      return await this.uow.Appointments.insert(appointment_packages).then(async _appointment => {
        this.loading = false;
        return _appointment;
      });
    });
  }

  private async timeSlotCalculate(timeSlots: TimeSlot[], branch_id: number, holidays: Holiday[] = []) {
    let resDateSlots = [],
      resTimeSlots = [];

    try {
      const timeSlot = this.getCurrentTimeSlot(timeSlots);
      if (timeSlot == undefined) throw new Error('timeSlot is not found');

      const end = timeSlot.end_date == null ? moment().add(Configs.max_day, 'days') : moment(timeSlot.end_date);
      const now = moment(new Date().setHours(0, 0, 0, 0));
      let diff = end.diff(now, 'days');

      const start_time_first = moment(timeSlot.start_time_first, Configs.timeFormat);
      const end_time_first = moment(timeSlot.end_time_first, Configs.timeFormat);
      let diffTime = end_time_first.diff(start_time_first, 'hour');
      for (let i = 0; i < diffTime; i++) {
        // randevu alınabilir saat listesi burada oluşturuluyor
        const time = moment(start_time_first).add(i, 'hours').format(Configs.timeFormat);
        resTimeSlots.push({
          text: time,
          availableCount: 0,
          customer_per_time_slots: timeSlot.customer_per_time_slots,
          disabled: false,
        });
      }

      // randevu alınabilir 2. saat listesi burada oluşturuluyor
      if (!StringUtils.isEmpty(timeSlot.start_time_second) && !StringUtils.isEmpty(timeSlot.end_time_second)) {
        const start_time_second = moment(timeSlot.start_time_second, Configs.timeFormat);
        const end_time_second = moment(timeSlot.end_time_second, Configs.timeFormat);
        let diffTimeSecond = end_time_second.diff(start_time_second, 'hour');
        for (let i = 0; i < diffTimeSecond; i++) {
          const time = moment(start_time_second).add(i, 'hours').format(Configs.timeFormat);
          resTimeSlots.push({
            text: time,
            availableCount: 0,
            disabled: false,
          });
        }
      }

      diff = diff > timeSlot.max_day ? timeSlot.max_day : diff;

      let counts = await this.getAvailableCountRange(
        'daily',
        branch_id,
        moment().format(Configs.dateServerFormat),
        diff,
      ).then(result => {
        return _.map(_.entries(result.data.attributes), ([k, v]) => ({
          date: moment(k, Configs.dateServerFormat).format(Configs.dateFormat),
          count: v as number,
        }));
      });

      for (let i = 0; i < diff; i++) {
        // randevu alınabilir günler listesi burada oluşturuluyor
        let _date = moment().add(i, 'days');
        let date = _date.format(Configs.dateFormat);

        // timeslot day filter
        let isMonday = _date.isoWeekday() == 1;
        let isTuesday = _date.isoWeekday() == 2;
        let isWednesday = _date.isoWeekday() == 3;
        let isThursday = _date.isoWeekday() == 4;
        let isFriday = _date.isoWeekday() == 5;
        let isSaturday = _date.isoWeekday() == 6;
        let isSunday = _date.isoWeekday() == 7;
        if (!timeSlot.monday && isMonday) {
          continue;
        }
        if (!timeSlot.tuesday && isTuesday) {
          continue;
        }
        if (!timeSlot.wednesday && isWednesday) {
          continue;
        }
        if (!timeSlot.thursday && isThursday) {
          continue;
        }
        if (!timeSlot.friday && isFriday) {
          continue;
        }
        if (!timeSlot.saturday && isSaturday) {
          continue;
        }
        if (!timeSlot.sunday && isSunday) {
          continue;
        }

        // holiday filteresi buraya gelmeli
        if (holidays) {
          let holidays_moment = holidays.map(x => moment(x.date).format(Configs.dateFormat));
          let holidays_some = holidays_moment.some(x => x == date);
          if (holidays_some) {
            console.log(holidays_moment, ' is holiday');
            continue;
          }
        }

        let oneHourseMax = timeSlot.customer_per_time_slots * 4;
        let oneDayMax = oneHourseMax * resTimeSlots.length;
        let counte = counts.find(x => x.date == date)?.count ?? 0;
        counte = oneDayMax - counte;
        counte = counte > 0 ? counte : 0;

        if (i == 0) {
          if (end_time_first < moment()) {
            continue;
          }
        }
        resDateSlots.push({ text: date, availableCount: counte, disabled: false });
      }

      return { resDateSlots: resDateSlots, resTimeSlots: resTimeSlots, slot: timeSlot };
    } catch (error) {
      UI.vue.$router.push('/error-404');
      console.log(error);

      return { resDateSlots: [], resTimeSlots: [] };
    }
  }

  public async hourSlots(time: any, timeSlot: TimeSlot, counts: any[]) {
    let result: any[] = [];
    if (time == null) {
      return result;
    }

    let minute_per_time_slots = timeSlot.minute_per_time_slots > 0 ? timeSlot.minute_per_time_slots : 15;
    let count = _.round(59 / minute_per_time_slots);
    count = count > 30 ? 30 : count;

    const start_time = moment(time.text, Configs.timeFormat);

    for (let i = 0; i < count; i++) {
      const _hour = moment(start_time).add(minute_per_time_slots * i, 'minute');
      const hour = _hour.format(Configs.timeFormat);
      const end_time = moment(_hour).add(minute_per_time_slots, 'minute');

      let counte = 0;
      let filter = counts.filter(x => end_time > x.hour && x.hour >= _hour).map(x => x.count);
      counte = _.sum(filter) ?? 0;

      counte = timeSlot.customer_per_time_slots - counte;
      counte = counte > 0 ? counte : 0;
      let disabled = counte == 0;

      result.push({ text: hour, availableCount: counte, disabled: disabled });
    }
    return result;
  }

  private getCurrentTimeSlot(timeSlots: TimeSlot[]) {
    let timeSlot = timeSlots
      .filter((value: TimeSlot, index: number) => {
        if (value.end_date == null) {
          return true;
        }

        const start = moment(value.start_date);
        const end = moment(value.end_date);
        const now = moment(new Date().setHours(0, 0, 0, 0));
        if (start <= now && end >= now) {
          return true;
        }
        return false;
      })
      .map((value: TimeSlot, index: number) => {
        //? Yukarıda geçerli zaman aralıkları filtrelendir
        //? şimdi bunlar arasında bu gün aktif olanı filtreleyeceğiz
        const now = moment(new Date().setHours(0, 0, 0, 0));
        const start = moment(value.start_date);
        const end = value.end_date == null;
        let diff = start.diff(now, 'days');
        return { diff: diff, endless: end, value: value };
      })
      .filter((value: any, index: number) => {
        //? time slot aktif ise al
        return value.value.status;
      })
      .sort((a, b) => a.diff - b.diff)
      .pop();
    return timeSlot?.value;
  }

  public async getAppointmentByQRCode(qr_code: string) {
    let filter = new FilterHelper().equal('qr_code', qr_code).lazyLoad('branch').lazyLoad('branch.test_center.logo');
    // TODO: Root Endpoint
    return await this.uow.Appointments.whereOne(filter).then(results => {
      return results;
    });
  }

  public async getAvailableCountRange(mode: 'daily' | 'hourly', branch_id: number, start_date: string, to?: number) {
    let searchParams = new URLSearchParams();
    searchParams.set('id', branch_id.toString());
    searchParams.set('start_date', start_date);
    if (to) {
      searchParams.set('to', to.toString());
    }
    const url = '/app/booking/available-count-range/';
    const param = url + mode.toString() + '?' + searchParams.toString();
    // TODO: Root Endpoint
    return await API.request('get', param);
  }
  public async verify(qr_code: string, pin: string) {
    this.loading = true;
    const formData = new FormData();
    formData.append('qr_code', qr_code);
    formData.append('pin', pin);
    return await API.request('POST', '/app/appointment/check-pin', { data: formData }).finally(
      () => (this.loading = false),
    );
  }
  public async cancel(qr_code: string, pin: string) {
    this.loading = true;
    const formData = new FormData();
    formData.append('qr_code', qr_code);
    formData.append('pin', pin);
    return await API.request('POST', '/app/appointment/cancel', { data: formData }).finally(
      () => (this.loading = false),
    );
  }

  public async getCustomer(customer_uid: string) {
    this.loading = true;
    let filter = new FilterHelper().equal('uid', customer_uid);
    // TODO: Root Endpoint
    return await this.uow.Customers.whereOne(filter).finally(() => (this.loading = false));
  }
}
