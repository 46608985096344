





























































import { mdiCameraFlipOutline, mdiCheckCircleOutline, mdiClose, mdiQrcodeScan } from '@mdi/js';
import _ from 'lodash';
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullScreen: { type: Boolean, default: false },
  },
  data() {
    return {
      camera: 'auto',
      noFrontCamera: false,
      noRearCamera: false,
      showScanConfirmation: false,
      valueForCamera: false,
      icons: {
        mdiQrcodeScan,
        mdiCameraFlipOutline,
        mdiCheckCircleOutline,
        mdiClose,
      },
    };
  },
  watch: {
    value(n, o) {
      if (!n) {
        this.pause();
      } else {
        this.play();
      }
    },
  },
  // mounted(){
  //   this.onInit
  // },
  methods: {
    async onInit(promise: Promise<any>) {
      try {
        await promise;
      } catch (error: any) {
        const triedFrontCamera = this.camera === 'front';
        const triedRearCamera = this.camera === 'rear';

        const cameraMissingError = error.name === 'OverconstrainedError';

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      }
    },
    cancel() {
      this.pause();
      this.$emit('close');
    },
    turnCam() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear';
          break;
        case 'rear':
          this.camera = 'front';
          break;
        case 'auto':
          this.camera = 'front';
          break;
      }
      console.log(this.camera);
    },
    play() {
      this.camera = 'auto';
      // this.valueForCamera = true
    },
    pause() {
      this.camera = 'off';
      // this.valueForCamera = false
    },
    trackFrame(value: any) {
      if (value) {
        this.pause();
        this.showScanConfirmation = true;
        _.delay(x => {
          this.$emit('decode', value);
        }, 1500);
      }
    },
  },
});
