import FilterHelper from '@/helpers/filter-helper'
import _ from 'lodash'
import BaseController from './base'
import Cache from './cache'

/**
 *  @description optimized 19.04.2022
 */
export default class ComponentController extends BaseController {
  constructor() {
    super()
  }

  public async initBranchSelector() {
    let filter = new FilterHelper().equal('user.id', Cache.User.id).lazyLoad('branches', 'group', false, false)
    return await this.uow.BranchPermissions.whereOne(filter).then(x => {
      if (x != null) {
        return x.branches
      }
      return []
    })
  }
}
