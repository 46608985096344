



















































































import Configs from '@/config/config';
import Cache from '@/controller/cache';
import UI from '@/controller/ui';
import FilterHelper from '@/helpers/filter-helper';
import { ICU, Setting } from '@/models';
import { UnitOfWork } from '@/uow/unit-of-work';
import moment from 'moment';
import Vue from 'vue';
import { set } from 'vue/types/umd';

export default Vue.extend({
  name: 'PositiveResultsSetting',
  data: () => ({
    focus: false,
    value: '',
    validation: true,
    exp: 0,
    setting: new Setting(),
    auto_send_time: '00:00',
    requiredRules: (v: any) => !!v || 'Dieses Feld ist erforderlich!',
    emailRules: (v: any) => /.+@.+\..+/.test(v) || !v || 'E-mail muss gültig sein',
    timeRules: (v: any) => moment(v, Configs.timeFormat).isValid() || 'Time is not a valid',
  }),
  watch: {
    auto_send_time(n, o) {
      const auto_time = moment(n, Configs.timeFormat);
      if (auto_time.isValid()) this.setting.auto_send_time = auto_time.format(Configs.timeServerFormat);
    },
  },
  async mounted() {
    let repo = UnitOfWork.instance.Settings;
    let filter = new FilterHelper().equal('test_center.id', Cache.testCenter.id);
    this.setting = await repo.whereOne(filter).then(x => {
      if (!x) {
        UI.snackOpen('Please contact the administrator, Setting Not Found', 'Error');
      }
      return x ?? (new ICU() as Setting);
    });
  },
  methods: {
    async save() {
      (this.$refs.export as any).validate();
      if (this.validation) {
        let repo = UnitOfWork.instance.Settings;
        const fake_model = {
          id: this.setting.id,
          inform_mail: this.setting.inform_mail,
          inform_error_mail: this.setting.inform_error_mail,
          auto_send: this.setting.auto_send,
          auto_send_time: this.setting.auto_send_time,
        } as Setting;
        await repo
          .update(fake_model)
          .then(x => {
            if (!x) {
              UI.snackOpen('Please contact the administrator, Setting Not Found', 'Error');
            }
            return x ?? (new ICU() as Setting);
          })
          .then(x => {
            this.setting = x;
            UI.snackOpen('Setting updated successfully');
            this.exp = -1;
          });
      }
    },
  },
});
