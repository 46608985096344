


































































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import DatePicker from '@/components/datepicker.vue';
import BranchSelector from '@/components/branch-selector.vue';
import { Appointment } from '@/models';
import UI from '@/controller/ui';
import DashboardController from '@/controller/dashbord';
import moment from 'moment';
import Configs from '@/config/config';
import { mdiEmailSendOutline, mdiFileDownloadOutline } from '@mdi/js';
import _ from 'lodash';
import DownloadController from '@/controller/download';
import JsonObject from '@/models/json-object';

export default Vue.extend({
  name: 'AppointmentsTable',
  model: { prop: 'value', event: 'input' },
  props: {
    value: Object,
  },
  components: {
    Editable,
    DatePicker,
    BranchSelector,
  },
  data() {
    return {
      title: 'Positive Meldungen',
      show: false,
      edit: false,
      deleted: false,
      selected: new Appointment(),
      statusColor: {
        Finished: 'success',
        Waiting: 'warning',
        Started: 'info',
        WaitingStart: 'secondary',
        Negative: 'success',
        Positive: 'error',
        NotClear: 'secondary',
        Cancel: 'error',
      },
      icons: {
        mdiFileDownloadOutline,
        mdiEmailSendOutline,
      },
      date: '',
      branch: [0],
      controller: new DashboardController(),
      items: <Appointment[]>[],
      timerInterval: <any>{},
      timerFlag: false,
      page: 1,
      qrScanerOpen: false,
      resetComponent: false,
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Appointment);
      headers = _.differenceWith(headers, ['start_time', 'result', 'phone', 'status', 'import.name'], (col, diff) => {
        return col.value == diff;
      });
      headers.splice(0, 0, {
        text: __('Model.IsActive'),
        value: 'active',
      });
      headers.splice(headers.length, 0, {
        text: __('Model.Status'),
        value: 'status',
      });
      headers.splice(headers.length, 0, {
        text: __('Controls.PDF'),
        value: 'result_pdf',
      });
      return headers;
    },
  },
  watch: {
    date(n, o) {
      this.refresh();
    },
    branch(n, o) {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    selectAll(val: any) {
      let cache_data = this.items;
      this.items = [];
      cache_data.forEach(element => {
        const last_meta = element.meta ?? new JsonObject();
        last_meta.isActive = val;
        element.meta = last_meta;
      });
      this.items = cache_data;
    },
    refresh() {
      const newDate = moment(this.date, Configs.dateFormat).format(Configs.dateServerFormat);
      this.items = [];
      if (this.branch.includes(0)) {
        this.controller.getPositiveAppointments(undefined, newDate).then(x => {
          this.items = x ?? [];
        });
      } else {
        this.controller.getPositiveAppointments(this.branch, newDate).then(x => {
          this.items = x ?? [];
        });
      }
    },
    clearform() {
      this.selected = new Appointment();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      this.selected = item;
      this.deleted = true;
      // this.clearform()
    },
    create() {
      this.selected = new Appointment();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item);
    },
    checkError(item: Appointment) {
      return !item.inform_sended && !_.isNil(item.meta?.error_message);
    },
    open(item: Appointment) {
      if (item.result_pdf) {
        const url = UI.getURLBase(item.result_pdf.url ?? '');
        DownloadController.downloadURL(url, item.result_pdf.name, true);
      }
    },
    change(item: Appointment) {
      console.log('item.meta =>');
      console.log(item.meta);

      // const last_meta = item.meta ?? new JsonObject();
      // last_meta.isActive = !last_meta.isActive;
      // item.meta = last_meta;
    },
    send() {
      const selectedItems = this.items.filter(item => item.meta?.isActive);
      if (selectedItems.length > 0) {
        if (confirm('Sind Sie sicher, dass Sie senden möchten')) {
          this.controller
            .inform_send(selectedItems)
            .finally(() => {
              this.refresh();
            })
            .catch(x => {
              UI.snackOpen(x.message, 'error');
            });
        }
      } else UI.snackOpen('Bitte wählen Sie eine Prüfung aus', 'warning');
    },
  },
});
