import { repoName } from '@/controller/reflections'
import { TimeSlot } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('TimeSlotRepository')
export default class TimeSlotRepository extends Repository<TimeSlot> {
  constructor() {
    super('time-slots')
  }
  public convertType(value: object): TimeSlot {
    return Object.assign(new TimeSlot(), value)
  }
}
