









































































import Vue from 'vue'
import { Group } from '@/models'
import BranchController from '@/controller/branch'
import UI from '@/controller/ui'
import Configs from '@/config/config'
import { PropType } from 'vue/types/umd'
import _ from 'lodash'
import { mdiClose } from '@mdi/js'

export default Vue.extend({
  name: 'AppointmentDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Group>,
      default: () => {
        return new Group()
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    Group,
    icons: {
      mdiClose,
    },
    group: new Group(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    controller: new BranchController(),
    validation: false,
    editmode: false,
  }),
  async mounted() {
    if (this.value.id != 0) {
      this.group = Object.assign(new Group(), this.value)
      this.editmode = this.group.id != 0
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      ;(this.$refs.group as any).validate()
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error')
        console.warn('Formular ist nicht gültig')
        return
      }
      if (!this.editmode) {
        this.controller
          .insertGroup(this.group)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'))
            this.$emit('save', x)
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Group not created : ' + x.message, 'error')
          })
      } else {
        this.controller
          .updateGroup(this.group)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'))
            this.$emit('save', x)
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Group not created : ' + x.message, 'error')
          })
      }
    },
  },
})
