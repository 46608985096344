








import Vue from 'vue'
import AppointmentsTable from './components/appointments-table.vue'

export default Vue.extend({
  name: 'Events',

  components: {
    AppointmentsTable,
  },

  data: () => ({}),
  mounted() {},
  methods: {},
})
