


































































































































































































































































































































































































































import Vue from 'vue';
import {
  mdiAvTimer,
  mdiClose,
  mdiEmailOutline,
  mdiPrinter,
  mdiContentSave,
  mdiCalendar,
  mdiMagnify,
  mdiContentCopy,
} from '@mdi/js';
import { Appointment, Customer } from '@/models';
import { AppointmentResult, AppointmentStatus, PaymentFreeStatus, PaymentMethodType } from '@/enums';
import DashboardController from '@/controller/dashbord';
import BranchSelector from '@/components/branch-selector.vue';
import DatetimePicker from '@/components/datetime-picker.vue';
import DateTimeText from '@/components/datetime-text.vue';
import moment from 'moment';
import StringUtils from '@/utils/string-utils';
import UI from '@/controller/ui';
import Configs from '@/config/config';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import Crypto from '@/utils/crypto';
import DownloadController from '@/controller/download';
import CustomeFields from '@/components/custome-fields/index.vue';
import ProductSelector from '@/components/product-selector.vue';
import JsonObject from '@/models/json-object';
import PDFController from '@/controller/pdf';
import EmailController from '@/controller/email';
import Session from '@/controller/session';
import appointment from '@/models/appointment';
import Cache from '@/controller/cache';

export default Vue.extend({
  name: 'AppointmentDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Appointment>,
      default: () => {
        return new Appointment();
      },
    },
    clone: {
      type: Object as PropType<Appointment>,
      default: () => {
        return new Appointment();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  components: {
    BranchSelector,
    DatetimePicker,
    CustomeFields,
    ProductSelector,
    DateTimeText,
  },
  computed: {
    join_descriptions(): string[] {
      return Cache.testCenter.setting.costly_text.split('\n');
    },
    free_descriptions(): string[] {
      return Cache.testCenter.setting.free_text.split('\n');
    },
    join(): number {
      return Cache.testCenter.setting.join_costly;
    },
    extra(): number {
      return Cache.testCenter.setting.extra_costly;
    },
    allowExtraCostly(): boolean {
      return Cache.testCenter.setting.extra_costly > 0;
    },
    allowPaymentFree(): boolean {
      return this.appointment.payment_free_status != PaymentFreeStatus.None;
    },
    payment_free_text(): string {
      switch (this.appointment.payment_free_status) {
        case PaymentFreeStatus.None:
          return '';
        case PaymentFreeStatus.Free:
          return 'Freitestung';
        case PaymentFreeStatus.Paid:
          return '(' + UI.getCurrency(Cache.testCenter.setting.join_costly) + ' bezahlt)';
        case PaymentFreeStatus.SelfPaid:
          return '(' + UI.getCurrency(Cache.testCenter.setting.extra_costly) + ' bezahlt)';
        default:
          return '';
      }
    },
    statusColor(): string {
      switch (this.appointment.status) {
        case AppointmentStatus.Waiting:
          return 'secondary';
        case AppointmentStatus.Cancel:
          return 'error';
        case AppointmentStatus.Finished:
          switch (this.appointment.result) {
            case AppointmentResult.Negative:
              return 'success';
            case AppointmentResult.NotClear:
              return 'secondary';
            case AppointmentResult.Positive:
              return 'error';
            default:
              return 'info';
          }
        case AppointmentStatus.WaitingStart:
          return 'info';
      }
      return 'warning';
    },
    allowGeneralControls(): Boolean {
      return !this.isCustomer;
    },
    allowEdit(): Boolean {
      return (
        this.appointment.status != AppointmentStatus.Cancel &&
        this.appointment.result == AppointmentResult.Waiting &&
        !this.isCustomer
      );
    },
    allowPrint(): Boolean {
      return this.appointment.id != 0;
    },
    allowCheckin(): Boolean {
      return (
        this.appointment.status == AppointmentStatus.Waiting &&
        this.appointment.id != 0 &&
        !this.allowPosTerminal &&
        !this.allowCash
      );
    },
    allowSave(): Boolean {
      return (
        this.appointment.status != AppointmentStatus.Finished &&
        this.allowEdit &&
        !this.allowPosTerminal &&
        !this.allowCash
      );
    },
    allowCustomerSearch(): Boolean {
      return this.appointment.id == 0;
    },
    allowStartDate(): Boolean {
      return this.appointment.status == AppointmentStatus.Waiting && !this.isCustomer;
    },
    allowStart(): Boolean {
      return this.appointment.status == AppointmentStatus.WaitingStart;
    },
    allowTicket(): Boolean {
      return (
        (this.appointment.status == AppointmentStatus.WaitingStart ||
          this.appointment.status == AppointmentStatus.Waiting) &&
        this.allowPrint
      );
    },
    allowCancel(): Boolean {
      return (
        this.appointment.status == AppointmentStatus.Waiting &&
        this.appointment.id != 0 &&
        (this.appointment.product == null || this.appointment.product.price == 0)
      );
    },
    allowClosed(): Boolean {
      return this.appointment.result != AppointmentResult.Waiting && this.appointment.id != 0;
    },
    allowFinished(): Boolean {
      return (
        this.appointment.status == AppointmentStatus.Started && this.appointment.result == AppointmentResult.Waiting
      );
    },
    allowReset(): Boolean {
      return (
        this.appointment.status == AppointmentStatus.WaitingStart ||
        this.appointment.status == AppointmentStatus.Started ||
        (Session.isTestCenter() && this.appointment.status == AppointmentStatus.Finished)
      );
    },
    allowAppointment(): Boolean {
      return this.appointment.id != 0;
    },
    allowCustomerNumber(): Boolean {
      return this.customer.id != 0;
    },
    allowCash(): Boolean {
      return (
        this.product &&
        (this.appointment.payment_status == null || this.appointment.payment_status == PaymentMethodType.None)
      );
    },
    allowPosTerminal(): Boolean {
      return (
        this.product &&
        (this.appointment.payment_status == null || this.appointment.payment_status == PaymentMethodType.None)
      );
    },
  },
  data: () => ({
    PaymentFreeStatus,
    Customer,
    Appointment,
    ticket_count: 1,
    icons: {
      mdiContentSave,
      mdiContentCopy,
      mdiClose,
      mdiMagnify,
      mdiCalendar,
      mdiAvTimer,
      mdiPrinter,
      mdiEmailOutline,
    },
    positiveConfirm: false,
    customer: new Customer(),
    appointment: new Appointment(),
    emailRules: [(v: any) => !!v || 'E-mail ist nötig', (v: any) => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein'],
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    payment_free_status_rules: [(v: any) => v != -1 || 'Bitte wählen Sie eine aus'],
    controller: new DashboardController(),
    pdf_controller: new PDFController(),
    birth_dateData: '',
    testkits: <any[]>[],
    result_exclusive: 'NotClear',
    validation: false,
    editmode: false,
    clonemode: false,
    branchId: [0],
    innerStart_date: new Date().toString(),
    fields: <any>null,
    product: <any>null,
    force_selected: <any>null,
    selected_branch_id: 0,
    isCheckinPopup: false,
    payment_free_status: -1,
    payment_free_status_validation: true,
  }),
  watch: {
    birth_dateData(n, o) {
      if (n == '') return;
      const newDate = moment(n);
      if (newDate.isValid()) this.appointment.birth_date = newDate.format(Configs.dateServerFormat) as any;
    },
    innerStart_date(n, o) {
      let newDate = moment(new Date(n));
      this.appointment.start_date = newDate.format(Configs.dateServerFormat);
      this.appointment.start_time = newDate.format(Configs.timeServerFormat);
    },
    branchId(n, o) {
      this.appointment.branch.id = n[0];
      if (!this.editmode) {
        this.customer.branch.id = n[0];
      }
      this.selected_branch_id = n[0];
    },
    appointment: {
      handler: function (n) {
        console.log('appointment modified => ');
        console.log(n);
      },
      deep: true,
    },
  },
  mounted() {
    let custome_fields = <any[]>[];
    const myFields = new JsonObject();
    myFields.custome_fields = custome_fields;
    this.fields = myFields;
    if (this.clone.id != 0) {
      this.clonemode = true;
      this.customer = Object.assign(new Customer(), this.clone.customer);
      this.syncFromCustomer();
      this.birth_dateData = this.customer.birth_date.toString();
      this.branchId = [0];
    } else if (this.value.id != 0) {
      this.appointment = Object.assign(new Appointment(), this.value);
      this.customer = Object.assign(new Customer(), this.value.customer);
      this.branchId = [this.appointment.branch.id];
      const start_date = moment(
        this.appointment.start_date + 'T' + this.appointment.start_time,
        Configs.dateTimeServerFormat,
      );
      this.innerStart_date = start_date.toDate().toString();
      this.birth_dateData = moment(this.customer.birth_date).toDate().toString();
      this.editmode = this.appointment.id != 0;
      if (this.appointment.meta) this.fields = this.appointment.meta;
      if (this.appointment.product) this.force_selected = this.appointment.product;
    }

    if (!this.editmode) this.appointment.qr_code = Crypto.NoSqlId();

    this.controller.getTestKits().then(x => {
      this.testkits = x.map(kits => {
        return { text: kits.kit_name, value: kits.id, default: kits.default };
      });
      if (!this.editmode) {
        this.appointment.testkit.id = this.controller.testCenter?.default_testkit?.id ?? this.testkits[0];
      }
    });
  },
  methods: {
    setPrice(status: PaymentFreeStatus, description: string) {
      switch (status) {
        case PaymentFreeStatus.Paid:
          this.appointment.price = this.join;
          break;
        case PaymentFreeStatus.SelfPaid:
          this.appointment.price = this.extra;
          break;
        default:
          this.appointment.price = 0;
          break;
      }
      this.appointment.payment_free_status = status;
      this.appointment.payment_description = description;
      console.log(this.appointment.price);
    },
    requiredRule(required: boolean) {
      if (required) return (v: any) => !!v || 'Dieses Feld ist erforderlich';
      else return true;
    },
    close() {
      this.$emit('close');
    },
    syncFromAppointment() {
      this.customer.first_name = this.appointment.first_name;
      this.customer.last_name = this.appointment.last_name;
      this.customer.street = this.appointment.street;
      this.customer.post_code = this.appointment.post_code;
      this.customer.district = this.appointment.district;
      this.customer.email = this.appointment.email;
      this.customer.birth_date = this.appointment.birth_date;
      this.customer.identity_number = this.appointment.identity_number;
      this.customer.phone = this.appointment.phone;
    },
    syncFromCustomer() {
      this.appointment.first_name = this.customer.first_name;
      this.appointment.last_name = this.customer.last_name;
      this.appointment.street = this.customer.street;
      this.appointment.post_code = this.customer.post_code;
      this.appointment.district = this.customer.district;
      this.appointment.email = this.customer.email;
      this.appointment.birth_date = this.customer.birth_date;
      this.appointment.identity_number = this.customer.identity_number;
      this.appointment.phone = this.customer.phone;
    },
    save() {
      (this.$refs.appointment as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        return false;
      }
      this.appointment.meta = this.fields;
      this.syncFromAppointment();
      if (this.product) this.appointment.product = this.product;
      if (!this.editmode) {
        this.appointment.updated_person = Cache.User.id;
        this.controller
          .insertAppointment(this.customer, this.appointment)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', { customer: this.customer, appointment: this.appointment });
            return true;
          })
          .catch((x: any) => {
            //error
            console.warn(x);
            UI.snackOpen(x.message, 'error');
            return false;
          });
      } else {
        this.appointment.updated_person = Cache.User.id;
        return this.controller
          .updateAppointment(this.customer, this.appointment)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', { customer: this.customer, appointment: this.appointment });
            return true;
          })
          .catch((x: any) => {
            //error
            console.warn(x);
            UI.snackOpen(x.message, 'error');
            return false;
          });
      }
    },
    allowCheckinable() {
      const start_date = moment(
        this.appointment.start_date + ' ' + this.appointment.start_time,
        Configs.dateTimeServerFormat,
      );
      const timeLeft = moment().diff(start_date, 'days');
      return timeLeft != 0 && this.editmode && this.allowEdit;
    },
    cancel() {
      const answer = confirm('Bist du sicher, dass du abbrechen möchtest?');
      if (answer) {
        this.appointment.status = AppointmentStatus.Cancel;
        this.appointment.end_date = moment().format(Configs.dateServerFormat);
        this.appointment.end_time = moment().format(Configs.timeServerFormat);
        this.save();
      }
    },
    ticketPrint() {
      this.pdf_controller
        .getMultiTicket(this.appointment.id, this.ticket_count)
        .then(result => {
          let url = result.url;
          if (!url) {
            return;
          }
          url = UI.getURLBase(url);
          DownloadController.downloadURL(url, result.ticket_pdf?.name, true);
          this.close();
        })
        .catch(err => {
          UI.snackOpen(err.message, 'error');
        });
    },
    async sendResult() {
      let email = prompt('E-Mail erneut versenden?\nzieladresse', this.customer.email);
      if (!email) return;
      this.controller.loading = true;
      await EmailController.sendResult(this.appointment.id, email);
      this.controller.loading = false;
      UI.snackOpen('Mail erfolgreich gesendet');
      this.close();
    },
    resultPrint() {
      let url = this.appointment.result_pdf?.url;
      if (!url) {
        return;
      }
      url = UI.getURLBase(url);
      DownloadController.downloadURL(url, undefined, true);
    },
    customerCard() {
      let url = this.appointment.customer.customer_card_pdf?.url;
      if (!url) {
        return;
      }
      url = UI.getURLBase(url);
      DownloadController.downloadURL(url);
    },
    statusChange(value: any, agree: boolean = false) {
      if (Cache.testCenter.setting.test_15m_before) {
        const left = UI.getTimeLeft(this.appointment.checkin, 'minute');

        if (left < 15) {
          alert('Es sind noch keine 15 Minuten');
          return;
        }
      }

      if (value == 'Positive' && !agree) {
        this.positiveConfirm = true;

        return;
      }
      this.appointment.result = value;
      this.appointment.status = AppointmentStatus.Finished;
      this.appointment.end_date = moment().format(Configs.dateServerFormat);
      this.appointment.end_time = moment().format(Configs.timeServerFormat);
      this.save();
    },
    start() {
      this.appointment.checkin = (moment().format(Configs.dateTimeServerFormat) + 'Z') as any;
      this.appointment.status = AppointmentStatus.Started;
      this.save();
    },
    async reset() {
      const old = this.appointment.status;
      switch (old) {
        case AppointmentStatus.Started:
          this.appointment.status = AppointmentStatus.WaitingStart;
          break;
        case AppointmentStatus.WaitingStart:
          this.appointment.status = AppointmentStatus.Waiting;
          break;
        case AppointmentStatus.Finished:
          this.appointment.end_date = <any>null;
          this.appointment.end_time = <any>null;
          this.appointment.result = AppointmentResult.Waiting;
          this.appointment.status = AppointmentStatus.Started;
          break;
      }
      if (!(await this.save())) {
        this.appointment.status = old;
      }
    },
    checkinPopup() {
      if (!Cache.testCenter.setting.assumption_cost) {
        this.appointment.payment_free_status = PaymentFreeStatus.None;
        this.appointment.price = 0;
        this.appointment.status = AppointmentStatus.WaitingStart;
        this.save();
        return;
      }
      this.isCheckinPopup = true;
    },
    checkin() {
      (this.$refs.payment_free_status as any).validate();
      if (!this.payment_free_status_validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        return false;
      }
      if (this.appointment.payment_free_status == PaymentFreeStatus.SelfPaid) {
        this.appointment.price = Cache.testCenter.setting.extra_costly;
      }
      if (this.appointment.payment_free_status == PaymentFreeStatus.Paid) {
        this.appointment.price = Cache.testCenter.setting.join_costly;
      }
      if (
        this.appointment.payment_free_status == PaymentFreeStatus.Free ||
        this.appointment.payment_free_status == PaymentFreeStatus.None
      ) {
        this.appointment.price = 0;
      }

      this.appointment.status = AppointmentStatus.WaitingStart;
      this.save();
    },
    customerSearch() {
      if (StringUtils.isEmpty(this.appointment.email)) {
        UI.snackOpen('E-Mail ist erforderlich', 'error', 2500);
        return;
      }

      this.controller
        .searchEmail(this.appointment.email)
        .then(x => {
          if (x == null) {
            UI.snackOpen('Kunde nicht gefunden', 'warning', 2500);
            return;
          }

          this.customer = x;
          this.syncFromCustomer();
          this.birth_dateData = this.customer.birth_date.toString();
        })
        .catch(x => {
          UI.snackOpen('Kunde nicht gefunden', 'warning', 2500);
          return;
        });
    },
    cash() {
      (this.$refs.appointment as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        return false;
      }
      if (confirm(`Haben Sie den Betrag von ${UI.getCurrency(this.product.price)} mit dem Kasse eingezogen?`)) {
        this.appointment.payment_status = PaymentMethodType.Cash;
        this.save();
      }
    },
    pos() {
      (this.$refs.appointment as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        return false;
      }
      if (confirm(`Haben Sie den Betrag von ${UI.getCurrency(this.product.price)} mit dem POS-Terminal eingezogen?`)) {
        this.appointment.payment_status = PaymentMethodType.POS;
        this.save();
      }
    },
  },
});
