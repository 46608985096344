import BaseController from './base';
import API from './api';

/**
 *  @description optimized 19.04.2022
 */
export default class EmailController extends BaseController {
  constructor() {
    super();
  }

  /**
   * test sonucunu ve pdf i gönderir
   * @param customer
   * @param appointment
   */
  public static async sendResult(id: number, email?: string) {
    return await API.strapi.request('post', '/app/mail/send-result/', {
      data: { id, email },
    });
  }

  public async sendInvitation(uid: string, is_test_center: boolean = false) {
    this.loading = true;
    return await this.uow.Users.sendInvitation(uid, is_test_center).then(x => {
      this.loading = false;
      return x;
    });
  }

  public async sendForgotPassword(email: string) {
    this.loading = true;
    return await API.strapi.forgotPassword(email, '').then(x => {
      this.loading = false;
      return x;
    });
  }
}
