import { Role } from '@/enums';
import _ from 'lodash';
import 'reflect-metadata';

/**
 *  @description optimized 19.04.2022
 */

export const notMappedSym = Symbol('notMapped');
export const repoNameSym = Symbol('repoName');
export const hiddenSym = Symbol('hidden');
export const minSym = Symbol('min');
export const requiredSym = Symbol('required');
export const displayNameSym = Symbol('displayName');
export const permissionSym = Symbol('permission');
export const dividerSym = Symbol('divider');

export function repoName(name: string) {
  return function (target: any) {
    Reflect.defineMetadata(repoNameSym, name, target);
  };
}

export function hidden(status = true) {
  return function (target: any, propertyKey: string) {
    let properties = new Array<string>();
    properties = Reflect.getMetadata(hiddenSym, target, propertyKey);
    if (!status) {
      Reflect.deleteMetadata(hiddenSym, target, propertyKey);
      return;
    }
    if (properties) {
      properties.push(propertyKey);
    } else {
      properties = [propertyKey];
      Reflect.defineMetadata(hiddenSym, properties, target, propertyKey);
    }
  };
}

export function min(value: number) {
  return Reflect.metadata(minSym, value);
}

export function required(options?: { or?: string }) {
  return Reflect.metadata(requiredSym, options ?? true);
}

export function display(name: string, trans = false) {
  return Reflect.metadata(displayNameSym, {
    name: name,
    trans: trans,
  });
}

export function permission(roles: Role[]) {
  return Reflect.metadata(permissionSym, {
    roles: roles,
  });
}
export interface ICustomeColumn {
  divider: boolean;
  path?: string;
}

export function customeColumn(option?: { value?: boolean; path?: string }) {
  return Reflect.metadata(dividerSym, {
    divider: option ? option.value ?? false : true,
    path: option ? option.path : undefined,
  });
}

export function notMapped(force: boolean = false) {
  return function (target: any, propertyKey: string) {
    let properties: any[] = Reflect.getMetadata(notMappedSym, target, propertyKey);

    if (properties) {
      properties.push({
        prop: propertyKey,
        class_name: target.constructor.name,
        force: force,
      });
    } else {
      properties = [
        {
          prop: propertyKey,
          class_name: target.constructor.name,
          force: force,
        },
      ];
      Reflect.defineMetadata(notMappedSym, properties, target, propertyKey);
    }
  };
}

export function sanitize(obj: any, callback?: (prop: any) => any): any {
  const whoami = obj.constructor.name;
  const keys = _.keys(obj);
  let metaList = keys
    .map(key => {
      const data = Reflect.getMetadata(notMappedSym, obj, key);
      return data != null ? data[0] : null;
    })
    .filter(v => {
      return v != null;
    });
  metaList = metaList.filter(prop => prop.class_name == whoami || prop.class_name == 'ICU');
  if (metaList.length > 0) {
    if (typeof callback === 'function') {
      metaList.forEach(x => {
        try {
          if (x.force) {
            obj[x.prop] = undefined;
          }
          if (Object.prototype.hasOwnProperty.call(obj, x.prop)) {
            if (obj[x.prop]) {
              return (obj[x.prop] = callback(obj[x.prop]));
            }
          }
        } catch (e) {
          console.error('satalizer\n', e, '\nKey : ', x);
          console.log('obj : ', obj[x]);
          console.log('metadata : ', metaList);

          return;
        }
      });
    } else {
      obj = _.omit(obj, metaList);
    }
  }
  return obj;
}
