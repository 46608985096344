import Configs from '@/config/config';
import { AccountType, Role } from '@/enums';
import FilterHelper from '@/helpers/filter-helper';
import GraphQLHelper from '@/helpers/graphQL-helper';
import { Branch, Setting, TestCenter, TimeSlot, User } from '@/models';
import BranchPermission from '@/models/branch-permission';
import TestCenterRepository from '@/uow/repositories/test-center-repository';
import UserRepository from '@/uow/repositories/user-repository';
import { UnitOfWork } from '@/uow/unit-of-work';
import Crypto from '@/utils/crypto';
import _ from 'lodash';
import moment from 'moment';
import API from './api';
import BaseController from './base';
import Cache from './cache';
import WebSocketAPI from './websocket';

/**
 * Session Controller
 */
export default class Session extends BaseController {
  static isDemo() {
    try {
      if (!this.isAuth() && !this.isCustomer()) {
        return false;
      }
      return Cache.testCenter.account_type == AccountType.Demo;
    } catch (error) {
      return false;
    }
  }
  static isPro() {
    try {
      if (!this.isAuth() && !this.isCustomer()) {
        return false;
      }
      return Cache.testCenter.account_type == AccountType.Pro;
    } catch (error) {
      return false;
    }
  }
  static isPremium() {
    try {
      if (!this.isAuth() && !this.isCustomer()) {
        return false;
      }
      return Cache.testCenter.account_type == AccountType.Premium;
    } catch (error) {
      return false;
    }
  }

  public static async reload() {
    let uow = UnitOfWork.instance;
    let filter = new FilterHelper().equal('user.id', Cache.User.id).lazyLoad();
    await uow.BranchPermissions.whereOne(filter).then(x => {
      if (x) {
        let usr = _.clone(Cache.User);
        usr.role = x.role as any;
        let photo = {};
        if (x.photo) {
          photo = { photo: { id: x.photo?.id, url: x.photo?.url } };
        }
        usr.branch_permission = { id: x.id, ...photo } as any;
        Cache.User = usr;
      }
    });
  }

  public static async reloadTestCenter() {
    let uow = UnitOfWork.instance;
    let gql = GraphQLHelper.getTestCenterRun(Cache.User.uid);
    return await uow.TestCenters.graphQL(gql).then(x => {
      Cache.testCenter = x[0];
    });
  }

  public static async reloadBranches() {
    let uow = UnitOfWork.instance;

    let filter2 = new FilterHelper()
      .equal('user.id', Cache.User.id)
      .lazyLoad('branches', 'group', false, false)
      .lazyLoad('branches', 'address', false, false);
    let branches = await uow.BranchPermissions.whereOne(filter2).then(x => {
      if (x != null) {
        return x.branches;
      }
      return [];
    });

    Cache.branches = branches;

    return branches;
  }

  public static permissionCheck(name: string | null | undefined): boolean {
    let modules = <string[]>[];
    let diff = <string[]>[];
    let role = Cache.User.role as any;
    if (role == Role.Customer) {
      modules = ['Reservations'];
    } else if (role == Role.User) {
      modules = ['Home', 'Dashboard', 'Events', 'Laboratory'];
    } else if (role == Role.Admin) {
      modules = ['All'];
      diff = ['AppCustomers'];
      if (!this.isPro()) {
        diff.push('PaymentMethods', 'Products', 'Invoices', 'Sales');
      }
    } else if (role == Role.AppAdmin) {
      modules = ['AppCustomers', 'TestCounts', 'Invoices', 'InvoiceCreate'];
      // diff = ['Customers']
    }
    return !_.isEmpty(name) && modules.some(x => x == name || x == 'All') && !diff.some(x => x == name);
  }

  public static isAuth(): boolean {
    return Cache.User != null && Cache.User.id != 0 && Cache.jwt != null;
  }

  public static async resetPassword(token: string, password: string, customer: boolean = false) {
    if (customer) {
      return await API.request('POST', '/reset-customer-password', { data: { token, password } });
    }
    return await API.strapi.resetPassword(token, password, password);
  }

  public static async remember(email: string, customer: boolean = false): Promise<boolean> {
    const callbackURL = /* Configs.BaseURL + */ '/reset-password';
    try {
      if (customer) {
        await API.request('POST', '/forgot-customer-password', { data: { identifier: email } });
        return true;
      }
      const x = await API.strapi.forgotPassword(email, callbackURL);
      return true;
    } catch (x_1) {
      return false;
    }
  }

  public static logout() {
    // Cache.User = <User>{ id: 0 }
    API.strapi.clearToken();
    Cache.deleteUser();
    Cache.deleteBranches();
    Cache.deleteTestCenter();
    WebSocketAPI.logout();
    location.reload();
  }

  public static fakeLogin(): Promise<boolean> {
    Cache.User = {} as User;
    Cache.setjwtExp();
    return new Promise((resolve, reject) => resolve(true));
  }

  private static async innerLogin(identifier: any, password: any, customer_mode: boolean, customer_data = {}) {
    API.strapi.clearToken();
    let provider = customer_mode ? 'customer' : 'local';
    return await API.request('post', `/auth/${provider}`, {
      data: {
        identifier,
        password,
        ...customer_data,
      },
    }).then(authentication => {
      API.strapi.setToken(authentication.jwt);
      return { result: false, data: authentication };
    });
    // .catch(error => {
    //   return { result: false, data: error }
    // })
  }

  public static async server_login(jwt: string) {
    API.strapi.setToken(jwt);
    return await API.request('post', 'app/auth/server-login')
      .then(async x => {
        let uow = UnitOfWork.instance;

        let usr = uow.Users.denormalize({ data: x.data }) as User;
        Cache.User = usr;
        await this.reload();
        if (!Session.isAdmin()) {
          await this.reloadTestCenter();
          await this.reloadBranches();
        }
        return { result: true, data: x } as any;
      })
      .catch((x: any) => {
        return { result: false, data: x } as any;
      });
  }

  public static async login(model: User, remember: boolean, customer_mode: boolean = false, customer_data = {}) {
    try {
      const x = await this.innerLogin(model.email, model.password, customer_mode, customer_data);
      Cache.remember = remember;
      Cache.User = x.data.user as User;
      Cache.setjwtExp();

      if (!Session.isCustomer()) {
        await this.reload();

        if (!Session.isAdmin()) {
          await this.reloadTestCenter();
          await this.reloadBranches();
        }
      }
      return await { result: true, data: x };
    } catch (x_1: any) {
      return { result: false, data: x_1 };
    }
  }

  public static isAdmin(): boolean {
    if (!this.isAuth()) {
      return false;
    }
    let role = Cache.User.role as any;
    return role == 'AppAdmin';
  }

  public static isTestCenter(): boolean {
    if (!this.isAuth()) {
      return false;
    }
    let role = Cache.User.role as any;
    return role == 'Admin';
  }

  public static isBranch(): boolean {
    if (!this.isAuth()) {
      return false;
    }
    let role = Cache.User.role as any;
    return role == 'User';
  }

  public static isCustomer(): boolean {
    if (!this.isAuth()) {
      return false;
    }
    let role = Cache.User.role as any;
    return role == 'Customer';
  }

  public static async signUp(user: User, test_center: TestCenter): Promise<any> {
    const data = {
      username: user.username,
      company_name: test_center.company_name,
      first_name: user.first_name,
      last_name: user.last_name,
      post_code: test_center.post_code,
      district: test_center.district,
      street: test_center.street,
      phone: test_center.phone,
      email: test_center.email,
      password: user.password,
    };
    const result = await API.request('POST', '/app/register', {
      data,
    });
    return result;
    try {
      API.strapi.clearToken();
      const x = await API.strapi.register(user.username, user.email, user.password);
      API.strapi.clearToken();
      user.id = (x.user as any).id;
      let uow = UnitOfWork.instance;
      let user_repo = uow.create(UserRepository);
      let test_center_repo = uow.create(TestCenterRepository);
      let user_packaged = user.package();
      // TODO: Root Endpoint
      await uow.Users.update(user_packaged, false);
      test_center.email = user.email;
      test_center.users.push(user.compress());
      let filter = new FilterHelper().equal('default', true);
      // TODO: Root Endpoint
      test_center.default_testkit = (await uow.Testkits.whereOne(filter))?.compress() ?? null;
      test_center.active_testkits = [test_center.default_testkit] as any;
      (test_center.setting as any) = undefined;
      // TODO: Root Endpoint
      let setting = new Setting();
      setting = await uow.Settings.insert(setting);
      test_center.setting = setting.compress();
      // TODO: Root Endpoint
      await test_center_repo.insert(test_center).then(async ret_test_center => {
        // User Update End
        let branch = new Branch();
        branch.company_name = test_center.company_name;
        branch.first_name = user.first_name;
        branch.last_name = user.last_name;
        branch.url = Crypto.NoSqlId();
        branch.test_center = ret_test_center.compress(); //* Releation
        branch.address.street = test_center.street;
        branch.address.district = test_center.district;
        branch.address.postcode = test_center.post_code;
        branch.address.email = test_center.email;
        branch.address.phone = test_center.phone;
        branch.address.name = branch.company_name;
        // TODO: Root Endpoint
        await uow.Branches.insert(branch).then(ret_branch => {
          let timeSlot = new TimeSlot();
          timeSlot.branch = ret_branch.compress(); //* Releation
          timeSlot.name = 'Main TimeSlot ' + branch.company_name;
          timeSlot.max_day = 15;
          timeSlot.minute_per_time_slots = 15;
          timeSlot.customer_per_time_slots = 15;
          timeSlot.start_date = moment().format(Configs.dateServerFormat) as any;
          timeSlot.start_time_first = moment('08:00:00', Configs.timeFormat).format(Configs.timeServerFormat);
          timeSlot.end_time_first =   moment('17:00:00', Configs.timeFormat).format(Configs.timeServerFormat);
          // TODO: Root Endpoint
          uow.TimeSlots.insert(timeSlot).then(ret_timeSlot => {
            // BranchPermission
            let branchPermission = new BranchPermission();
            branchPermission.branches.push(ret_branch.compress()); //* Releation
            branchPermission.user = user.compress(); //* Releation
            branchPermission.name = 'Main Permission ' + branch.company_name;
            branchPermission.role = Role.Admin;
            // TODO: Root Endpoint
            uow.BranchPermissions.insert(branchPermission).then(async x_1 => {
              console.log('SignUp Success 🎉');
            });
          });
        });
      });
      return await true;
    } catch (x_2) {
      console.log('SignUp Fail ☹');
      return false;
    }
  }
}
