







































































































































































































































import Vue from 'vue';

import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { TestCenter, User } from '@/models';
import Session from '@/controller/session';
import UI from '@/controller/ui';

export default Vue.extend({
  data: () => ({
    isPasswordVisible: false,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    email: '',
    emailRules: [(v: any) => !!v || 'E-mail ist nötig', (v: any) => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein'],
    passwordRules: [
      (v: string) => !!v || 'Passwort wird benötigt',
      (v: string) => v.length > 7 || 'Passwort muss 8-stellig sein',
    ],
    passwordconfirm: '',
    isPasswordVisible2: false,
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    new_user: new User(),
    new_test_center: new TestCenter(),
    remember: false,
    loading: false,
    validation: false,
    registered: false,
    errors: [],
  }),
  computed: {
    passwordConfirmRules(): any {
      if (!this.new_user.password) {
        return 'confirm Passwort wird benötigt';
      } else if (this.passwordconfirm.length < 8) {
        return 'confirm Passwort muss 8-stellig sein';
      } else if (this.new_user.password != this.passwordconfirm) {
        return 'confirm Passwort must be equal to the password';
      }
      return true;
    },
  },
  mounted() {},
  methods: {
    register(): void {
      (this.$refs.register as any).validate();

      if (!this.validation) {
        UI.snackOpen('Füllen Sie bitte dieses Formular aus.', 'error');
        console.warn('Füllen Sie bitte dieses Formular aus.');
        return;
      }
      this.loading = true;
      this.new_test_center.email = this.new_user.email;
      this.new_user.phone = this.new_test_center.phone;
      Session.signUp(this.new_user, this.new_test_center)
        .then(result => {
          this.registered = true;
          this.loading = false;
        })
        .catch(err => {
          this.errors = err.errors;
          this.loading = false;
          console.warn(err);
        });
      return;
    },
  },
});
