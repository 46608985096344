import { repoName } from '@/controller/reflections';
import { User } from '@/models';
import { Repository } from '@/uow/unit-of-work';

@repoName('UserRepository')
export default class UserRepository extends Repository<User> {
  constructor() {
    super('users');
  }
  public convertType(value: object): User {
    return Object.assign(new User(), value);
  }

  public async sendInvitation(uid: string, is_test_center: boolean) {
    const formData = new FormData();
    formData.append('uid', uid.toString());
    formData.append('is_test_center', is_test_center.toString());
    return await this.api.request('post', 'app/mail/send-invitation', Object.assign({ data: formData }));
  }
}
