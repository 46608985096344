import { repoName } from '@/controller/reflections'
import { Setting } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('SettingRepository')
export default class SettingRepository extends Repository<Setting> {
  constructor() {
    super('settings')
  }
  public convertType(value: object): Setting {
    return Object.assign(new Setting(), value)
  }
}
