import FilterHelper from '@/helpers/filter-helper';
import { Branch, Group, TimeSlot } from '@/models';
import _ from 'lodash';
import BaseController from './base';
import Cache from './cache';
import { ICU } from '@/models/imodels';
import Configs from '@/config/config';
import moment from 'moment';
import Session from './session';

/**
 *  @description optimized 19.04.2022
 */
export default class BranchController extends BaseController {
  constructor() {
    super();
  }

  public async init() {
    return await Session.reloadBranches();
  }

  public async groupInit() {
    this.loading = true;

    let filter = new FilterHelper().equal('test_center.id', this.testCenter.id).lazyLoad('branches', '*', false, false);
    let groups = await this.uow.Groups.where(filter).then(x => {
      if (x != null) {
        return x;
      }
      return [];
    });

    this.loading = false;
    return groups;
  }

  public async insertBranch(branch: Branch) {
    this.loading = true;
    branch.test_center = this.testCenter;
    let branch_packaged = branch.package();
    return await this.uow.Branches.insert(branch_packaged).then(async res => {
      this.getPermission().then(async perm => {
        if (perm) {
          this.loading = false;
          let newPerm = new ICU();
          newPerm.id = res.id;
          perm?.branches.push(newPerm as Branch);

          await this.uow.BranchPermissions.update(perm);

          let defaultTimeSlot = new TimeSlot();
          defaultTimeSlot.name = branch_packaged.company_name;
          defaultTimeSlot.tuesday = true;
          defaultTimeSlot.wednesday = true;
          defaultTimeSlot.thursday = true;
          defaultTimeSlot.friday = true;
          defaultTimeSlot.saturday = true;
          defaultTimeSlot.sunday = true;
          defaultTimeSlot.start_date = moment().format(Configs.dateServerFormat) as any;
          defaultTimeSlot.start_time_first = moment('08:00', 'HH:mm').format(Configs.timeServerFormat) as any;
          defaultTimeSlot.end_time_first = moment('17:00', 'HH:mm').format(Configs.timeServerFormat) as any;
          defaultTimeSlot.minute_per_time_slots = 15;
          defaultTimeSlot.max_day = 15;
          defaultTimeSlot.branch = res.compress();
          await this.uow.TimeSlots.insert(defaultTimeSlot);
          Session.reloadBranches();
        }
      });
      return res;
    });
  }

  public async getPermission(id?: number) {
    let filter = new FilterHelper().equal('user.id', id ?? Cache.User.id).lazyLoad();
    return await this.uow.BranchPermissions.whereOne(filter);
  }

  public async updateBranch(branch: Branch) {
    this.loading = true;
    let branch_packaged = branch.package();
    return await this.uow.Branches.update(branch_packaged).then(async x => {
      this.loading = false;
      Session.reloadBranches();
      return x;
    });
  }

  public async insertGroup(group: Group) {
    this.loading = true;

    group.test_center = this.testCenter;
    let group_packaged = group.package();

    let filter = new FilterHelper().equal('name', group.name);
    let uniqGroups = await this.uow.Groups.whereOne(filter);
    if (uniqGroups) {
      throw new Error('A group name already exists with this name');
    }

    return await this.uow.Groups.insert(group_packaged).then(async x => {
      this.loading = false;
      return x;
    });
  }

  public async updateGroup(group: Group) {
    this.loading = true;

    group.test_center = this.testCenter;
    let group_packaged = group.package();

    let filter = new FilterHelper().equal('name', group.name);
    let uniqGroups = await this.uow.Groups.whereOne(filter);
    if (uniqGroups) {
      throw new Error('A group name already exists with this name');
    }

    return await this.uow.Groups.update(group_packaged).then(async x => {
      this.loading = false;
      return x;
    });
  }

  public async removeGroup(group: Group) {
    this.loading = true;
    return this.uow.Groups.delete(group.id).then(async x => {
      this.loading = false;
      return x;
    });
  }

  public async removeBranch(branch: Branch) {
    this.loading = true;
    return this.uow.Branches.delete(branch.id).then(async x => {
      this.loading = false;
      return x;
    });
  }

  public async getBranchFromURL(url: string) {
    let filter = new FilterHelper()
      .equal('url', url)
      .lazyLoad('test_center', 'logo', false, false)
      .lazyLoad('address', '*', false, false);
    this.loading = true;
    // custome filter branch search
    // TODO: Root Endpoint
    return this.uow.Branches.whereOne(filter)
      .then(async (x: Branch | null) => {
        return x;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
