





























import Vue from 'vue';
import { mdiAlert } from '@mdi/js';
import ThemeSwitcher from '@/layouts/components/theme-switcher.vue';
export default Vue.extend({
  name: 'PageNotFound',
  components: {
    ThemeSwitcher,
  },
  data: () => ({
    icons: {
      mdiAlert,
    },
  }),
  mounted() {},
  methods: {},
});
