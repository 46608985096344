import { repoName } from '@/controller/reflections'
import { Invoice } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('InvoiceRepository')
export default class InvoiceRepository extends Repository<Invoice> {
  constructor() {
    super('invoices')
  }
  public convertType(value: object): Invoice {
    return Object.assign(new Invoice(), value)
  }
}
