










import Vue from 'vue';
import SalesTable from './components/sales-table.vue';

export default Vue.extend({
  name: 'Sales',
  components: {
    SalesTable,
  },
  data: () => ({}),
  mounted() {},
  methods: {},
});
