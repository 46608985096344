import { repoName } from '@/controller/reflections'
import { TestCenter } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('TestCenterRepository')
export default class TestCenterRepository extends Repository<TestCenter> {
  constructor() {
    super('test-centers')
  }
  public convertType(value: object): TestCenter {
    return Object.assign(new TestCenter(), value)
  }
}
