
















import Vue from 'vue';
import CustomeFields from '@/components/custome-fields/index.vue';
import JsonObject from '@/models/json-object';
import CustomeFieldsTable from './components/custome-fields-table.vue';

export default Vue.extend({
  name: 'CustomeFieldsPage',
  components: {
    CustomeFields,
    CustomeFieldsTable,
  },
  data: () => ({
    fields: <any>null,
    refresh: true,
  }),
  mounted() {
    let custome_fields = <any[]>[];
    const myFields = new JsonObject();
    myFields.custome_fields = custome_fields;
    this.fields = myFields;
  },
  methods: {
    preview() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
  },
});
