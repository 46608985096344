



































































import Vue from 'vue';
import { TestKit } from '@/models';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose } from '@mdi/js';
import Crypto from '@/utils/crypto';
import TestkitController from '@/controller/testkit';

export default Vue.extend({
  name: 'BranchDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<TestKit>,
      default: () => {
        return new TestKit();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    TestKit,
    icons: {
      mdiClose,
    },
    testkit: new TestKit(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    controller: new TestkitController(),
    validation: false,
    editmode: false,
    active: true,
  }),
  mounted() {
    if (this.value.id != 0) {
      this.testkit = Object.assign(new TestKit(), this.value);
      this.editmode = this.testkit.id != 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.group as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }
      if (!this.editmode) {
        this.controller
          .insertTestKit(this.testkit, this.active)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Branch not created : ' + x.message, 'error');
          });
      } else {
        this.controller
          .updateTestKit(this.testkit, this.active)
          .then((x: any) => {
            //success
            // UI.snackOpen(__('Success'))
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Filiale nicht aktualisiert : ' + x.message, 'error');
          });
      }
    },
  },
});
