import { display, hidden, notMapped } from '@/controller/reflections';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class TestKit extends ICU {
  @display('Model.Name', true)
  public kit_name: string = '';
  @display('Model.Default', true)
  public default: boolean = false;
  @hidden()
  @notMapped()
  public test_center: TestCenter = <TestCenter>{};
}
