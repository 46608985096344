import { display, hidden, notMapped } from '@/controller/reflections';
import { FieldStatus, FieldType } from '@/enums';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class CustomeField extends ICU {
  @display('Model.Name', true)
  public name: string = '';

  @display('Model.FieldType', true)
  public field_type: FieldType = FieldType.Text;

  @display('Model.Status', true)
  public status: FieldStatus = FieldStatus.Active;

  @display('Model.Required', true)
  public required: boolean = false;

  @display('Model.Shortcode', true)
  public shortcode: string = '';

  @display('Model.Index', true)
  public index: number = 1;

  @hidden()
  public min: number = 1;
  @hidden()
  public max: number = 1;
  @hidden()
  public items: string = '';

  @notMapped()
  @hidden()
  public test_center: TestCenter = <TestCenter>new ICU();
}
