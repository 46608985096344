





































import Vue from 'vue';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import moment from 'moment';
import Configs from '@/config/config';

export default Vue.extend({
  name: 'DatePicker',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: String,
      default: moment().format('YYYY-MM-DD'),
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      menu: false,
      icons: {
        mdiCalendar,
        mdiChevronLeft,
        mdiChevronRight,
      },
    };
  },
  watch: {
    value(val) {
      this.date = moment(val, Configs.dateFormat).format('YYYY-MM-DD');
    },
  },
  computed: {
    dateFormatted: {
      get(): any {
        return moment(this.internalValue).format(Configs.dateFormat);
      },
    },
    internalValue: {
      get(): any {
        return this.date;
      },
      set(val: any) {
        this.date = moment(val).format('YYYY-MM-DD');

        this.$emit('input', moment(this.date).format(Configs.dateFormat));
      },
    },
  },
  mounted() {
    if (moment(this.value).isValid()) {
      this.date = this.value;
    }
    this.internalValue = moment().format('YYYY-MM-DD');
  },
  methods: {
    dateBack() {
      this.internalValue = moment(this.date).subtract(1, 'days').format('YYYY-MM-DD');
    },
    dateForward() {
      this.internalValue = moment(this.date).add(1, 'd').format('YYYY-MM-DD');
    },
  },
});
