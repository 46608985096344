import { repoName } from '@/controller/reflections'
import { Branch } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('BranchRepository')
export default class BranchRepository extends Repository<Branch> {
  constructor () {
    super('branches')
  }
  public convertType(value: object): Branch {
    return Object.assign(new Branch(), value)
  }
}
