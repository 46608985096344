







































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { Branch, Group } from '@/models';
import UI from '@/controller/ui';
import BranchController from '@/controller/branch';
import BranchDialog from './branch-dialog.vue';
import _ from 'lodash';
import { mdiEyeOutline } from '@mdi/js';

export default Vue.extend({
  components: {
    Editable,
    BranchDialog,
  },
  data() {
    return {
      title: 'Standorte',
      show: false,
      edit: false,
      selected: new Branch(),
      controller: new BranchController(),
      items: <Branch[]>[],
      icons: {
        mdiEyeOutline,
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Branch);
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Postalcode'),
        value: 'address.postcode',
      });
      headers.splice(headers.length - 2, 0, {
        text: __('Model.District'),
        value: 'address.district',
      });
      headers.splice(headers.length, 0, {
        text: __('Model.View'),
        value: 'view',
      });
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.items = [];
      this.controller.init().then(x => {
        this.items = x;
      });
    },
    clearform() {
      this.selected = new Branch();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        this.controller.removeBranch(item).then(() => {
          this.refresh();
        });
      }
      // this.clearform()
    },
    create() {
      this.selected = new Branch();
      this.show = true;
    },
    doubleclick(item: any) {
      this.update(item);
    },
  },
});
