





















































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { Import } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiDelete, mdiPencilOutline, mdiHelpCircleOutline, mdiEmailOutline, mdiUpload } from '@mdi/js';
import ImportController from '@/controller/import';
import { ImportStatus } from '@/enums';

export default Vue.extend({
  components: {
    Editable,
  },
  data() {
    return {
      ImportStatus,
      title: 'Termine Import',
      show: false,
      edit: false,
      selected: new Import(),
      controller: new ImportController(),
      items: new Array<Import>(),
      statusColor: {
        Error: 'error',
        Success: 'success',
        Processing: 'warning',
      },
      icons: {
        mdiEmailOutline,
        mdiDelete,
        mdiPencilOutline,
        mdiUpload,
        mdiHelpCircleOutline,
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Import);
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init().then((imports: Import[]) => {
        this.items = imports;
      });
    },
    clearform() {
      this.selected = new Import();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;

      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (item.default) {
        UI.snackOpen('Standard kann nicht gelöscht werden', 'warning');
        return false;
      }
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        this.controller.deleteImport(item).then(() => {
          this.refresh();
        });
      }
    },
    showErrors(item: any) {
      const _errors = item.content.map((x: any) => x.error_message) as Array<string>;
      const errors = _errors.filter(x => !_.isEmpty(x));
      alert(errors.join('\r\n'));
    },
    create() {
      // this.selected = new Import();
      // this.show = true;
      this.$router.push({ name: 'ImportProc' });
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
  },
});
