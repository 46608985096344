import Configs from '@/config/config';
import { Branch, TestCenter, User } from '@/models';
import StringUtils from '@/utils/string-utils';
import * as Cookies from 'js-cookie';
import { PropSync } from 'vue-property-decorator';

/**
 *  @description optimized 19.04.2022
 */
export default class Cache {
  static remember: boolean = false;
  static setjwtExp() {
    if (this.jwt) {
      this.setCookie('jwt', this.jwt, this.remember ? 10 : 0);
    }
  }

  static deleteUser() {
    this.deleteCookie('user');
  }

  static deleteBranches() {
    this.deleteStorage('branches');
  }

  static deleteTestCenter() {
    this.deleteStorage('testCenter');
  }

  // read user in cookie
  public static get jwt() {
    return this.getCookie('jwt');
  }

  // read user in cookie
  public static get User(): User {
    const userString = this.getCookie('user') || '';
    if (StringUtils.isEmpty(userString)) return <any>null;
    const user = Object.assign(new User(), JSON.parse(userString));
    return user;
  }

  // write user in cookie
  public static set User(newUser: User) {
    this.setCookie('user', JSON.stringify(newUser), this.remember ? 10 : 0);
  }

  // read testCenter in cookie
  public static get testCenter(): TestCenter {
    const testCenterString = this.getStorage('testCenter') || '';
    if (StringUtils.isEmpty(testCenterString)) return <any>null;
    const testCenter = Object.assign(new TestCenter(), JSON.parse(testCenterString));
    return testCenter;
  }

  // write testCenter in cookie
  public static set testCenter(newTestCenter: TestCenter) {
    this.setStorage('testCenter', JSON.stringify(newTestCenter));
  }

  // read branches in cookie
  public static get branches(): Branch[] {
    const branchesString = this.getStorage('branches') || '';
    if (StringUtils.isEmpty(branchesString)) return <any>null;
    const branches = JSON.parse(branchesString);
    return branches;
  }

  // write branches in cookie
  public static set branches(newbranches: Branch[]) {
    this.setStorage('branches', JSON.stringify(newbranches));
  }

  /**
   * write cookie
   * @param cname Cookie Name
   * @param cvalue Cookie
   * @param exdays life date
   */
  public static setCookie(cname: string, cvalue: string, exdays: number = 0, secure: boolean = true) {
    Cookies.set(cname, cvalue, { expires: exdays == 0 ? undefined : exdays, secure: secure });
  }

  /**
   * read cookie
   * @param cname Cookie Name
   * @returns cname
   */
  public static getCookie(cname: string) {
    return Cookies.get(cname);
  }

  /**
   * delete cookie
   * @param cname Cookie name to be deleted
   */
  public static deleteCookie(cname: string) {
    Cookies.remove(cname);
  }

  /**
   * write storage
   * @param cname Cookie Name
   * @param cvalue Cookie
   * @param exdays life date
   */
  public static setStorage(cname: string, cvalue: string) {
    localStorage.setItem(cname, cvalue);
  }

  /**
   * read cookie
   * @param cname Cookie Name
   * @returns cname
   */
  public static getStorage(cname: string) {
    return localStorage.getItem(cname);
  }

  /**
   * delete storage
   * @param cname Cookie name to be deleted
   */
  public static deleteStorage(cname: string) {
    localStorage.removeItem(cname);
  }
}
