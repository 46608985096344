
















































import Configs from '@/config/config';
import moment from 'moment';
import Vue from 'vue';
import DatePicker from '@/components/datepicker.vue';
import ExportController from '@/controller/export';
import DownloadController from '@/controller/download';
import { AppointmentResult } from '@/enums';
import _ from 'lodash';
import UI from '@/controller/ui';
import Env from '@/plugins/env';
import * as XLSX from 'xlsx';

export default Vue.extend({
  name: 'Exports',
  components: {
    DatePicker,
  },
  data() {
    return {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      exportType: 0,
      exportFormat: 0,
      controller: new ExportController(),
    };
  },
  mounted() {},
  methods: {
    today() {
      let date = moment().format(Configs.dateFormat);
      this.start = date;
      this.end = date;
    },
    yesterday() {
      let date = moment().subtract(1, 'days').format(Configs.dateFormat);
      this.start = date;
      this.end = date;
    },
    lastMonths() {
      let date = moment().subtract(1, 'months');
      let start = date.startOf('months').format(Configs.dateFormat);
      let end = date.endOf('months').format(Configs.dateFormat);
      this.start = start;
      this.end = end;
    },
    thisMonths() {
      let date = moment();
      let start = date.startOf('months').format(Configs.dateFormat);
      let end = date.endOf('months').format(Configs.dateFormat);
      this.start = start;
      this.end = end;
    },
    apply() {
      let start = moment(this.start, Configs.dateFormat).format(Configs.dateServerFormat);
      let end = moment(this.end, Configs.dateFormat).format(Configs.dateServerFormat);
      let result = AppointmentResult.Positive as any;
      if (this.exportType == 0) {
        result = undefined;
      }
      this.controller.export(start, end, result).then(x => {
        this.controller.loading = true;
        _.delay(
          () => {
            try {
              if (this.exportFormat == 0) {
                const ws = XLSX.utils.json_to_sheet(x);
                const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' });
                const sperator = '"sep=,"\r\n';
                DownloadController.download(sperator + csv, 'text/csv', 'Termin-Export.csv', true);
              } else if (this.exportFormat == 1) {
                const wb = XLSX.utils.book_new();
                const filename = 'Termine-Export';
                const ws = XLSX.utils.json_to_sheet(x);
                XLSX.utils.book_append_sheet(wb, ws, filename.replace('/', ''));
                const buffer = XLSX.write(wb, { type: 'buffer', bookType: 'xlsx' });
                DownloadController.downloadBlob(
                  buffer,
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'Termin-Export.xlsx',
                );
              }
            } catch (err) {
              UI.snackOpen('Parser Error: ' + (err as Error).message, 'error');
              console.error(err);
            }
            this.controller.loading = false;
          },
          Env.isDev ? 0 : 2000,
        );
      });
    },
  },
});
