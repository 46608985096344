










import Configs from '@/config/config'
import Vue from 'vue'

export default Vue.extend({
  name: 'Privacy',
  data: () => ({}),
  mounted() {

  },
  methods: {},
})
