import { TestCenter, TestKit } from '@/models';
import _ from 'lodash';
import BaseController from './base';
import Cache from './cache';
import Session from './session';
import UI from './ui';

/**
 *  @description optimized 19.04.2022
 */
export default class TestkitController extends BaseController {
  constructor() {
    super();
  }

  public async init() {
    this.loading = true;
    await Session.reloadTestCenter();
    let all = (await this.testCenter.allTestkit) ?? <TestKit[]>[];
    this.loading = false;
    return {
      all: all,
      actives: this.testCenter.active_testkits ?? <TestKit[]>[],
    };
  }

  public async insertTestKit(testkit: TestKit, active: boolean = true) {
    testkit.test_center = this.testCenter;
    let testkit_packaged = testkit.package();
    return await this.uow.Testkits.insert(testkit_packaged).then(async res => {
      this.setActive(res, active);
      return res;
    });
  }

  public async updateTestKit(testkit: TestKit, active: boolean = true) {
    testkit.test_center = this.testCenter;
    let testkit_packaged = testkit.package();
    return await this.uow.Testkits.update(testkit_packaged).then(res => {
      this.setActive(res, active);
      return res;
    });
  }

  //? CheckIt: default test kit is cannot be disabled
  public async setActive(testkit: TestKit, active: boolean = true) {
    let cloneTestCenter = Object.assign(new TestCenter(), this.testCenter);
    cloneTestCenter.active_testkits = cloneTestCenter.active_testkits || [];

    let check = cloneTestCenter.active_testkits.filter(x => x.id == testkit.id);
    if (testkit.default && !active) {
      UI.snackOpen('Default cannot be disabled.', 'warning');
      return testkit;
    }
    if (check != null && check.length > 0) {
      if (active) {
        return testkit;
      } else if (cloneTestCenter.active_testkits.length > 1) {
        // remove active
        cloneTestCenter.active_testkits = cloneTestCenter.active_testkits.filter(x => x.id != testkit.id);
        let cloneTestCenter_packaged = cloneTestCenter.package(false, false);
        cloneTestCenter_packaged = _.omit(cloneTestCenter_packaged, [
          'users',
          'testkits',
          'default_testkit',
        ]) as TestCenter;
        await this.uow.TestCenters.update(cloneTestCenter_packaged);
        return testkit;
      } else {
        UI.snackOpen('At least one testkit must be active', 'warning');
        return testkit;
      }
    } else {
      if (active) {
        // add active
        cloneTestCenter.active_testkits.push(testkit);
        let cloneTestCenter_packaged = cloneTestCenter.package(false, false);
        cloneTestCenter_packaged = _.omit(cloneTestCenter_packaged, [
          'users',
          'testkits',
          'default_testkit',
        ]) as TestCenter;
        await this.uow.TestCenters.update(cloneTestCenter_packaged);
        return testkit;
      } else {
        // remove active
        return testkit;
      }
    }
  }

  public async setDefault(testkit: TestKit) {
    let cloneTestCenter = _.clone(this.testCenter);
    cloneTestCenter.default_testkit = testkit;
    Cache.testCenter = cloneTestCenter;

    let test_center_packaged = this.testCenter.package(false, false);
    test_center_packaged = _.omit(test_center_packaged, ['users', 'testkits', 'active_testkits']) as TestCenter;
    return this.uow.TestCenters.update(test_center_packaged).then(res => {
      return res;
    });
  }

  public async deleteTestkit(testkit: TestKit) {
    return this.uow.Testkits.delete(testkit.id).then(res => {
      return res;
    });
  }
}
