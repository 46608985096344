import { DataTableHeader } from 'vuetify';
import moment from 'moment';
import Configs from '@/config/config';
import { IModel } from '@/models/imodels';
import {
  displayNameSym,
  dividerSym,
  hiddenSym,
  ICustomeColumn,
  minSym,
  permissionSym,
  repoNameSym,
  requiredSym,
} from '@/controller/reflections';
import File from '@/models/file';
import { Role } from '@/enums';
import Cache from './cache';
import _ from 'lodash';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);

import { Ref, ref } from '@vue/composition-api';

/**
 *  @description optimized 19.04.2022
 */
export default class UI {
  //#region Variables
  public static get async_refresh(): Boolean {
    const value = Cache.getCookie('async_refresh');
    return value == 'true';
  }
  public static set async_refresh(value: Boolean) {
    Cache.setCookie('async_refresh', value.toString());
    if (value) {
      this.$emit('table.refresh', { start_date: moment().format(Configs.dateServerFormat) });
    }
  }

  //#endregion
  public static isReady() {
    const condition1 = (window as any).vue;
    return condition1;
  }
  // #region Reflector operations

  public static getModelHeader<T extends IModel>(type: new () => T): DataTableHeader[] {
    const obj = new type();
    const props = _.keys(obj);
    let hiddenProps = props
      .map(key => {
        const data = Reflect.getMetadata(hiddenSym, obj, key);
        return data != null ? data[0] : null;
      })
      .filter(v => {
        return v != null;
      });
    // const hiddenProps: string[] = Reflect.getMetadata(hiddenSym, obj) || [];

    const result = props
      .filter(key => !hiddenProps.includes(key) && this.getPermission(obj, key))
      .map(key => {
        const custome = this.getCustomeColumn(obj, key);
        return {
          value: custome.path ?? key,
          text: this.getDisplayName(obj, key),
          divider: custome.divider,
        };
      });
    return result;
  }

  public static getModelValidation(obj: any): boolean {
    const props = Object.keys(obj);
    return props.some(x => {
      let result = false;
      const required = this.getRequired(obj, x);
      const min = this.getMin(obj, x);

      if (min) {
        result ||= this.getPropMinValidation(obj, x, min);
      }

      if (required) {
        result ||= this.getPropValidation(obj, x);
      }

      return result;
    });
  }

  public static getPropMinValidation(obj: any, property: string, min: number): boolean {
    const value = obj[property];
    if (_.isEmpty(value)) return false;
    return value.length < min;
  }

  public static getPropValidation(obj: any, property: string): boolean {
    // Clone With Out Vue observe object
    function cloneWithOut(value: any) {
      return JSON.parse(JSON.stringify(value));
    }

    const value = obj[property]; // value : IModel or string | boolean | number

    let result = _.isNull(value); // isNull validate

    // if value type is IModel then check id
    if (_.isArray(value)) {
      result ||= value.length == 0;
    } else if (value instanceof File) {
      return result;
    } else if (!result && typeof value === 'object') {
      const clearValue = cloneWithOut(value);
      const valueId = clearValue.id;
      result ||= _.isUndefined(valueId);
      result ||= valueId == 0;
      // if (_.isNumber(valueId)) {}
    } else if (typeof value === 'string') {
      result ||= _.isEmpty(value) || _.isEmpty(this.getInnerText(value));
    } else if (typeof value === 'number') {
      result ||= value == 0;
    } else if (typeof value === 'boolean') {
      result ||= !value; // if value is false then not validated
    }

    return result;
  }

  public static getRequired(obj: any, property: string): boolean {
    const prototype = obj.prototype || obj;
    const data = Reflect.getMetadata(requiredSym, prototype, property) || false;
    if (_.isBoolean(data)) return data;

    if (data.or) {
      return this.getPropValidation(obj, data.or);
    }

    return false;
  }

  public static getMin(obj: any, property: string): number {
    const prototype = obj.prototype || obj;
    const data = Reflect.getMetadata(minSym, prototype, property) || false;
    return data;
  }

  public static getCustomeColumn(obj: any, property: string): ICustomeColumn {
    const prototype = obj.prototype || obj;
    const empty = { divider: false };
    return Reflect.getMetadata(dividerSym, prototype, property) || empty;
  }

  public static getTime(value?: Date | string) {
    let date = moment(value, Configs.timeServerFormat);
    if (date.isValid()) {
      let result = date.format(Configs.timeFormat);
      return result;
    }
    return '';
  }

  public static getTimeServer(value: string): string {
    let date = moment(value, Configs.timeFormat);
    if (date.isValid()) {
      let result = date.format(Configs.timeServerFormat);
      return result;
    }
    return '';
  }

  public static getDate(value: any) {
    let date = moment(value);
    if (date.isValid()) {
      let result = date.format(Configs.dateFormat);
      return result;
    }
    return '';
  }

  public static getDateTime(value: Date | string) {
    let date = moment(value);
    if (date.isValid()) {
      let result = date.format(Configs.dateTimeFormat);
      return result;
    }
    return '';
  }

  public static dateMergerTime(date: Date, time: string) {
    let result = moment(date + ' ' + time, Configs.dateServerFormat + ' ' + Configs.timeServerFormat);
    if (result.isValid()) {
      return result.format(Configs.dateTimeFormat);
    }
    return '';
  }

  public static getTimeLeft(datetime: Date | null, mode?: moment.unitOfTime.Base) {
    if (_.isUndefined(datetime) || _.isNull(datetime)) return 'undefined';

    let date = moment(datetime, Configs.dateTimeServerFormat);

    if (!_.isUndefined(mode)) {
      let timeLeft = moment().diff(date, mode);
      return timeLeft;
    }

    if (date.isValid()) {
      let timeLeft = moment.utc(moment().diff(date)).format(Configs.timeSecondFormat);
      return timeLeft;
    }
    return '';
  }

  public static getDisplayName(obj: any, property: string): string {
    const prototype = obj.prototype || obj;
    const metaData = Reflect.getMetadata(displayNameSym, prototype, property);
    if (!metaData) {
      return property;
    } else {
      if (metaData.trans) {
        return (window as any).vueApp.$i18n.tc(metaData.name);
      } else {
        return metaData.name;
      }
    }
  }

  public static getPermission(obj: any, property: string): boolean {
    const prototype = obj.prototype || obj;
    const metaData = Reflect.getMetadata(permissionSym, prototype, property);
    if (!metaData) {
      return true;
    } else {
      const roles = metaData.roles as Role[];
      const currentUserRole = Cache.User.role.name as Role;
      if (roles) {
        return roles.includes(currentUserRole);
      } else {
        return metaData.name;
      }
    }
  }

  public static getRepoName(obj: any): string {
    const res = Reflect.getMetadata(repoNameSym, obj);
    return res;
  }

  public static getInnerText(value: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    return doc.body.innerText;
  }
  public static getResultUrl(value: string) {
    return Configs.resultURL + value;
  }
  // #endregion

  // #region UI operations
  public static getURLBase(path: string): string {
    return Configs.api_base_url + path;
  }

  public static getURL(path: string): string {
    return Configs.api_url + path;
  }

  public static getHttpURL(url: string): string {
    let result = url;
    if (result.indexOf('http') > -1) {
      return result;
    }
    result = 'https://' + result;
    return result;
  }

  public static getImageURL(image?: File): string {
    if (!image) return '';
    const url = image.formats ? image.formats.thumbnail.url : image.url;
    return Configs.api_base_url + url;
  }

  public static snackOpen(message: string, color?: string, timeOut: number = 2000) {
    if (timeOut == 2000) {
      timeOut = color == 'error' ? 3000 : 2000 + message.length * 10;
    }
    (window as any).vueApp.$root.$eventBus.$emit('snackOpen', message, color, timeOut);
  }

  public static pageLayout(blank: boolean) {
    (window as any).vueApp.$root.$eventBus.$emit('pageLayout', blank);
  }

  public static getMB(kb: number) {
    return kb / 1024;
  }

  public static getCurrency(price?: number) {
    const coin = price ?? 0;
    return coin.toFixed(2) + ' €';
  }

  public static calcTax(price?: number, tax?: number) {
    const _price = price ?? 0;
    const _tax = tax ?? 0;
    let calc_tax = _tax > 0 ? _tax / 100 + 1 : 1;
    let calc_price = _price * calc_tax;
    return this.getCurrency(calc_price);
  }

  public static getPercentage(rate?: number) {
    const coin = rate ?? 0;
    return coin.toFixed(2) + ' %';
  }
  public static phoneMask = {
    inputMask: '+## (###) ### ## ##',
    outputMask: '+## (###) ### ## ##',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
  };

  public static dateMask = {
    inputMask: Configs.dateFormat,
    empty: null,
    alphanumeric: true,
  };

  public static get vue(): Vue {
    return (window as any).vueApp;
  }
  public static copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        UI.snackOpen('Text wurde kopiert', 'success');
      })
      .catch(() => {
        UI.snackOpen('Text konnte nicht kopiert werden!', 'warning');
      });
    // navigator.clipboard.writeText(this.$refs[refName] as any)
  }
  // #endregion

  //#region events
  public static $on(event: string, handler: Function) {
    (window as any).vueApp.$root.$eventBus.$on(event, handler);
  }
  public static $off(event: string, handler: Function) {
    (window as any).vueApp.$root.$eventBus.$off(event, handler);
  }
  public static $emit(event: string, data?: any) {
    (window as any).vueApp.$root.$eventBus.$emit(event, data);
  }
  //#endregion
}
