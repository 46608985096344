import { customeColumn, display, hidden, notMapped } from '@/controller/reflections';
import Address from './address';
import Customer from './customer';
import Group from './group';
import Holiday from './holiday';
import { ICU } from './imodels';
import Import from './import';
import TestCenter from './test-center';
import TimeSlot from './time-slot';

export default class Branch extends ICU {
  @display('Model.CompanyName', true)
  public company_name: string = '';
  @hidden()
  @display('Model.FirstName', true)
  public first_name: string = '';
  @hidden()
  @display('Model.LastName', true)
  public last_name: string = '';
  @display('Model.Description', true)
  public comment: string | null = '';
  @hidden()
  @display('Model.Note', true)
  public note: string | null = '';
  @hidden()
  public location_open?: boolean = false;
  @hidden()
  public reservation_limit: boolean = false;
  @hidden()
  public customer_card: boolean = false;
  @hidden()
  public reservation_btn: boolean = false;
  @hidden()
  public reservation_time_slot: boolean = false;
  @display('Model.IsActive', true)
  public status: boolean = true;
  @notMapped()
  @hidden()
  public time_slots: TimeSlot[] = <TimeSlot[]>[];
  @notMapped()
  @hidden()
  public holidays: Holiday[] = <Holiday[]>[];
  @display('Model.Group', true)
  @customeColumn({ path: 'group.name' })
  @notMapped()
  public group: Group | null = null;
  @display('Model.Street', true)
  @customeColumn({ path: 'address.street' })
  public address: Address = new Address();
  @hidden()
  @notMapped()
  public test_center: TestCenter = new TestCenter();
  @hidden()
  @notMapped()
  public customers: Customer[] = [];
  public url?: string | null;
  @hidden()
  @notMapped()
  public uid = '';
  @hidden()
  public imports: Array<Import> = [];
}
