import { FilterType } from '@/enums';
import StringUtils from '@/utils/string-utils';
import IStrapiParam from './istrapi-param';
export default class Filter implements IStrapiParam {
  constructor(name?: string, value?: string, index: number = 0, filter_type: FilterType = FilterType.$eq) {
    this.filter_type = filter_type;
    this.name = name ?? '';
    this.value = value ?? '';
    this.index = index;
  }
  public filter_type: FilterType = FilterType.$eq;
  public condition_type?: FilterType;
  public paramType: string = 'Filter';
  public name: string = '';
  public value: any;
  public index: number = 0;

  public url(): string {
    const condition = StringUtils.isEmpty(this.condition_type) ? '' : `[${this.condition_type}][${this.index}]`;
    const inTypes = [FilterType.$in, FilterType.$notIn];
    const conditionInNotIN = this.condition_type != null ? inTypes.includes(this.condition_type) : false;
    let name = this.name
      .split('.')
      .map(x => '[' + x + ']')
      .join('');
    if (conditionInNotIN) {
      return `filters${name}[${this.condition_type}][${this.index}]=${this.value}`;
    }
    if (this.condition_type == FilterType.$and) {
      if (this.filter_type == FilterType.$in || this.filter_type == FilterType.$notIn) {
        const ins = this.value.map((x: any, i: any) => `filters${condition}${name}[${this.filter_type}][${i}]=${x}`);
        return ins.join('&');
      }
    }
    return `filters${condition}${name}[${this.filter_type}]=${this.value}`;
  }
}
