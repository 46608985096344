import Configs from '@/config/config';
import Crypto from '@/utils/crypto';
import Cache from './cache';
import Session from './session';
import UI from './ui';

/**
 *  @description optimized 19.04.2022
 */
export default class WebSocketAPI {
  private socket?: WebSocket;
  private isLogin = false;
  private subscribers = new Map<String, Function>();
  public onConnected?: () => void;

  private static _instance?: WebSocketAPI;

  public static get create(): WebSocketAPI {
    if (this._instance) {
      return this._instance;
    }
    this._instance = new this();
    return this._instance;
  }

  constructor() {
    this.socket = new WebSocket(Configs.ws_api_url);
    // Connection opened
    this.socket.addEventListener('open', _ => {
      if (typeof this.onConnected === 'function') {
        this.onConnected();
      }
    });
    // Listen for messages
    this.socket.addEventListener('message', (event: any) => {
      this.message(event.data);
    });
    // Listen for close
    this.socket.addEventListener('close', (_: any) => {
      console.log('connection closed');
    });
    // Listen for close
    this.socket.addEventListener('error', (_: any) => {
      console.log('connection error');
      Session.logout();
      UI.snackOpen('Your session has timed out.');
    });
    (window as any).socket = this;
  }

  public static logout() {
    if (this._instance) {
      this._instance.logout();
    }
  }

  logout() {
    try {
      this.subscribers.clear();
      this.socket?.close(3052022);
    } catch (err) {
      UI.snackOpen('WebSocket Failed');
    }
  }

  send(data: any) {
    if (this.socket?.readyState == WebSocket.OPEN) {
      this.socket?.send(JSON.stringify(data));
    }
  }

  subscribe(name: string, event: string = '*', callback: Function) {
    // burada hem entity name hemde event belirtilmeli
    this.send({
      method: 'subscribe',
      data: {
        name,
        event,
      },
    });
    this.subscribers.set(name + ':' + event, callback);
  }

  addSubscribe(name: string, event: string = '*', callback: Function) {
    const id = name + ':' + event + '_' + Crypto.NoSqlId();
    this.subscribers.set(id, callback);
  }

  clearSubscribe(entity_name: string) {
    this.subscribers.forEach((e: Function, k: String) => {
      if (k.includes(entity_name)) {
        this.unsubscribe(k);
      }
    });
  }

  unsubscribe(name: String, event: string = '*') {
    this.send({
      method: 'unsubscribe',
      data: {
        name,
        event,
      },
    });
    this.subscribers.delete(name + ':' + event);
  }

  emit(name: string, event: string = '*', data: any) {
    this.subscribers.forEach((callback: Function, k: String) => {
      if (k.includes(name + ':' + event) || k.includes(name + ':*')) {
        if (typeof callback === 'function') {
          callback(data, { name, event });
        }
      }
    });
  }

  private message(comingData: any) {
    const model = JSON.parse(comingData);
    const keys = Object.keys(model);
    if (!keys.includes('method')) {
      console.log('method not found');
      return;
    }
    const method = model.method;
    switch (method) {
      case 'emit':
        this.emit(model.name, model.event, model.data);
        break;
      case 'subscribe':
        console.log(model);
      case 'unsubscribe':
        console.log(model);
        break;
      default:
        console.log(model);
        break;
    }
  }
}
