






















































































import Vue from 'vue';
import Session from '../../controller/session';
import _ from 'lodash';
import StringUtils from '@/utils/string-utils';
import UI from '@/controller/ui';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { Branch } from '@/models';
import BranchController from '@/controller/branch';

export default Vue.extend({
  name: 'ResetPassword',
  data: () => ({
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    password: '',
    passwordconfirm: '',
    isPasswordVisible: false,
    isPasswordVisible2: false,
    passwordRules: [
      (v: string) => !!v || 'Passwort wird benötigt',
      (v: string) => v.length > 7 || 'Passwort muss 8-stellig sein',
    ],
    changed: false,
    validation: false,
    customer_mode: false,
    page: {
      logo: require('@/assets/images/logos/logo.png'),
      title: 'CoTest24',
    },
    branch: new Branch(),
    controller: new BranchController(),
    branch_url: '',
  }),
  mounted() {
    this.customer_mode = !StringUtils.isEmpty(this.$route.params.customer);
    this.branch_url = this.$route.query.url as string;

    if (this.customer_mode) {
      this.customer_mode = !StringUtils.isEmpty(this.$route.params.customer);
      this.controller.getBranchFromURL(this.branch_url).then(x => {
        if (!x) {
          return;
        }

        this.branch = x;
        this.page.title = x.company_name;
        this.page.logo = UI.getImageURL(x.test_center.logo);
      });
    }
  },
  methods: {
    passwordConfirmRules(v: string) {
      if (!v) {
        return 'confirm Passwort wird benötigt';
      } else if (v.length < 8) {
        return 'confirm Passwort muss 8-stellig sein';
      } else if (this.password != this.passwordconfirm) {
        return 'confirm password must be equal to the password';
      }
      return true;
    },
    checkToken(token: string): boolean {
      if (StringUtils.isEmpty(token)) {
        UI.snackOpen('Forgot Password Token Invalid', 'error');
        this.controller.loading = false;
        return false;
      }
      return true;
    },
    reset() {
      (this.$refs.resetPw as any).validate();
      if (!this.validation) {
        UI.snackOpen('Please Fill Forgot Password Form', 'error');
        return;
      }
      this.controller.loading = true;

      const token = this.$route.query.code?.toString();
      if (!this.checkToken(token)) return;

      Session.resetPassword(token, this.password, this.customer_mode)
        .then(res => {
          if (res) {
            this.changed = true;
            return;
          } else {
            UI.snackOpen('Remember Password Failed', 'error');
          }
        })
        .then(x => {
          UI.snackOpen('Remember Password Success', 'success');
        })
        .catch(x => {
          UI.snackOpen(x.message, 'error');
        })
        .finally(() => {
          this.controller.loading = false;
        });
    },
  },
});
