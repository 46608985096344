import _ from 'lodash';

/**
 * @description meta json datalarını kolayca modellemek için bir sınıf
 * @example
 * let obj = new JsonObject();
 * obj.my_attribute = my_value;
 * obj.parse()
 * @returns "{ my_attribute : my_value }"
 */
export default class JsonObject implements Dict<string> {
  /**
   * Eğer başlangıçta bir json belirtilirse bunu load etmeliyiz.
   */
  constructor(data?: any) {
    if (data) {
      this.load(data);
    }
  }

  [key: string]: any | undefined;

  /**
   *
   * @param data string json
   * @returns this
   */
  public load(data: any) {
    let jsonData = JSON.parse(data);
    _.each(jsonData, (value, key) => {
      this[key] = value;
    });
    return this;
  }

  /**
   *
   * @returns string json
   */
  public parse() {
    return JSON.stringify(this);
  }
}
