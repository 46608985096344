import Session from '@/controller/session';
import Vue from 'vue';
import VueRouter, { Route, RouteConfig, RouteMeta } from 'vue-router';
import Home from '../views/home/home.vue';
import Events from '../views/events/events.vue';
import Laboratory from '../views/laboratory/laboratory.vue';
import Login from '../views/auth/login.vue';
import ForgotPassword from '../views/auth/forgot-password.vue';
import ResetPassword from '../views/auth/reset-password.vue';
import Register from '../views/auth/register.vue';
import Profile from '../views/profile/profile.vue';
import MyCompany from '../views/my-company/my-company.vue';
import Invoices from '../views/invoices/invoices.vue';
import LocationGroups from '../views/location-groups/location-groups.vue';
import Locations from '../views/locations/locations.vue';
import Appointments from '../views/appointments/appointments.vue';
import Employees from '../views/employees/employees.vue';
import Imports from '../views/imports/imports.vue';
import Exports from '../views/exports/exports.vue';
import TestCounts from '../views/test-counts/test-counts.vue';
import Booking from '../views/booking/booking.vue';
import PageNotFound from '../views/error-404/error-404.vue';
import Statistic from '../views/statistic/statistic.vue';
import BookingPreview from '../views/booking-preview/booking-preview.vue';
import Testkits from '../views/testkits/testkits.vue';
import Result from '../views/result/result.vue';
import AppCustomers from '../views/app-customers/app-customers.vue';
import Impressum from '../views/impressum/impressum.vue';
import Privacy from '../views/privacy/privacy.vue';
import CustomeFields from '../views/custome-fields/custome-fields.vue';
import PDFDesigner from '../views/pdf-designer/pdf-designer.vue';
import ImportProc from '../views/import-proc/import-proc.vue';
// import InvoiceCreate from '../views/invoices/components/invoice-create.vue';
import PositiveResults from '../views/positive-results/positive-results.vue';
import PaymentMethods from '../views/payment-methods/payment-methods.vue';
import Products from '../views/products/products.vue';
import Sales from '../views/sales/sales.vue';
import Cancel from '../views/cancel/cancel.vue';
import Reservations from '../views/reservations/reservations.vue';
//EndImport
import UI from '@/controller/ui';
import WebSocketAPI from '@/controller/websocket';
import { Appointment } from '@/models';
import { WebSocketEvent } from '@/enums';

Vue.use(VueRouter);

const permissions = (to: Route, from: Route, next: any) => {
  // Is the blank page
  const isBlank = to.meta?.blank || false;
  // Is the user name not null
  const isAuthenticated = Session.isAuth();
  if (isBlank) {
    next();
  } else if (to.name !== 'Login' && !isAuthenticated) {
    // WebSocketAPI.logout();
    next({ name: 'Login' });
  } else if (Session.permissionCheck(to.name)) {
    // const wss = WebSocketAPI.create;
    // wss.onConnected = () => {
    //   wss.subscribe('appointments', WebSocketEvent.Create, (data: Appointment, req: any) => {
    //     if (!UI.async_refresh) {
    //       return;
    //     }
    //     UI.$emit('table.refresh', data);
    //   });
    //   wss.subscribe('appointments', WebSocketEvent.Update, (data: Appointment, req: any) => {
    //     if (!UI.async_refresh) {
    //       return;
    //     }
    //     UI.$emit('table.refresh', data);
    //   });
    // };
    next();
  } else {
    if (!UI.isReady()) {
      if (from.name == 'PageNotFound') {
        if (Session.isAdmin()) {
          next({ name: 'AppCustomers' });
        } else if (Session.isCustomer()) {
          next({ name: 'Reservations' });
        } else {
          next({ name: 'Home' });
        }
      } else {
        router.back();
        return;
      }
    }
    UI.snackOpen('Permission Denied', 'error');
    next(false);
  }
};

const logindetector = (to: Route, from: Route, next: any) => {
  // Is the user name not null
  const isAuthenticated = Session.isAuth();
  if (isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
};

const routeMeta = { blank: true };

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: Session.isAdmin() ? '/app-customers' : Session.isCustomer() ? '/reservations' : '/dashboard',
    beforeEnter: permissions,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Home,
    beforeEnter: permissions,
    meta: { title: 'Dashboard' },
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    beforeEnter: permissions,
    meta: { title: 'Termine' },
  },
  {
    path: '/laboratory',
    name: 'Laboratory',
    component: Laboratory,
    beforeEnter: permissions,
    meta: { title: 'Labor' },
  },
  {
    path: '/login/:url?',
    name: 'Login',
    beforeEnter: logindetector,
    meta: { ...routeMeta, title: 'Login' },
    component: Login,
  },
  {
    path: '/forgot-password/:url?',
    name: 'ForgotPassword',
    meta: { ...routeMeta, title: 'Forgot Password' },
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:customer?',
    name: 'ResetPassword',
    beforeEnter: logindetector,
    meta: { ...routeMeta, title: 'Reset Password' },
    component: ResetPassword,
  },
  {
    path: '/register',
    name: 'Register',
    beforeEnter: logindetector,
    meta: { ...routeMeta, title: 'Register' },
    component: Register,
  },
  {
    path: '/landing-page',
    name: 'LandingPage',
    meta: { title: 'Landing Page' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: permissions,
    meta: { title: 'Profile' },
  },
  {
    path: '/my-company/:upgrade?',
    name: 'MyCompany',
    component: MyCompany,
    beforeEnter: permissions,
    meta: { title: 'Mein Unternehmen' },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    beforeEnter: permissions,
    meta: { title: 'Rechnungen' },
  },
  // {
  //   path: '/invoice-create/:uid?',
  //   name: 'InvoiceCreate',
  //   component: InvoiceCreate,
  //   beforeEnter: permissions,
  //   meta: { title: 'Create Rechnungen' },
  // },
  {
    path: '/location-groups',
    name: 'LocationGroups',
    component: LocationGroups,
    beforeEnter: permissions,
    meta: { title: 'Standortgruppen' },
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    beforeEnter: permissions,
    meta: { title: 'Standorte' },
  },
  {
    path: '/appointments',
    name: 'Appointments',
    component: Appointments,
    beforeEnter: permissions,
    meta: { title: 'Terminvergabe' },
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    beforeEnter: permissions,
    meta: { title: 'Mitarbeiter' },
  },
  {
    path: '/imports',
    name: 'Imports',
    component: Imports,
    beforeEnter: permissions,
    meta: { title: 'Termin-Import' },
  },
  {
    path: '/exports',
    name: 'Exports',
    component: Exports,
    beforeEnter: permissions,
    meta: { title: 'Export' },
  },
  {
    path: '/test-counts/:uid?',
    name: 'TestCounts',
    component: TestCounts,
    beforeEnter: permissions,
    meta: { title: 'Testzahlen' },
  },
  {
    path: '/booking/:type(redirect|location)/:id/:customer?',
    name: 'Booking',
    component: Booking,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: 'Buchung' },
  },
  {
    path: '/error-404',
    name: 'PageNotFound',
    component: PageNotFound,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: '404 - Page Not Found' },
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: Statistic,
    beforeEnter: permissions,
    meta: { title: 'statistic' },
  },
  {
    path: '/booking-preview/:id',
    name: 'BookingPreview',
    component: BookingPreview,
    beforeEnter: permissions,
    meta: { title: 'booking-preview' },
  },
  {
    path: '/testkits',
    name: 'Testkits',
    component: Testkits,
    beforeEnter: permissions,
    meta: { title: 'testkits' },
  },
  {
    path: '/result/:qr_code',
    name: 'Result',
    component: Result,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: 'Result' },
  },

  {
    path: '/app-customers',
    name: 'AppCustomers',
    component: AppCustomers,
    beforeEnter: permissions,
    meta: { title: 'App Customers' },
  },
  {
    path: '/impressum/:id',
    name: 'Impressum',
    component: Impressum,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: 'Impressum' },
  },
  {
    path: '/privacy/:id',
    name: 'Privacy',
    component: Privacy,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: 'Privacy' },
  },
  {
    path: '/custome-fields',
    name: 'CustomeFields',
    component: CustomeFields,
    beforeEnter: permissions,
    meta: { title: 'Benutzerdefinierte Felder' },
  },
  {
    path: '/pdf-designer',
    name: 'PDFDesigner',
    component: PDFDesigner,
    beforeEnter: permissions,
    meta: { title: 'PDF Designer' },
  },
  {
    path: '/import-proc',
    name: 'ImportProc',
    component: ImportProc,
    beforeEnter: permissions,
    meta: { title: 'Import Process' },
  },
  {
    path: '/positive-results',
    name: 'PositiveResults',
    component: PositiveResults,
    beforeEnter: permissions,
    meta: { title: 'Positiv Meldungen' },
  },
  {
    path: '/payment-methods',
    name: 'PaymentMethods',
    component: PaymentMethods,
    beforeEnter: permissions,
    meta: { title: 'PaymentMethods' },
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    beforeEnter: permissions,
    meta: { title: 'Products' },
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    beforeEnter: permissions,
    meta: { title: 'Rechungs' },
  },
  {
    path: '/booking/cancel/:qr_code',
    name: 'Cancel',
    component: Cancel,
    beforeEnter: permissions,
    meta: { ...routeMeta, title: 'Buchung stornieren' },
  },
  {
    path: '/reservations',
    name: 'Reservations',
    component: Reservations,
    beforeEnter: permissions,
    meta: { title: 'Ihr Termine' },
  },
  //EndRoute
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const defaultTitle = process.env.APP_NAME;

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    var title = [defaultTitle, to.meta?.title].filter(x => !!x);
    document.title = title.join(' - ');
    // document.documentElement.lang = Env.variable.APP_LANG ?? 'de';

    UI.pageLayout(to.meta?.blank || false);
  });
});
export default router;
