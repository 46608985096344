import { repoName } from '@/controller/reflections';
import { File } from '@/models';
import { Repository } from '@/uow/unit-of-work';
import Crypto from '@/utils/crypto';
import StringUtils from '@/utils/string-utils';

@repoName('StorageRepository')
export default class StorageRepository extends Repository<File> {
  constructor() {
    super('upload/files');
  }

  public convertType(value: object): File {
    return Object.assign(new File(), value);
  }

  public async customeInsert(file: File, ref?: string, field?: string, refId?: number): Promise<File> {
    if (!file.content) {
      throw new Error('content is not available');
    }
    return await this.Upload(file.content, undefined, ref, field, refId).then(upload => {
      let first = upload;

      first = first?.pop();
      if (!first) {
        console.warn('Data Not Found');
        throw new Error('Data Not Found');
      }

      return this.convertType(first);
    });
  }

  public async update(file: File): Promise<File> {
    if (!file.content) {
      throw new Error('content is not available');
    }
    if (file.id == 0) {
      throw new Error('file is not found');
    }
    return await this.Upload(file.content, file.id).then(upload => {
      let first = upload;

      first = first?.pop();
      if (!first) {
        console.warn('Data Not Found');
        throw new Error('Data Not Found');
      }

      return this.convertType(first);
    });
  }

  public async select(): Promise<File[]> {
    return [];
  }

  public async select_all(): Promise<File[]> {
    return [];
  }

  public async delete(id: number): Promise<boolean> {
    await this.api.request('delete', `/upload/files/${id}`);
    return true;
  }

  private async Upload(
    file: globalThis.File,
    id?: number,
    ref?: string,
    field?: string,
    refId?: number,
    requestConfig?: any,
  ) {
    const uploadUrl = '/upload' + (!StringUtils.isEmpty(id) ? `?${id}` : '');
    const formData = new FormData();
    formData.append('files', file);
    if (ref) {
      formData.append('ref', ref);
    }
    if (refId) {
      formData.append('refId', refId.toString());
    }
    if (field) {
      formData.append('field', field);
    }
    return await this.api.request('post', uploadUrl, Object.assign({ data: formData }, requestConfig));
  }
}
