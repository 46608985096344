










































































import Vue from 'vue';
import TestCenterController from '@/controller/test-center';
import { File, TestCenter, User } from '@/models';
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js';
import UI from '@/controller/ui';
import StorageController from '@/controller/storage';
import _ from 'lodash';
import Cache from '@/controller/cache';
import { use } from 'vue/types/umd';
import Session from '@/controller/session';
export default Vue.extend({
  name: 'AccountSettingsSecurity',
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    avatar: true,
    status: ['Germany', 'England', 'USA', 'Belgium'],
    country: 'Germany',
    icons: {
      mdiAlertOutline,
      mdiCloudUploadOutline,
    },
    user: Cache.User,
    testCenter: new TestCenter(),
    controller: new TestCenterController(),
    storageController: new StorageController(),
  }),
  mounted() {
    this.resetForm();
  },
  methods: {
    resetForm() {
      this.testCenter = this.controller.testCenter;
    },
    save() {
      this.controller.update(_.clone(this.testCenter)).then(testCenter => {
        this.controller.uow.Users.update(
          {
            id: this.user.id,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
          } as User,
          false,
        ).then(x => {
          Cache.User = x;
          Session.reload()
          UI.snackOpen(__('Success'));
        });
      });
    },
    async upload(e: Event) {
      const allowTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const pp = (e.target as any).files[0];
      if (pp.size > 1024 * 1024 * 1.1) {
        let size = Math.round(pp.size / 1024 / 1024);
        UI.snackOpen('too large file size (Max 1mb) your file size is ' + size + 'mb', 'error');
        return;
      }
      if (!allowTypes.includes(pp.type)) {
        UI.snackOpen('file type is not allwed (' + pp.type + ')', 'error');
        return;
      }
      const file = new File(pp);
      await this.storageController.uploadLogo(file);
      UI.snackOpen(__('Success'));
      this.avatar = false;
      await this.Session.reloadTestCenter();
      this.testCenter = this.controller.testCenter;
      this.avatar = true;
    },
    async resetLogo() {
      await this.storageController.resetLogo();
      this.avatar = false;
      await this.Session.reloadTestCenter();
      this.testCenter = this.controller.testCenter;
      this.avatar = true;
    },
  },
});
