import { hidden, notMapped } from '@/controller/reflections';
import { Role } from '@/enums';
import Branch from './branch';
import File from './file';
import { ICU } from './imodels';
import User from './user';

export default class BranchPermission extends ICU {
  public name: string = '';
  public user: User = new User();
  public role: Role = Role.User;
  public branches: Branch[] = [];
  public photo?: File | null;
}
