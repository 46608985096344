import { repoName } from '@/controller/reflections'
import { Holiday} from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('HolidayRepository')
export default class HolidayRepository extends Repository<Holiday> {
  constructor() {
    super('holidays')
  }
  public convertType(value: object): Holiday {
    return Object.assign(new Holiday(), value)
  }
}
