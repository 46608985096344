






















import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    badge: {
      type: Object as PropType<{ color: String; content: String }>,
      default: undefined,
    },
  },
  data: () => ({
    alternateIcon: 'mdi-checkbox-blank-circle-outline',
  }),
});
