







import Vue from 'vue';
import ProductTable from './components/product-table.vue';

export default Vue.extend({
  name: 'Products',

  components: {
    ProductTable,
  },

  mounted() {},
});
