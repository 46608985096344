
































































































import Vue from 'vue'

import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import Session from '@/controller/session'
import Cache from '@/controller/cache'
import { User } from '@/models'
import UI from '@/controller/ui'

export default Vue.extend({
  name: 'AccountSettingsSecurity',

  components: {},
  data: () => ({
    tab: '',
    icons: {
      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    },
    isCurrentPasswordVisible: false,
    isNewPasswordVisible: false,
    isCPasswordVisible: false,
    currentPassword: '',
    newPassword: '',
    cPassword: '',
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    pw1: [
      (v: any) => !!v || 'Dieses Feld ist erforderlich',
      (v: any) => v.length >= 8 || 'this field is must be at least 8 characters',
    ],
    pw2: [
      (v: any) => !!v || 'Dieses Feld ist erforderlich',
      (v: any) => v.length >= 8 || 'this field is must be at least 8 characters',
    ],
    validation: false,
    loading: false,
  }),
  mounted() {},
  methods: {
    save() {
      this.loading = true
      ;(this.$refs.changePasword as any).validate()
      if (this.newPassword != this.cPassword) {
        UI.snackOpen('The new password does not match the confirmation password.', 'error')
        this.validation = false
        return
      }
      if (!this.validation) {
        UI.snackOpen('Please Valid Pw Change Form', 'error')
        console.warn('Please Valid Pw Change Form')
        this.loading = false
        return
      }

      let sessionController = new Session()
      let me = Cache.User
      me.password = this.newPassword
      sessionController.uow.Users.update(me, false).then((x: User) => {
        if (x != null) {
          Cache.User = x
          this.UI.snackOpen('Success! ')
          this.clearform()
        }
        this.loading = false
      })
    },
    clearform() {
      this.currentPassword = ''
      this.newPassword = ''
      this.cPassword = ''
    },
  },
})
