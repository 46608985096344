













































































import Vue from 'vue';

import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js';
import Session from '@/controller/session';
import Cache from '@/controller/cache';
import { File, User } from '@/models';
import StorageController from '@/controller/storage';
import UI from '@/controller/ui';
import _ from 'lodash';

export default Vue.extend({
  name: 'AccountSettingsSecurity',
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    status: ['Active', 'Inactive', 'Pending', 'Closed'],
    accountDataLocale: <any>{},
    icons: {
      mdiAlertOutline,
      mdiCloudUploadOutline,
    },
    storageController: new StorageController(),
    avatar: true,
  }),
  mounted() {
    this.resetForm();
  },
  methods: {
    resetForm() {
      this.accountDataLocale = JSON.parse(JSON.stringify(this.$props.accountData));
    },
    save() {
      let sessionController = new Session();
      let me = Cache.User;
      me.first_name = this.accountDataLocale.firstname;
      me.last_name = this.accountDataLocale.LastName;
      (me.password as any) = undefined;
      me = me.package();
      me = _.omit(me, 'role') as any;
      sessionController.uow.Users.update(me, false).then((x: User) => {
        if (x != null) {
          Cache.User = x;
          this.Session.reload();
          this.UI.snackOpen('Success! ');
        }
      });
    },
    async upload(e: Event) {
      const allowTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const pp = (e.target as any).files[0];
      if (pp.size > 1024 * 1024 * 1.1) {
        let size = Math.round(pp.size / 1024 / 1024);
        UI.snackOpen('too large file size (Max 1mb) your file size is ' + size + 'mb', 'error');
        return;
      }
      if (!allowTypes.includes(pp.type)) {
        UI.snackOpen('file type is not allwed (' + pp.type + ')', 'error');
        return;
      }
      const file = new File(pp);
      await this.storageController.uploadProfile(file);
      UI.snackOpen(__('Success'));
      this.avatar = false;
      await this.Session.reload();
      this.accountDataLocale.avatarImg = Cache.User.photo;
      this.$root.$eventBus.$emit('refreshPP');
      this.avatar = true;
    },
    async resetProfile() {
      await this.storageController.resetProfile();
      this.avatar = false;
      await this.Session.reload();
      this.accountDataLocale.avatarImg = null;
      this.$root.$eventBus.$emit('refreshPP');
      this.avatar = true;
    },
  },
});
