


















































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { User } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiDelete, mdiPencilOutline, mdiEmailOutline,mdiKeyOutline } from '@mdi/js';
import EmployeeDialog from './employee-dialog.vue';
import UserController from '@/controller/user';
import EmailController from '@/controller/email';

export default Vue.extend({
  components: {
    Editable,
    EmployeeDialog,
  },
  data() {
    return {
      title: 'Mitarbeiter',
      show: false,
      edit: false,
      selected: new User(),
      controller: new UserController(),
      emailController: new EmailController(),
      items: <User[]>[],
      icons: {
        mdiEmailOutline,
        mdiDelete,
        mdiPencilOutline,mdiKeyOutline
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(User);
      headers.splice(headers.length, 0, {
        text: 'Einladung',
        value: 'invitation',
      });
      headers.splice(headers.length, 0, {
        text: 'Passwort vergessen',
        value: 'forgot',
      });
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init().then((users: User[]) => {
        this.items = users;
      });
    },
    clearform() {
      this.selected = new User();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    // deleting(item: any) {

    //   if (item.default) {
    //     UI.snackOpen('Default cannot delete.', 'warning')
    //     return false
    //   }
    //   if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
    //     // this.selected = item
    //     this.controller.deleteUser(item).then(() => {
    //       this.refresh()
    //     })
    //   }
    // },
    create() {
      this.selected = new User();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
    async sendInvitation(user: User) {
      if (confirm('Sind Sie sicher, dass Sie senden möchten')) {
        this.emailController.sendInvitation(user.uid,false).then(() => {
          UI.snackOpen('Invitation sended');
        });
      }
    },
    async sendForgot(user: User) {
      if (confirm('Sind Sie sicher, dass Sie senden möchten')) {
        this.emailController.sendForgotPassword(user.email).then(() => {
          UI.snackOpen('Passwort zurücksetzen gesendet');
        });
      }
    },
  },
});
