var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"max-width":!_vm.fullscreen ? 1440 : null,"content-class":"dialog-min-width","scrollable":"","hide-overlay":_vm.fullscreen,"transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('Editable',{attrs:{"headers":_vm.headers,"editable":false,"deletable":false,"creatable":false,"searchable":false,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.dateMergerTime(item.start_date, item.start_time))+" ")]}},{key:"end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.dateMergerTime(item.end_date, item.end_time))+" ")]}},{key:"result",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.result]}},[_vm._v(" "+_vm._s(_vm._f("trans")(('TestResult.' + item.result)))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","color":"info","title":"Termine"},on:{"click":function($event){return _vm.createNew(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}])})],1),_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm._f("trans")('Controls.Cancel'))+" ")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.controller.loading),callback:function ($$v) {_vm.$set(_vm.controller, "loading", $$v)},expression:"controller.loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Bitte bleiben Sie dabei "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }