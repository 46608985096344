import { repoName } from '@/controller/reflections'
import { Product } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('ProductRepository')
export default class ProductRepository extends Repository<Product> {
  constructor() {
    super('products')
  }
  public convertType(value: object): Product {
    return Object.assign(new Product(), value)
  }
}
