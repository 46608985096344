







































































































































































import BranchController from '@/controller/branch';
import ThemeSwitcher from '@/layouts/components/theme-switcher.vue';
import { Branch } from '@/models';
import StringUtils from '@/utils/string-utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'Impressum',
  components: {
    ThemeSwitcher,
  },
  data: () => ({
    page: {
      logo: null,
      title: null,
      hello_message: null,
      id: null,
    },
    branch: new Branch(),
    controller: new BranchController(),
  }),
  mounted() {
    let branch_url = this.$route.params.id;

    if (StringUtils.isEmpty(branch_url)) {
      this.$router.push('/error-404');
    }
    this.controller.getBranchFromURL(branch_url).then(branch => {
      if (branch == null) {
        this.$router.push('/error-404');
        return;
      }
      this.branch = branch;
      this.page = {
        logo: branch.test_center.logo?.url,
        title: branch.company_name,
        hello_message: branch.note,
        id: branch.url,
      } as any;
    });
  },
  methods: {},
});
