





































































import Vue from 'vue';
import Editor from '@tinymce/tinymce-vue';
import { mdiContentSave, mdiSync, mdiCloudUploadOutline } from '@mdi/js';
import PDFController from '@/controller/pdf';
import { File, PDFDesign, Setting } from '@/models';
import UI from '@/controller/ui';
import { PDFDesingType, PinMode } from '@/enums';
import Cache from '@/controller/cache';
import StorageController from '@/controller/storage';

export default Vue.extend({
  name: 'PDFDesigner',
  components: {
    Editor,
  },
  data: () => ({
    PinMode,
    icons: {
      mdiContentSave,
      mdiSync,
      mdiCloudUploadOutline,
    },
    editorConfig: {
      plugins: ['save', 'autolink lists', 'image', 'preview table link code'],
      save_onsavecallback: function () {
        console.log('Saved');
      },
      height: 800,
      toolbar: [
        'formatselect | fontselect | removeformat | checklist | casechange | link ',
        'bold italic strikethrough forecolor backcolor formatpainter | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent ',
      ],
      content_style: `p {
  margin: 5px 0 0 0;
}`,
    },
    controller: new PDFController(),
    storageController: new StorageController(),
    design: new PDFDesign(),
    signature_img: new File(),
  }),
  async mounted() {
    const pdfDesing = await this.controller.getPDFDesign(PDFDesingType.Result);
    this.signature_img = Cache.testCenter.setting.signature_img as any;
    if (!pdfDesing) {
      this.design.template = await this.controller.getDefaultPDFDesign(PDFDesingType.Result);
      this.design = await this.controller.pdfDesignInsert(this.design);
    } else {
      this.design = pdfDesing;
    }
  },
  methods: {
    async save() {
      await this.controller.pdfDesignUpdate(this.design);
      UI.snackOpen(__('Success'));
    },
    async reset() {
      if (confirm('Möchten Sie wirklich zurücksetzen?')) {
        this.design.template = await this.controller.getDefaultPDFDesign(PDFDesingType.Result);
        await this.save();
      }
    },
    async upload(e: Event) {
      const allowTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const pp = (e.target as any).files[0];
      if (pp.size > 1024 * 1024 * 1.1) {
        let size = Math.round(pp.size / 1024 / 1024);
        UI.snackOpen('too large file size (Max 1mb) your file size is ' + size + 'mb', 'error');
        return;
      }
      if (!allowTypes.includes(pp.type)) {
        UI.snackOpen('file type is not allwed (' + pp.type + ')', 'error');
        return;
      }
      const file = new File(pp);
      const new_img = await this.storageController.uploadPDFSignature(file, Cache.testCenter.setting.id);
      UI.snackOpen(__('Success'));
      await this.Session.reloadTestCenter();
      location.reload();
    },
    async resetLogo() {
      await this.storageController.resetPDFSignature();
      this.signature_img = null as any;
    },
  },
});
