































import Vue from 'vue'

import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
// demos
import AccountSettingsAccount from './components/account-settings-account.vue'
import AccountSettingsSecurity from './components/account-settings-security.vue'
import AccountSettingsInfo from './components/account-settings-info.vue'
import Cache from '@/controller/cache'

export default Vue.extend({
  name: 'Profile',

  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
  },
  data: () => ({
    tab: '',
    tabs: [
      { title: 'Konto', icon: mdiAccountOutline },
      { title: 'Sicherheit', icon: mdiLockOpenOutline },
      { title: 'Info', icon: mdiInformationOutline },
    ],
    accountSettingData: {
      account: {
        avatarImg: Cache.User.photo,
        username: Cache.User.username,
        firstname: Cache.User.first_name,
        LastName: Cache.User.last_name,
        email: Cache.User.email,
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    },
  }),
  mounted() {
    this.Session.reload()
    this.accountSettingData.account = {
      avatarImg: Cache.User.photo,
      username: Cache.User.username,
      firstname: Cache.User.first_name,
      LastName: Cache.User.last_name,
      email: Cache.User.email,
      role: 'Admin',
      status: 'Active',
      company: 'Google.inc',
    }
  },
})
