var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"editable":false,"deletable":false,"headers":_vm.headers,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},on:{"update":_vm.update,"delete":_vm.deleting,"create":_vm.create,"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trans")(item.default.toString())))]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-block mw"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"default",fn:function(ref){
var item = ref.item;
return [(_vm.getActive(item))?_c('v-switch',{staticClass:"ma-0",attrs:{"color":"primary","hide-details":""},on:{"click":function($event){return _vm.setDefault(item)}},model:{value:(item.default),callback:function ($$v) {_vm.$set(item, "default", $$v)},expression:"item.default"}}):_vm._e()]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0",attrs:{"input-value":_vm.getActive(item),"color":"primary","hide-details":""},on:{"change":function($event){return _vm.setActive($event, item)}}})]}},{key:"dcc",fn:function(ref){
var item = ref.item;
return [(item.dcc)?_c('v-badge',{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","content":"DCC"}}):_c('v-badge',{staticClass:"ms-4",attrs:{"bottom":"","color":"info","overlap":"","content":"Manuell"}})]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [(!_vm._.isEmpty(item.test_center))?_c('v-btn',{attrs:{"color":"light-blue","elevation":"2","small":"","fab":"","dark":""},on:{"click":function($event){return _vm.update(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1):_vm._e()]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [(!_vm._.isEmpty(item.test_center))?_c('v-btn',{attrs:{"color":"error","elevation":"2","small":"","fab":"","dark":""},on:{"click":function($event){return _vm.deleting(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDelete))])],1):_vm._e()]}}])}),(_vm.show)?_c('TestkitsDialog',{attrs:{"title":_vm.title,"fullscreen":false,"show":_vm.show},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }