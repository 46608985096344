

































































































import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';

import { mdiShieldKeyOutline } from '@mdi/js';
import ThemeSwitcher from '@/layouts/components/theme-switcher.vue';
import StringUtils from '@/utils/string-utils';
import { Appointment, Branch, Customer } from '@/models';
import _ from 'lodash';
import BookingController from '@/controller/booking';
import UI from '@/controller/ui';
import DownloadController from '@/controller/download';
import moment from 'moment';
import Configs from '@/config/config';
import { AppointmentStatus } from '@/enums';

export default Vue.extend({
  name: 'Booking',
  components: {
    ThemeSwitcher,
    QrcodeVue,
  },
  data: () => ({
    timeouted: false,
    icons: { mdiShieldKeyOutline },
    branch: new Branch(),
    appointment: new Appointment(),
    qrCode: '',
    requiredrules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    validation: false,
    controller: new BookingController(),
    pin: '',
    downloaded: false,
    qr_code: '',
    last_error: null,
  }),
  mounted() {
    this.qr_code = this.$route.params.qr_code;

    if (StringUtils.isEmpty(this.qr_code)) {
      this.$router.push('/error-404');
    }

    this.controller.getAppointmentByQRCode(this.qr_code).then(appointment => {
      if (appointment == null) {
        this.$router.push('/error-404');
        return;
      }
      this.appointment = appointment;
      this.branch = appointment.branch;
      const diff = moment(appointment.end_date).diff(moment(), 'days');
      this.timeouted = Math.abs(diff) >= 3;
    });
  },
  methods: {
    download() {
      (this.$refs.door as any).validate();

      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }
      switch (this.appointment.status) {
        case AppointmentStatus.Waiting:
          UI.snackOpen('Your test has not started yet.', 'info');
          return;
        case AppointmentStatus.Finished:
          break;
        case AppointmentStatus.Started:
          UI.snackOpen('Your test has started.', 'info');
          return;
        case AppointmentStatus.WaitingStart:
          UI.snackOpen('Your test is waiting to start.', 'info');
          return;
        case AppointmentStatus.Cancel:
          UI.snackOpen('Your test has been cancelled.', 'info');
          return;
        default:
          break;
      }

      this.controller
        .verify(this.qr_code, this.pin)
        .then(x => {
          let url = x.url ?? '';
          let name = x.name ?? '';
          if (StringUtils.isEmpty(url)) {
            this.$router.push('/error-404');
            return;
          }
          location.href = url;
          // DownloadController.downloadURL(url, name, false);
          this.downloaded = true;
        })
        .catch(err => {
          console.error(err.message);
          this.last_error = err.message;
          UI.snackOpen(err.message, 'error');
        });
    },
  },
});
