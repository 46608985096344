































































import Vue from 'vue';
import { mdiMagnify, mdiBellOutline, mdiRefresh } from '@mdi/js';
import VerticalNavMenu from './components/vertical-nav-menu/side-menu.vue';
import ThemeSwitcher from './components/theme-switcher.vue';
import AppBarUserMenu from './components/app-bar-user-menu.vue';
import moment from 'moment';

export default Vue.extend({
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  data: () => ({
    moment,
    isDrawerOpen: true,
    // Icons
    icons: {
      mdiMagnify,
      mdiBellOutline,
      mdiRefresh,
    },
  }),
  mounted() {
    // this.isDrawerOpen = this.$vuetify.breakpoint.mobile ? true : true
  },
  beforeMount() {
    this.isDrawerOpen = this.$vuetify.breakpoint.mobile ? false : true;
  },
});
