import { display, hidden, notMapped } from '@/controller/reflections';
import Appointment from './appointment';
import Branch from './branch';
import File from './file';
import { ICU } from './imodels';

export default class Customer extends ICU {
  @notMapped()
  public branch: Branch = <Branch>new ICU();
  @notMapped()
  public appointments: Appointment[] = <Appointment[]>[];
  @display('Model.FirstName', true)
  public first_name: string = '';
  @display('Model.LastName', true)
  public last_name: string = '';
  @display('Model.Street', true)
  public street: string = '';
  @display('Model.Postalcode', true)
  public post_code: string = '';
  @display('Model.District', true)
  public district: string = '';
  @display('Model.Email', true)
  public email: string = '';
  @display('Model.BirthDate', true)
  public birth_date: Date = new Date();
  @display('Model.IdentityNumber', true)
  public identity_number: string = '';
  @hidden()
  @notMapped()
  public customer_card_pdf?: File;
  @hidden()
  @notMapped(true)
  public resetPasswordToken?: string = '';
  @hidden()
  @notMapped(true)
  public role?: string = '';
  @display('Model.Phone', true)
  public phone: string = '';
  @hidden()
  @notMapped(true)
  public uid: string = '';
}
