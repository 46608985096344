import { repoName } from '@/controller/reflections'
import { Customer } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('CustomerRepository')
export default class CustomerRepository extends Repository<Customer> {
  constructor() {
    super('customers')
  }
  public convertType(value: object): Customer {
    return Object.assign(new Customer(), value)
  }
}
