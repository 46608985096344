export enum Role {
  User     = 'User',
  Admin    = 'Admin',
  AppAdmin = 'AppAdmin',
  Customer = 'Customer',
}

export enum FilterType {
  $eq           = '$eq',             //	Equal
  $ne           = '$ne',             //	Not equal
  $lt           = '$lt',             //	Less than
  $lte          = '$lte',            //	Less than or equal to
  $gt           = '$gt',             //	Greater than
  $gte          = '$gte',            //	Greater than or equal to
  $in           = '$in',             //	Included in an array
  $notIn        = '$notIn',          //	Not included in an array
  $contains     = '$contains',       //	Contains (case-sensitive)
  $notContains  = '$notContains',    //	Does not contain (case-sensitive)
  $containsi    = '$containsi',      //	Contains
  $notContainsi = '$notContainsi',   //	Does not contain
  $null         = '$null',           //	Is null
  $notNull      = '$notNull',        //	Is not null
  $between      = '$between',        //	Is between
  $startsWith   = '$startsWith',     //	Starts with
  $endsWith     = '$endsWith',       //	Ends with
  $or           = '$or',             //	Joins the filters in an "or" expression
  $and          = '$and',            //	Joins the filters in an "and" expression
}

export enum AppointmentStatus {
  Finished     = 'Finished',
  Waiting      = 'Waiting',
  Started      = 'Started',
  WaitingStart = 'WaitingStart',
  Cancel       = 'Cancel',
}

export enum AppointmentResult {
  Negative = 'Negative',
  Positive = 'Positive',
  NotClear = 'NotClear',
  Waiting  = 'Waiting',
}

export enum OrderBy {
  asc  = ':asc',
  desc = ':desc',
  none = '',
}

export enum DashboardFilterType {
  All       = 0,
  Positive  = 1,
  NotClear  = 2,
  Negative  = 3,
  Continues = 4,
  Done      = 5,
  Checkin   = 6,
  Free      = 7,
  Paid      = 8,
  SelfPaid  = 9,
}

export enum AccountType {
  Demo    = 'Demo',
  Premium = 'Premium',
  Pro     = 'Pro',
  Vip     = 'Vip',
}

export enum FieldType {
  Text   = 'Text',
  Number = 'Number',
  Select = 'Select',
  Date   = 'Date',
}

export enum FieldStatus {
  Active    = 'Active',
  OnlyAdmin = 'OnlyAdmin',
  Deactive  = 'Deactive',
}

export enum PinMode {
  Digit6   = 'Digit6',
  Birthday = 'Birthday',
}

export enum ImportStatus {
  Success    = 'Success',
  Error      = 'Error',
  Processing = 'Processing',
}

export enum PublicationStatus {
  Published   = 'live',
  Unpublished = 'preview',
}

export enum WebSocketEvent {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export enum PDFDesingType {
  Result  = 'Result',
  Invoice = 'Invoice',
}

export enum InvoceStatus {
  Waiting = 'Waiting',
  Paid    = 'Paid',
  Cancel  = 'Cancel',
}

export enum PaymentMethodType {
  None   = 'None',
  Spot   = 'Spot',
  Cash   = 'Cash',
  POS    = 'POS',
  Paypal = 'Paypal',
}

export enum RequiredPdfMode {
  None    = 'None',
  Default = 'Default',
  Custome = 'Custome',
}

export enum PaymentFreeStatus {
  None     = 'None',
  Free     = 'Free',
  Paid     = 'Paid',
  SelfPaid = 'SelfPaid',
}
