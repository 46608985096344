






import Vue from 'vue';
import InvoiceTable from './components/invoice-table.vue';

export default Vue.extend({
  name: 'InvoicesPage',

  components: {
    InvoiceTable,
  },
  data: () => ({}),
  mounted() {},
});
