import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// Translation provided by Vuetify (typescript)
import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'
import preset from '@/plugins/materio/default-preset/preset';
import i18n from './translation'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { en, de },
    current: i18n.locale
    // t: (key, ...params) => i18n.t(key).toString(),
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
