var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{ref:"datetime_picker",attrs:{"close-on-content-click":false,"nudge-top":10,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-text-field-simplemask',{attrs:{"label":_vm.label,"properties":{
          prefix: '',
          suffix: '',
          readonly: false,
          disabled: _vm.disabled,
          outlined: true,
          clearable: true,
          hint: _vm.Configs.dateFormat + ' format',
          placeholder: '',
          rules: _vm.rules,
          'prepend-icon': _vm.icons.mdiCalendar,
        },"options":{
          inputMask: '##.##.####',
          outputMask: '##.##.####',
          empty: null,
          applyAfter: false,
          alphanumeric: true,
          lowerCase: false,
        }},on:{"click:append":function($event){_vm.date_text = ''},"click":function($event){_vm.menu = true}},model:{value:(_vm.date_text),callback:function ($$v) {_vm.date_text=$$v},expression:"date_text"}})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm._f("trans")('Controls.Cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }