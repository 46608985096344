








import Vue from 'vue'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import Cache from '@/controller/cache'
import StringUtils from '@/utils/string-utils'

export default Vue.extend({
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiWeatherNight,
      mdiWeatherSunny,
    },
    dark: false,
  }),
  mounted() {
    let theme = Cache.getCookie('dark') || 'false'
    if (StringUtils.isEmpty(theme)) {
      Cache.setCookie('dark', this.$vuetify.theme.dark ? 'true' : 'false', 10)
    } else {
      this.dark = theme == 'true'
    }
    this.$vuetify.theme.dark = this.dark
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      Cache.setCookie('dark', this.$vuetify.theme.dark ? 'true' : 'false', 10)
    },
  },
})
