import { Factory } from '@/config/factory';
import { FilterType, OrderBy, PublicationStatus } from '@/enums';
import Field from '@/uow/field';
import Filter from '@/uow/filter';
import IStrapiParam from '@/uow/istrapi-param';
import LazyLoad from '@/uow/lazy-load';
import Publication from '@/uow/publication';
import Sort from '@/uow/sort';

/**
 *  @description optimized 19.04.2022
 */
export default class FilterHelper {
  private _params: IStrapiParam[] = [];

  public and(field: string, value: any, filterType?: FilterType): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), filterType);
    newFilter.condition_type = FilterType.$and;
    this._params.push(newFilter);
    return this;
  }

  public or(field: string, value: any, filterType?: FilterType): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), filterType);
    newFilter.condition_type = FilterType.$or;
    this._params.push(newFilter);
    return this;
  }

  public gte(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$gte);
    this._params.push(newFilter);
    return this;
  }

  public lte(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$lte);
    this._params.push(newFilter);
    return this;
  }

  public gt(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$gt);
    this._params.push(newFilter);
    return this;
  }

  public lt(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$lt);
    this._params.push(newFilter);
    return this;
  }

  public in(field: string, values: any[], filterType?: FilterType): this {
    values.forEach(value => {
      let newFilter = new Filter(field, value, this.getCount('In'), filterType);
      newFilter.paramType = 'In';
      newFilter.condition_type = FilterType.$in;
      this._params.push(newFilter);
    });
    return this;
  }

  public notIn(field: string, values: any[], filterType?: FilterType): this {
    values.forEach(value => {
      let newFilter = new Filter(field, value, this.getCount('NotIn'), filterType);
      newFilter.paramType = 'NotIn';
      newFilter.condition_type = FilterType.$notIn;
      this._params.push(newFilter);
    });
    return this;
  }

  public equal(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$eq);
    this._params.push(newFilter);
    return this;
  }

  public notEqual(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$ne);
    this._params.push(newFilter);
    return this;
  }

  public contains(field: string, value: any): this {
    let newFilter = new Filter(field, value, this.getCount('Filter'), FilterType.$containsi);
    this._params.push(newFilter);
    return this;
  }

  public orContains(field: string, value: any): this {
    return this.or(field, value, FilterType.$containsi);
  }

  public sort(field: string, orderBy?: OrderBy): this {
    let newFilter = new Sort(field, this.getCount('Sort'), orderBy);
    this._params.push(newFilter);
    return this;
  }

  public lazyLoad(name?: string, value?: string, deep: boolean = false, field: boolean = false): this {
    let newLazyFilter = new LazyLoad(name, value, deep, field, this.getCount('LazyLoad'));
    this._params.push(newLazyFilter);
    return this;
  }

  public publication(status?: PublicationStatus): this {
    let newPublicationFilter = new Publication(status);
    newPublicationFilter.index = this.getCount('Publication');
    this._params.push(newPublicationFilter);
    return this;
  }

  public field(name?: string): this {
    let newFieldFilter = new Field(name, this.getCount('Field'));
    this._params.push(newFieldFilter);
    return this;
  }

  public parse() {
    let filterString = this._params
      .map(x => {
        return x.url();
      })
      .join('&');
    return `?${filterString}`;
  }

  private getCount(type: string) {
    return this._params.filter(x => x.paramType == type).length;
  }
}
