import Configs from '@/config/config';
import API from '@/controller/api';
import { sanitize } from '@/controller/reflections';
import UI from '@/controller/ui';
import FilterHelper from '@/helpers/filter-helper';
import { ICU, IModel } from '@/models/imodels';
import _, { update } from 'lodash';
import Strapi from 'strapi-sdk-javascript';
import GQL from './gql';
import IRepository from './irepository';

export default abstract class Repository<T extends IModel> implements IRepository<T> {
  entry: string;

  get api(): Strapi {
    return API.strapi;
  }

  constructor(entry: string) {
    this.entry = entry;
  }

  async whereOne(filters: FilterHelper): Promise<T | null> {
    const result = this.where(filters, false).then((x: T[]) => {
      let first = this.denormalize(x, true);
      first = first?.pop();
      if (!first) {
        console.warn('Data Not Found');
        return null;
      }
      return first as T;
    });
    return result;
  }

  async where(filters: FilterHelper, isDenormalize: boolean = true): Promise<T[]> {
    let url_param = filters.parse();
    const result = await this.api
      .getEntries(this.entry + url_param)
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
      });
    return isDenormalize ? this.denormalize(result, true) : result;
  }

  async getEntry(id: number): Promise<T> {
    const result = await this.api
      .getEntry(this.entry, id.toString() + '?populate=*')
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
      });

    return this.denormalize(result, true);
  }

  async select(normalize = true): Promise<T[]> {
    const result = await this.api
      .getEntries(this.entry)
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
      });

    if (normalize) {
      return this.denormalize(result);
    } else return result;
  }

  async select_all(): Promise<T[]> {
    const result = await this.api
      .getEntries(this.entry + '?populate=*')
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
      });

    return this.denormalize(result, true);
  }

  async insert(data: T, normalize = true): Promise<T> {
    let _data = data;
    if (normalize) {
      _data = this.normalize(_data) as any;
    }
    const result = await this.api.createEntry(this.entry, _data).then(x => x as any);
    if (normalize && result) {
      return this.denormalize(result);
    } else return result;
  }

  async update(data: T, normalize = true): Promise<T> {
    let _data = data;
    if (normalize) {
      _data = this.normalize(_data) as any;
    }
    const result = await this.api
      .updateEntry(this.entry, data.id.toString(), _data)
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
      });

    if (normalize) {
      return this.denormalize(result);
    } else return result;
  }

  async delete(id: number): Promise<boolean> {
    const result = await this.api
      .deleteEntry(this.entry, id.toString())
      .then(_ => true)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
        return false;
      });

    return result;
  }
  async unpublish(id: number) {
    return await this.update({ id, publishedAt : null } as any);
  }

  async graphQL(gql: GQL): Promise<T[]> {
    const result = await this.api
      .request('post', Configs.graphQL_url, {
        data: sanitize(gql),
      })
      .then(x => x as any)
      .catch(error_result => {
        UI.snackOpen(__('Error')+'  : ' + error_result.error, 'error');
        return false;
      });

    return this.denormalizeGQL(result, gql.root, true);
  }

  normalize(base_data: any) {
    // if (_.isArray(base_data)) {
    //   base_data.forEach(x => (x = this.normalize(x)))
    // } else {
    //   for (const key in base_data) {
    //     if (Object.prototype.hasOwnProperty.call(base_data, key)) {
    //       if (_.isObject(base_data[key])) {
    //         base_data[key] = this.normalize(base_data[key])
    //       }
    //     }
    //   }
    // }
    // return { id: base_data.id, data: _.omit(base_data, 'id') }
    return { data: base_data };
  }

  denormalizeGQL(base_data: any, root?: string, deep_denomalize = false, convert = true) {
    if (root) {
      return this.denormalize(base_data.data[root], deep_denomalize, convert);
    } else {
      return this.denormalize(base_data, deep_denomalize, convert);
    }
  }

  public denormalize(base_data: any, deep_denomalize = false, convert = true) {
    if (_.isEmpty(base_data.data)) {
      return null;
    } else if (_.isArray(base_data.data)) {
      if (deep_denomalize) {
        return base_data.data.map((x: any) => {
          for (const key in x.attributes) {
            if (Object.prototype.hasOwnProperty.call(x.attributes, key)) {
              if (_.isObject(x.attributes[key]) && Object.prototype.hasOwnProperty.call(x.attributes[key], 'data')) {
                x.attributes[key] = this.denormalize(x.attributes[key], true, false);
              }
            }
          }
          let data = { id: Number(x.id), ...x.attributes };
          return convert ? this.convertType(data) : this.convertICU(data);
        });
      } else {
        return base_data.data.map((x: any) => {
          let data = { id: Number(x.id), ...x.attributes };
          return convert ? this.convertType(data) : this.convertICU(data);
        });
      }
    } else {
      let data = { id: Number(base_data.data.id), ...base_data.data.attributes };
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (_.isObject(data[key]) && Object.prototype.hasOwnProperty.call(data[key], 'data')) {
            data[key] = this.denormalize(data[key], true, false);
          }
        }
      }
      return convert ? this.convertType(data) : this.convertICU(data);
    }
  }

  convertICU(value: object): ICU {
    return Object.assign(new ICU(), value);
  }

  public abstract convertType(value: object): T;
}
