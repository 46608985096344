import Vue from 'vue'
import VueI18n, { I18nOptions } from 'vue-i18n'

// #region for language
Vue.use(VueI18n)

Vue.filter('trans', (str: string) => {
  return i18n.t(str)
})

Vue.mixin({
  methods: {
    __ (str: string) {
      return i18n.t(str)
    }
  }
})
// #endregion

const i18n = new VueI18n({
  locale: 'de',
  messages: {
    // en: require('@/assets/lang/en.json'),
    de: require('@/assets/lang/de.json')
  },
  silentTranslationWarn: true
})

globalThis.__ = (
  key: VueI18n.Path,
  choice?: VueI18n.Choice,
  values?: VueI18n.Values
) => i18n.tc(key, choice, values)

export default i18n
