import Configs from '@/config/config';
import { AppointmentResult, AppointmentStatus, DashboardFilterType, FilterType, PaymentFreeStatus } from '@/enums';
import FilterHelper from '@/helpers/filter-helper';
import GraphQLHelper from '@/helpers/graphQL-helper';
import { Appointment, Customer, File } from '@/models';
import { ICU } from '@/models/imodels';
import Env from '@/plugins/env';
import GQL from '@/uow/gql';
import Crypto from '@/utils/crypto';
import _ from 'lodash';
import moment from 'moment';
import API from './api';
import BaseController from './base';
import Cache from './cache';
import PDFController from './pdf';
import { sanitize } from './reflections';

/**
 *  @description optimized 19.04.2022
 */
export default class DashboardController extends BaseController {
  public static counts: number = 0;
  public static positive: number = 0;
  public static negative: number = 0;
  public static notclear: number = 0;
  public static checkin: number = 0;
  public static done: number = 0;
  public static continues: number = 0;
  public static free: number = 0;
  public static paid: number = 0;
  public static selfpaid: number = 0;

  private static _filterType: DashboardFilterType = DashboardFilterType.All;

  public static get filterType(): DashboardFilterType {
    return this._filterType;
  }

  public static set filterType(v: DashboardFilterType) {
    this._filterType = v;
    DashboardController.onFilterChangeHandler();
  }

  constructor() {
    super();
  }

  public async init() {
    this.loading = true;
    return this.getAppointmentsFromDate();
  }

  public async getAppointmentsCustomer(start_date?: Date) {
    this.loading = true;

    return await API.request('POST', '/app/appointment/customer', {
      data: {
        start_date,
      },
    })
      .then(x => {
        return this.uow.Appointments.denormalize(x, true, true);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public async getAppointmentsFromDate(start_date?: Date) {
    //? CheckIt : is we need?
    // if (_.isEmpty(this.branches)) {
    //   await Session.reloadBranches();
    // }

    let gql = GraphQLHelper.getAppointmentsRun(
      this.branches.filter(x => x.status).map(x => x.id), // ids [1,2,3,4,...]
      start_date,
    );
    return this.getGqlData(gql);
  }

  public async getAppointmentsFromBranchesDate(ids: number[], start_date?: Date) {
    if (_.isEmpty(ids)) {
      return { target: Cache.User.target, count: 0, appointments: <Appointment[]>[] };
    }
    let gql = GraphQLHelper.getAppointmentsRun(ids, start_date);
    return this.getGqlData(gql);
  }

  private async getGqlData(gql: GQL) {
    this.loading = true;
    return await this.uow.Appointments.graphQL(gql)
      .then(x => {
        this.loading = false;
        x = x || [];
        const count = x.length;
        DashboardController.counts = count;
        DashboardController.positive = x.filter(x => x.result == AppointmentResult.Positive).length;
        DashboardController.negative = x.filter(x => x.result == AppointmentResult.Negative).length;
        DashboardController.notclear = x.filter(x => x.result == AppointmentResult.NotClear).length;
        DashboardController.checkin = x.filter(x => x.status == AppointmentStatus.WaitingStart).length;
        DashboardController.done = x.filter(x => x.status == AppointmentStatus.Finished).length;
        DashboardController.continues = x.filter(x => x.status == AppointmentStatus.Started).length;
        DashboardController.free = x.filter(x => x.payment_free_status == PaymentFreeStatus.Free).length;
        DashboardController.paid = x.filter(x => x.payment_free_status == PaymentFreeStatus.Paid).length;
        DashboardController.selfpaid = x.filter(x => x.payment_free_status == PaymentFreeStatus.SelfPaid).length;
        DashboardController.root.$eventBus.$emit('dashboard.onChangeCounts');
        switch (DashboardController.filterType) {
          case DashboardFilterType.Checkin:
            x = x.filter(x => x.status == AppointmentStatus.WaitingStart);
            break;
          case DashboardFilterType.Positive:
            x = x.filter(x => x.result == AppointmentResult.Positive);
            break;
          case DashboardFilterType.NotClear:
            x = x.filter(x => x.result == AppointmentResult.NotClear);
            break;
          case DashboardFilterType.Negative:
            x = x.filter(x => x.result == AppointmentResult.Negative);
            break;
          case DashboardFilterType.Done:
            x = x.filter(x => x.status == AppointmentStatus.Finished);
            break;
          case DashboardFilterType.Continues:
            x = x.filter(x => x.status == AppointmentStatus.Started);
            break;
          case DashboardFilterType.Free:
            x = x.filter(x => x.payment_free_status == PaymentFreeStatus.Free);
            break;
          case DashboardFilterType.Paid:
            x = x.filter(x => x.payment_free_status == PaymentFreeStatus.Paid);
            break;
          case DashboardFilterType.SelfPaid:
            x = x.filter(x => x.payment_free_status == PaymentFreeStatus.SelfPaid);
            break;
        }
        return { target: Cache.User.target, count: count, appointments: x };
      })
      .catch(x => {
        this.loading = false;
        console.log(x);
        return { target: Cache.User.target, count: 0, appointments: <Appointment[]>[] };
      });
  }

  public async search(_searchText: string) {
    this.loading = true;

    return await this.uow.Appointments.api
      .request('POST', '/app/search', {
        data: {
          search_text: _searchText,
          branch_ids: this.branches.map(x => x.id),
        },
      })
      .then(x => {
        this.loading = false;

        return this.uow.Appointments.denormalize(x, true, true);
      })
      .catch(x => {
        this.loading = false;
        console.log(x);
        return <Appointment[]>[];
      });
  }

  public async searchQR(code: string) {
    this.loading = true;
    let filter = new FilterHelper()
      .equal('qr_code', code)
      .in(
        'branch.id',
        this.branches.map(x => x.id),
      )
      .lazyLoad();
    return await this.uow.Appointments.where(filter)
      .then(x => {
        this.loading = false;
        return x;
      })
      .catch(x => {
        this.loading = false;
        console.log(x);
        return <Appointment[]>[];
      });
  }

  public async insertAppointment(customer: Customer, appointment: Appointment) {
    this.loading = true;
    const insertAppointment = async (_customer: Customer) => {
      appointment.customer = _customer;
      appointment.status = AppointmentStatus.Waiting;
      appointment.result = AppointmentResult.Waiting;
      appointment.qr_code = Crypto.NoSqlId();
      appointment = appointment.package();

      return await this.uow.Appointments.insert(appointment).then(async (_appointment: Appointment) => {
        this.loading = false;
        return _appointment;
      });
    };

    if (customer.id != 0) {
      customer.branch = appointment.branch.compress();
      let customer_packages = customer.package();

      return await this.uow.Customers.update(customer_packages).then(insertAppointment);
    }

    return await this.searchEmail(customer.email)
      .then(async _customer => {
        if (_customer != null) {
          if (
            !confirm(
              'Ein Kunde mit dieser E-Mail existiert bereits. Möchten Sie die Informationen wirklich aktualisieren?',
            )
          ) {
            throw new Error(`Customer ${customer.email} already exists`);
          }
          _customer = sanitize(_customer) as ICU as Customer;

          return await this.uow.Customers.update(_customer).then(async x => {
            return await insertAppointment(x);
          });
        } else {
          customer.branch = appointment.branch.compress();

          return await this.uow.Customers.insert(customer).then(insertAppointment);
        }
      })
      .catch(async err => {
        customer.branch = appointment.branch.compress();

        return await this.uow.Customers.insert(customer).then(insertAppointment);
      });
  }

  public async updateAppointment(customer: Customer, appointment: Appointment) {
    this.loading = true;
    customer = customer.package(true);

    // let pdf = new PDFController();
    // await pdf
    //   .getTemplate('customer_ticket', {
    //     ...customer,
    //     company_name: appointment.branch.company_name,
    //   })
    //   .then(async x => {
    //     if (customer == null) return;
    //     if (customer.customer_card_pdf == null) return;
    //     const file = customer.customer_card_pdf;
    //     await x.createAndUpdate(file).then(x => {
    //       if (customer != null) customer.customer_card_pdf = x.compress();
    //     });
    //   });
    customer = _.omit(customer, ['email']) as any;
    return await this.uow.Customers.update(customer).then(async x => {
      appointment = appointment.package();
      return await this.uow.Appointments.update(appointment).then(async (_appointment: Appointment) => {
        this.loading = false;
        return _appointment;
      });
    });
  }

  public static subscribeCounts(callback: Function): void {
    this.root.$eventBus.$on('dashboard.onChangeCounts', callback);
  }

  public static unsubscribeCounts(callback: Function): void {
    this.root.$eventBus.$off('dashboard.onChangeCounts', callback);
  }

  public static onFilterChangeHandler(): void {
    DashboardController.root.$eventBus.$emit('dashboard.onFilterChange');
  }

  public static subscribeFilter(callback: Function): void {
    this.root.$eventBus.$on('dashboard.onFilterChange', callback);
  }

  public static unsubscribeFilter(callback: Function): void {
    this.root.$eventBus.$off('dashboard.onFilterChange', callback);
  }

  public async getTestKits() {
    return this.testCenter.active_testkits ?? [];
  }

  public static async getPositiveCount() {
    return await API.request('GET', '/app/appointment/positive-results/count');
  }

  public async getPositiveAppointments(ids?: number[], start_date?: string) {
    const branches = ids ?? this.branches.map(x => x.id);
    const date = start_date ?? moment().format(Configs.dateServerFormat);
    this.loading = true;
    let filter = new FilterHelper()
      .and('result', AppointmentResult.Positive)
      .and('end_date', date)
      .and('branch.id', branches, FilterType.$in)
      .lazyLoad('customer')
      .lazyLoad('branch')
      .lazyLoad('result_pdf');
    return await this.uow.Appointments.where(filter).finally(() => {
      this.loading = false;
    });
  }

  public async inform_send(items: Array<Appointment>) {
    const informs = items.map(item => item.id);
    const formData = new FormData();
    informs.forEach(c => formData.append('ids', c.toString()));

    return await API.request('POST', '/app/mail/inform-send', {
      data: formData,
    });
  }

  /**
   * Hastanın gelecekte mevcut randevusu olup olmadığını kontrol eder.
   * Eğer randevu varsa statusCode 400 döner ve "You have an appointment in the future" mesajı
   * @param customer_email
   * @param customer_birthdate
   * @returns
   */
  public static async next(customer_email: string, customer_birthdate: string) {
    const formData = new FormData();
    formData.append('email', customer_email);
    formData.append('birth_date', customer_birthdate);
    return await API.request('POST', '/app/appointment/next', { data: formData });
  }
}
