

















































import Cache from '@/controller/cache';
import Session from '@/controller/session';
import UI from '@/controller/ui';
import { User } from '@/models';
import Vue from 'vue';

export default Vue.extend({
  name: 'AccountSettingsInfo',

  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data: () => ({
    optionsLocal: <any>{},
    validation: false,
    target: 0,
    targetRule: [(v: any) => !!v || 'Dieses Feld ist erforderlich', (v: any) => v >= 0 || 'this field is must be >= 0'],
  }),
  mounted() {
    this.target = Cache.User.target;
  },
  methods: {
    save() {
      (this.$refs.targetForm as any).validate();

      if (!this.validation) {
        UI.snackOpen('Please invalid target', 'error');
        console.warn('Please invalid target');
        return;
      }

      let sessionController = new Session();
      let me = Cache.User;
      me.target = this.target;
      sessionController.uow.Users.update({ id: me.id, target: this.target } as any, false).then((x: User) => {
        if (x != null) {
          Cache.User = x;
          Session.reload()
          this.UI.snackOpen('Success!');
        }
      });
    },
  },
});
