import Configs from '@/config/config';
import { AppointmentResult, PaymentFreeStatus } from '@/enums';
import GraphQLHelper from '@/helpers/graphQL-helper';
import _ from 'lodash';
import moment from 'moment';
import BaseController from './base';

/**
 *  @description optimized 19.04.2022
 */
export default class ExportController extends BaseController {
  constructor() {
    super();
  }

  public async init() {}
  /**
   * appointment export
   * @param start
   * @param end
   * @param result
   * @returns exportable appointment data
   */
  public async export(start: string, end: string, result?: AppointmentResult | undefined) {
    this.loading = true;
    let id = this.testCenter.id;
    let gql = GraphQLHelper.getExportRun(id, start, end, result);
    return await this.uow.Appointments.graphQL(gql)
      .then(x => {
        x = x || [];
        let items = x.map(item => {
          let start = moment(
            item.start_date + ' ' + item.start_time,
            Configs.dateServerFormat + ' ' + Configs.timeServerFormat,
          ).format(Configs.dateTimeFormat);

          let end = moment(
            item.end_date + ' ' + item.end_time,
            Configs.dateServerFormat + ' ' + Configs.timeServerFormat,
          ) as any;

          end = end.isValid() ? end.format(Configs.dateTimeFormat) : '';
          let const_type = '';
          if (item.payment_free_status == PaymentFreeStatus.Free) {
            const_type='Freitestung'
          }
          if (item.payment_free_status == PaymentFreeStatus.Paid) {
            const_type='Bürgertests mit Zuzahlung'
          }
          if (item.payment_free_status == PaymentFreeStatus.SelfPaid) {
            const_type='Selbstzahler'
          }
          return {
            Vorname: item.customer.first_name,
            Nachname: item.customer.last_name,
            Strasse: item.customer.street,
            PLZ: item.customer.post_code,
            Ort: item.customer.district,
            Telefon: item.customer.phone,
            Email: item.customer.email,
            Termine: start,
            Teststart: end,
            Status: item.status,
            Testergebnis: item.result,
            PDFPin: item.pin,
            Standorte: item.branch.company_name,
            Testkit: item.testkit.kit_name,
            Kostenart: const_type,
            KostenubernahmeAuswahl: item.payment_description,
            Betrag: item.price,
          };
        });
        this.loading = false;

        return items;
      })
      .catch(x => {
        this.loading = false;
        console.log(x);
        return [];
      });
  }
}
