



























































































import Vue from 'vue';
import Session from '@/controller/session';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import UI from '@/controller/ui';
import StringUtils from '@/utils/string-utils';
import email from '@/controller/email';
import BranchController from '@/controller/branch';
import { Branch } from '@/models';

export default Vue.extend({
  name: 'Login',
  data: () => ({
    email: '',
    emailRules: [(v: any) => !!v || 'E-mail ist nötig', (v: any) => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein'],
    password: '',
    passwordRules: [
      (v: string) => !!v || 'Passwort wird benötigt',
      (v: string) => v.length > 7 || 'Passwort muss 8-stellig sein',
    ],
    remember: false,
    loading: false,
    validation: false,
    isPasswordVisible: false,
    icons: {
      mdiEyeOutline: mdiEyeOutline,
      mdiEyeOffOutline: mdiEyeOffOutline,
    },
    emailConfirmation: false,
    page: {
      logo: require('@/assets/images/logos/logo.png'),
      title: 'CoTest24',
    },
    customer_mode: false,
    controller: new BranchController(),
    branch: new Branch(),
    customer_data: {} as any,
    branch_url: '',
  }),
  mounted() {
    let jwt = this.$route.query.code as any;
    this.branch_url = this.$route.params.url as string;

    this.customer_mode = !StringUtils.isEmpty(this.branch_url);
    if (this.customer_mode) {
      this.controller.getBranchFromURL(this.branch_url).then(x => {
        if (!x) {
          UI.snackOpen('TestCenter is not available', 'error');
          return;
        }

        this.branch = x;
        this.page.title = x.company_name;
        this.page.logo = UI.getImageURL(x.test_center.logo);
      });
    }

    //* eğer jwt key bilgisi varsa
    if (!StringUtils.isEmpty(jwt)) {
      Session.server_login(jwt).then(isAuth => {
        if (isAuth.result) {
          this.$root.$router.push('/');
        } else {
          UI.snackOpen(isAuth.data.message, 'error');
          console.warn(isAuth.data);
        }
        this.loading = false;
      });
    }

    this.emailConfirmation = !StringUtils.isEmpty(this.$route.query.emailConfirm as any);
  },
  methods: {
    devCustomerLogin() {
      this.email = 'dogus@walnut.ist';
      this.password = '123456789';
      this.login();
    },
    devTestCenterLogin() {
      this.email = 'developer@walnut.ist';
      this.password = '123456789';
      this.login();
    },
    devAdminLogin() {
      this.email = 'admin@walnut.ist';
      this.password = '123456789';
      this.login();
    },
    login() {
      if (!this.Env.isDev) {
        (this.$refs.login as any).validate();
        if (!this.validation) {
          UI.snackOpen('Füllen Sie bitte dieses Formular aus.', 'error');
          console.warn('Füllen Sie bitte dieses Formular aus.');
          return;
        }
      }

      this.loading = true;

      const loginData = { email: this.email, password: this.password } as any;

      if (this.customer_mode) this.customer_data.branch_url = this.branch.url;

      Session.login(loginData, this.remember, this.customer_mode, this.customer_data).then(isAuth => {
        if (isAuth.result) {
          window.location.reload();
          return;
          if (Session.isAdmin()) {
            this.$root.$router.push('/app-customers');
            return;
          }
          this.$root.$router.push('/');
        } else {
          UI.snackOpen(isAuth.data.message, 'error');
          console.warn(isAuth.data);
        }
        this.loading = false;
      });
    },
  },
});
