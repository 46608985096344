import { display, hidden, notMapped } from '@/controller/reflections';
import { AccountType } from '@/enums';
import GraphQLHelper from '@/helpers/graphQL-helper';
import { UnitOfWork } from '@/uow/unit-of-work';
import _ from 'lodash';
import { ICU } from './imodels';
import TestKit from './testkit';
import User from './user';
import File from './file';
import Setting from './setting';
import Invoice from './invoice';

export default class TestCenter extends ICU {
  @display('Model.Logo', true)
  @notMapped()
  public logo?: File;


  @display('Model.Email', true)
  public email: string = '';
  @display('Model.Website', true)
  public website: string = '';
  @display('Model.Phone', true)
  public phone: string = '';
  @display('Model.CompanyName', true)
  public company_name = '';
  @display('Model.Street', true)
  public street = '';
  @display('Model.District', true)
  @hidden()
  public district = '';
  @display('Model.Postalcode', true)
  @hidden()
  public post_code = '';
  @display('Model.Country', true)
  @hidden()
  public country = '';

  @hidden()
  @notMapped()
  public uid = '';

  @hidden()
  @notMapped()
  public testkits: TestKit[] = [];
  @notMapped()
  @hidden()
  public default_testkit: TestKit | null = null;
  @notMapped()
  @hidden()
  public active_testkits: TestKit[] = [];
  @notMapped()
  @hidden()
  public users: User[] = [];
  @notMapped()
  @hidden()
  public setting: Setting = new Setting();
  @hidden()
  public account_type: AccountType = AccountType.Demo;

  @hidden()
  @notMapped(true)
  public invoices: Invoice[] = [];

  public get allTestkit() {
    let gql = GraphQLHelper.getTestkitsRun(this.uid);
    let uow = UnitOfWork.instance;
    // TODO: Root Endpoint
    return uow.Testkits
      .graphQL(gql)
      .then((x: TestKit[]) => {
        return _.orderBy(x, ['test_center.id'], 'asc');
      });
  }
}
