import FilterHelper from '@/helpers/filter-helper';
import { Import } from '@/models';
import _ from 'lodash';
import BaseController from './base';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import moment from 'moment';
import Configs from '@/config/config';
import StringUtils from '@/utils/string-utils';

export default class ImportController extends BaseController {
  constructor() {
    super();
  }

  public async init() {
    let filter = new FilterHelper()
      .in(
        'branch.id',
        this.branches.map(b => b.id),
      )
      .lazyLoad();
    return await this.uow.Imports.where(filter).then(imports => {
      return imports ?? new Array<Import>();
    });
  }

  public async insertImport(_import: Import) {
    this.loading = true;
    return await this.uow.Imports.insert(_import).finally(() => (this.loading = false));
  }

  public async updateImport(_import: Import) {
    return;
  }

  public async deleteImport(_import: Import) {
    this.loading = false;
    return await this.uow.Imports.unpublish(_import.id).finally(() => (this.loading = false));
  }

  public async convertJson(_file: File) {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (data: ProgressEvent<FileReader>) => {
        // const bomstring = StringUtils.convertBom(data.target?.result as string);
        var wb = XLSX.read(data.target?.result, {
          type: 'string',
          raw: true,
          cellText: true,
        });
        var wsn = wb.SheetNames[0];
        const ws = wb.Sheets[wsn];
        console.log(ws);
        const result = await this._convertJson(ws);
        resolve(result);
      };
      reader.readAsText(_file, 'ANSI');
    });
  }
  private async _convertJson(ws: XLSX.Sheet) {
    const json = XLSX.utils.sheet_to_json(ws, {
      defval: null,
      blankrows: false,
    });
    var verify_format = [
      'Vorname',
      'Nachname',
      'Strasse',
      'PLZ',
      'Ort',
      'Telefon',
      'Email',
      'Geburtsdatum_dd.mm.yyyy',
      'AusweisNummer',
    ].sort();
    const formated = json.map((k: any) => _.omitBy(k, (value, key) => !verify_format.includes(key)));
    const promises = _.map(formated, async (v: any, index: number) => {
      let valid = _.isEqual(_.keys(v).sort(), verify_format);
      //#region Verify format
      const schema = yup.object({
        Vorname: yup.string().nullable().required('Required!'),
        Nachname: yup.string().nullable().required('Required!'),
        Ort: yup.string().nullable().required('Required!'),
        Email: yup.string().nullable().email('Invalid Email').required('Required!'),
        'Geburtsdatum_dd.mm.yyyy': yup
          .string()
          .nullable()
          .required('Required!')
          .test((value: any) => {
            const date = moment(value, Configs.dateFormat);
            return date.isValid();
          }),
      });
      let error_message = '';
      await schema
        .validate(v, {
          strict: false,
          abortEarly: false,
        })
        .catch((err: any) => {
          error_message += index + 2 + '. row (';
          error_message += err.inner.map((x: any) => x.message + ' ' + x.path).join(' , ');
          error_message += ')';
          console.error(error_message);
        });
      //#endregion
      valid &&= error_message.length == 0;
      const date = moment(v['Geburtsdatum_dd.mm.yyyy'], Configs.dateFormat);
      if (date.isValid()) v['Geburtsdatum_dd.mm.yyyy'] = date.format(Configs.dateServerFormat);
      return { ...v, valid: valid, error_message: error_message };
    });
    const _result = await Promise.all(promises);
    return [..._result];
  }
}
