


































































































































































import Vue from 'vue'
import { Address, Branch, Group } from '@/models'
import BranchController from '@/controller/branch'
import UI from '@/controller/ui'
import { PropType } from 'vue/types/umd'
import _ from 'lodash'
import { mdiClose } from '@mdi/js'
import Crypto from '@/utils/crypto'

export default Vue.extend({
  name: 'BranchDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Branch>,
      default: () => {
        return new Branch()
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    Group,
    Branch,
    Address,
    icons: {
      mdiClose,
    },
    group: new Group(),
    branch: new Branch(),
    address: new Address(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    controller: new BranchController(),
    validation: false,
    editmode: false,
    groups: <any>[],
  }),
  mounted() {
    if (this.value.id != 0) {
      this.branch = Object.assign(new Branch(), this.value)
      this.group = Object.assign(new Group(), this.value.group)
      this.address = Object.assign(new Address(), this.value.address)

      this.editmode = this.branch.id != 0
    }
    this.controller.groupInit().then(x => {
      this.groups = x.map(group => {
        return { text: group.name, value: group.id }
      })
      if (!this.editmode) {
        this.address.email = this.controller.testCenter?.email ?? ''
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      ;(this.$refs.group as any).validate()
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error')
        console.warn('Formular ist nicht gültig')
        return
      }
      if (this.group.id == 0) {
        this.branch.group = null as any
      } else {
        this.branch.group = this.group
      }
      this.branch.address = this.address
      if (!this.editmode) {
        this.branch.url = Crypto.NoSqlId()
        this.controller
          .insertBranch(this.branch)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'))
            this.$emit('save', x)
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Branch not created : ' + x.message, 'error')
          })
      } else {
        this.controller
          .updateBranch(this.branch)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'))
            this.$emit('save', x)
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Filiale nicht aktualisiert : ' + x.message, 'error')
          })
      }
    },
  },
})
