



































import Cache from '@/controller/cache'
import Vue from 'vue'
export default Vue.extend({
  name: 'Congratulation',
  props: {
    target: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      targetMessage: '',
      targetDescription: '',
    }
  },
  watch: {
    count(n, o) {
      this.messageSet()
    },
    target(n, o) {
      this.messageSet()
    },
  },
  mounted() {
    this.messageSet()
  },
  methods: {
    messageSet() {
      let user = Cache.User
      if (this.target <= this.count) {
        this.targetMessage = `Herzlichen Glückwunsch ${user.first_name} 🥳`
        this.targetDescription = `Sie haben Ihr Ziel erreicht. ${this.target}`
      } else {
        this.targetMessage = `Sie haben Ihr Ziel noch nicht erreicht. 😎`
        this.targetDescription = `Ihr Ziel ist ${this.target}`
      }
    },
  },
})
