import { display, hidden, notMapped } from '@/controller/reflections';

export default class GQL {
  constructor(query: string, operationName: string, variables?: any, root?: string) {
    this.query = query;
    this.variables = variables;
    this.operationName = operationName;
    this.root = root;
  }

  public query: string = '';
  public operationName: string = '';
  @notMapped()
  public root?: string;
  public variables: any = {};
}
