








































import Vue from 'vue';
import {
  mdiCreditCardSettingsOutline,
  mdiCogOutline,
  mdiContentSaveOutline,
  mdiShieldKeyOutline,
  mdiPlayOutline,
} from '@mdi/js';
import CompanySettings from './components/company-settings.vue';
import CompanySettingsAdvenced from './components/company-settings-advanced.vue';
import CompanyInvoiceses from './components/company-invoiceses.vue';
import CompanyAssumptionCost from './components/company-assumption-cost.vue';
import CompanySettingsPro from './components/company-settings-pro.vue';
import StringUtils from '@/utils/string-utils';

export default Vue.extend({
  name: 'MyCompany',
  components: {
    CompanySettings,
    CompanySettingsAdvenced,
    CompanyInvoiceses,
    CompanySettingsPro,
    CompanyAssumptionCost,
  },
  data: () => ({
    tab: 0,
    tabs: [
      { title: 'Stammdaten', icon: mdiContentSaveOutline, iconColor: '' },
      { title: 'Einstellungen', icon: mdiCogOutline, iconColor: '' },
      { title: 'Kostenübernahme', icon: mdiCreditCardSettingsOutline, iconColor: '' },
      // { title: 'Rechnungen', icon: mdiPlayOutline, iconColor: 'success' },
      // { title: 'Kostenübernahme', icon: mdiCreditCardSettingsOutline, iconColor: '' },
    ],
  }),
  mounted() {
    if (this.isDemo) {
      this.tabs.push({ title: 'Upgrade Pro Plan', icon: mdiShieldKeyOutline, iconColor: 'success' });
    }
    let upgrade = this.$route.params.upgrade;

    if (!StringUtils.isEmpty(upgrade) && this.isDemo) {
      this.tab = 3;
    }
  },
});
