import BaseController from './base';
import PDFMake from 'pdfmake/build/pdfmake';
import PDFFonts from 'pdfmake/build/vfs_fonts';
import axios from 'axios';
import Configs from '@/config/config';
import _ from 'lodash';
import moment from 'moment';
import Crypto from '@/utils/crypto';
import { File as FileModel, PDFDesign } from '@/models';
import StorageController from './storage';
import FilterHelper from '@/helpers/filter-helper';
import * as yup from 'yup';
import API from './api';
import { PDFDesingType } from '@/enums';
/**
 *  @description optimized 19.04.2022
 */
export default class PDFController extends BaseController {
  private template: any;
  private doc: PDFMake.TCreatedPdf | undefined;
  private storage: StorageController = new StorageController();
  private engineConfig = {
    imports: {
      formatDate: (v: any) => moment(v).format(Configs.dateFormat),
      formatDateTime: (v: any) => moment(v).format(Configs.dateTimeFormat),
    },
  };

  constructor() {
    super();
    PDFMake.vfs = PDFFonts.pdfMake.vfs;
  }

  /**
   *
   * @param name
   * @param data
   * @param mode
   * @returns
   * @deprecated artık server side
   */
  public async getTemplate(name: string, data?: any, mode: 'Json' | 'Eval' = 'Json') {
    return await axios.get(Configs.app.page_url + '/' + Configs.templatePath + name + '.json').then(res => {
      // template separator
      _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
      if (mode == 'Json') {
        const _template = JSON.stringify(res.data);
        let compiled = _.template(_template, this.engineConfig);
        this.template = JSON.parse(compiled(data));
      } else if (mode == 'Eval') {
        const _template = res.data;
        let compiled = _.template(_template, this.engineConfig);
        var rs = compiled(data);
        var result = '';
        eval('result=' + rs);
        this.template = result as any;
      }
      return this;
    });
  }

  public createPDF(): this {
    if (!this.template) {
      throw new Error('template not initialized');
    }

    this.doc = PDFMake.createPdf(this.template);
    return this;
  }

  public getBlob(callback: (result: Blob) => void) {
    if (!this.doc) {
      throw new Error('doc is not initialized');
    }
    return this.doc.getBlob(callback);
  }

  public getFile(filename?: string) {
    // callback: (result: globalThis.File) => void,
    return new Promise((resolve: (res: File) => void, reject) => {
      if (!this.doc) {
        reject('doc is not initialized');
        return;
      }
      this.doc.getBlob((result: Blob) => {
        const newFileName = filename ?? Crypto.NoSqlId() + '.pdf';
        resolve(new File([result], newFileName));
      });
    });
  }

  /**
   *
   * @param filename
   * @param ref
   * @param field
   * @returns
   * @deprecated
   */
  public async createAndUpload(filename?: string, ref?: string, field?: string) {
    return await this.createPDF()
      .getFile(filename)
      .then(async result => {
        let model = new FileModel(result);
        return await this.storage.upload(model, ref, field).then((res: FileModel) => {
          return res;
        });
      });
  }

  public async createAndUpdate(file: FileModel) {
    return await this.createPDF()
      .getFile(file.name)
      .then(async result => {
        let model = new FileModel(result);
        return await this.storage.upload(model).then((res: FileModel) => {
          return res;
        });
      });
  }

  //#region PDFDesign

  public async pdfDesignInsert(pdfDesign: PDFDesign): Promise<PDFDesign> {
    pdfDesign.test_center = this.testCenter;
    const pdfDesign_packaged = pdfDesign.package();
    return await this.uow.PDFDesigns.insert(pdfDesign_packaged);
  }

  public async pdfDesignUpdate(pdfDesign: PDFDesign): Promise<PDFDesign> {
    const pdfDesign_packaged = pdfDesign.package();
    return await this.uow.PDFDesigns.update(pdfDesign_packaged);
  }

  public async getPDFDesign(type: PDFDesingType) {
    const filter = new FilterHelper().equal('test_center.uid', this.testCenter.uid).equal('type', type);
    return await this.uow.PDFDesigns.whereOne(filter);
  }

  public async getDefaultPDFDesign(type: PDFDesingType) {
    return await axios
      .get(`${Configs.app.page_url}/${Configs.templatePath}/default_${type}_pdf.html`)
      .then(x => x.data);
  }

  public async getMultiTicket(id: number, count: number) {
    const valid = await yup.number().required().max(10).isValid(count);
    if (!valid) {
      throw new Error('Validation Error : Count = ' + count + ' Expected max 10');
    }
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('count', count.toString());

    const url = '/app/appointment/multi-ticket';
    return await API.strapi.request('POST', url, { data: formData });
  }

  //#endregion
}
