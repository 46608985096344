








import Vue from 'vue';
import ImportTable from './components/import-table.vue';
export default Vue.extend({
  name: 'Imports',

  components: {
    ImportTable,
  },

  mounted() {},
});
