var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.exp),callback:function ($$v) {_vm.exp=$$v},expression:"exp"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Positive Meldungen Einstellungen ")]),_c('v-expansion-panel-content',[_c('v-form',{ref:"export",model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}},[_c('v-row',[_c('v-col',[_c('v-radio-group',{model:{value:(_vm.setting.auto_send),callback:function ($$v) {_vm.$set(_vm.setting, "auto_send", $$v)},expression:"setting.auto_send"}},[_c('v-radio',{attrs:{"value":false,"label":"Manuelle Meldung an das Gesundheitsamt"}}),_c('v-radio',{attrs:{"value":true,"label":"Zeitgesteuerte Meldung an das Gesundheitsamt"}})],1)],1)],1),(_vm.setting.auto_send)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field-simplemask',{attrs:{"label":"Zeit *","properties":{
                      prefix: '',
                      suffix: '',
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: 'Only Hours',
                      class: 'text-center',
                      rules: [_vm.requiredRules, _vm.timeRules],
                    },"options":{
                      inputMask: '##:00',
                      outputMask: '##:00',
                      empty: null,
                      applyAfter: false,
                      alphanumeric: true,
                      lowerCase: false,
                    },"focus":_vm.focus},on:{"focus":function($event){_vm.focus = false}},model:{value:(_vm.auto_send_time),callback:function ($$v) {_vm.auto_send_time=$$v},expression:"auto_send_time"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":"Email *","placeholder":"john@example.com","rules":[_vm.requiredRules, _vm.emailRules]},model:{value:(_vm.setting.inform_mail),callback:function ($$v) {_vm.$set(_vm.setting, "inform_mail", $$v)},expression:"setting.inform_mail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":"Email-Adresse für Benachrichtigung im Fehlerfall","placeholder":"john@example.com","rules":[_vm.emailRules]},model:{value:(_vm.setting.inform_error_mail),callback:function ($$v) {_vm.$set(_vm.setting, "inform_error_mail", $$v)},expression:"setting.inform_error_mail"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.__('Controls.Save'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }