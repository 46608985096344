








import Vue from 'vue'
import AppointmentTable from './components/appointments-table.vue'

export default Vue.extend({
  name: 'Events',

  components: {
    AppointmentTable,
  },

  mounted() {},
})
