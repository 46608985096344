import FilterHelper from '@/helpers/filter-helper';
import { Branch, Holiday, TimeSlot } from '@/models';
import BaseController from './base';

/**
 *  @description optimized 19.04.2022
 */
export default class TimeSlotController extends BaseController {
  constructor() {
    super();
  }

  public async getTimeSlots(branch_id: number) {
    this.loading = true;
    let filter = new FilterHelper().equal('branch.id', branch_id).lazyLoad('branch', 'id', false, true);
    return await this.uow.TimeSlots.where(filter).then(result => {
      this.loading = false;
      return result;
    });
  }

  public async insertTimeSlot(branch: Branch, timeslot: TimeSlot) {
    if (branch == null || branch.id == 0) {
      return Promise.reject(new Error('branch is not available'));
    }

    timeslot.branch = branch.compress();
    return await this.uow.TimeSlots.insert(timeslot).then(res => {
      return res;
    });
  }

  public async updateTimeSlot(branch: Branch, timeslot: TimeSlot) {
    timeslot.branch = branch.compress();
    const res = await this.uow.TimeSlots.update(timeslot);
    return res;
  }
  public async deleteTimeSlot(timeslot: TimeSlot) {
    const res = await this.uow.TimeSlots.delete(timeslot.id);
    return res;
  }


  public async getHolidays(branch_id: number) {
    this.loading = true;
    let filter = new FilterHelper().equal('branch.id', branch_id).lazyLoad('branch', 'id', false, true);
    return await this.uow.Holidays.where(filter).then(result => {
      this.loading = false;
      return result;
    });
  }

  public async insertHoliday(branch: Branch, holiday: Holiday) {

    if (branch == null || branch.id == 0) {
      return Promise.reject(new Error('branch is not available'));
    }

    holiday.branch = branch;
    let holiday_packaged = holiday.package();
    return await this.uow.Holidays.insert(holiday_packaged).then(res => {
      return res;
    });
  }
  public async deleteHoliday(holiday: Holiday) {
    const res = await this.uow.Holidays.delete(holiday.id);
    return res;
  }
}
