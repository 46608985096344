import Configs from '@/config/config';
import GQL from '@/uow/gql';
import { getAppointments, getExport, getTestCenter, getTestCount, getTestkits } from '@/uow/graphQL';
import moment from 'moment';

/**
 *  @description optimized 19.04.2022
 */
export default class GraphQLHelper {
  public static getAppointmentsRun(ids: number[], start_date?: Date): GQL {
    const now = moment(start_date).format(Configs.dateServerFormat);
    let query = new GQL(getAppointments, 'GetAppointments', { branch_ids: ids, start_date: now }, 'appointments');
    return query;
  }

  public static getTestCenterRun(uid: string): GQL {
    let query = new GQL(getTestCenter, 'GetTestCenter', { user_uid: uid }, 'testCenters');
    return query;
  }

  public static getTestCountRun(test_center_uid: string, start_date: string, end_date: string): GQL {
    let query = new GQL(
      getTestCount,
      'GetTestCount',
      {
        test_center_uid: test_center_uid,
        start_date: start_date,
        end_date: end_date,
      },
      'appointments',
    );
    return query;
  }

  public static getExportRun(
    test_center_id: number,
    start_date: string,
    end_date: string,
    result: string | undefined,
  ): GQL {
    let query = new GQL(
      getExport,
      'GetExport',
      {
        test_center_id: test_center_id,
        start_date: start_date,
        end_date: end_date,
        result: result,
      },
      'appointments',
    );
    return query;
  }

  public static getTestkitsRun(test_center_uid: string): GQL {
    let query = new GQL(getTestkits, 'GetTestkits', { test_center_uid: test_center_uid }, 'testkits');
    return query;
  }
}
