





















































































import Configs from '@/config/config'
import BranchController from '@/controller/branch'
import UI from '@/controller/ui'
import { Branch } from '@/models'
import StringUtils from '@/utils/string-utils'
import { mdiContentCopy } from '@mdi/js'
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'

export default Vue.extend({
  name: 'BookingPreview',
  components: {
    QrcodeVue,
  },
  data: () => ({
    branch: new Branch(),
    icons: {
      mdiContentCopy,
    },
    links: {
      location: '',
      redirect: '',
    },
    controller: new BranchController(),
  }),
  mounted() {
    let branch_id = this.$route.params.id

    if (StringUtils.isEmpty(branch_id)) {
      this.$router.push('/error-404')
    }

    this.controller.init().then((data: Branch[]) => {
      let finder = data.find(x => x.id == Number(branch_id))
      if (finder == null) {
        this.$router.push('/error-404')
        return
      }
      this.branch = finder
      this.links.redirect =
        this.window.location.origin +
        this.$router.resolve({ name: 'Booking', params: { id: this.branch.url ?? '', type: 'redirect' } }).href
      this.links.location =
        this.window.location.origin +
        this.$router.resolve({ name: 'Booking', params: { id: this.branch.url ?? '', type: 'location' } }).href
    })
  },
  methods: {
    copy(text: string) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          UI.snackOpen('Url wurde kopiert.', 'success')
        })
        .catch(() => {
          UI.snackOpen('Url konnte nicht kopiert werden!', 'warning')
        })
      // navigator.clipboard.writeText(this.$refs[refName] as any)
    },
  },
})
