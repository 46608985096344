import { display, hidden, notMapped } from '@/controller/reflections';
import Branch from './branch';
import { ICU } from './imodels';

export default class TimeSlot extends ICU {
  @display('Model.ID')
  @hidden()
  public id: number = 0;

  @display('Model.Name', true)
  public name?: string | null = '';

  @hidden()
  public monday: boolean = true;
  @hidden()
  public tuesday: boolean = true;
  @hidden()
  public wednesday: boolean = true;
  @hidden()
  public thursday: boolean = true;
  @hidden()
  public friday: boolean = true;
  @hidden()
  public saturday: boolean = false;
  @hidden()
  public sunday: boolean = false;

  @display('Model.Person', true)
  public customer_per_time_slots: number = 15;
  @display('Model.Interval', true)
  @hidden()
  public minute_per_time_slots: number = 1;

  @display('Model.MaxDay', true)
  @hidden()
  public max_day: number = 15;

  @display('Model.Start', true)
  public start_date: Date = new Date();
  @display('Model.End', true)
  public end_date: Date | null = null;
  @display('Model.StartTime', true)
  public start_time_first: string = '08:00:00.000';
  @display('Model.EndTime', true)
  public end_time_first: string = '17:00:00.000';
  @hidden()
  @display('Model.StartTime', true)
  public start_time_second: string | null = null;
  @hidden()
  @display('Model.EndTime', true)
  public end_time_second: string | null = null;
  @display('Model.Status', true)
  public status: boolean = true;

  @hidden()
  @notMapped()
  public branch: Branch = <Branch>new ICU();
}
