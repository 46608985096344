
























































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import DatePicker from '@/components/datepicker.vue';
import { InvoiceCustomer, Product } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiEyeOutline, mdiDelete, mdiFileDownloadOutline, mdiPencilOutline } from '@mdi/js';
import InvoiceController from '@/controller/invoice';
import moment from 'moment';
import Configs from '@/config/config';
import DownloadController from '@/controller/download';
import { PaymentMethodType } from '@/enums';

export default Vue.extend({
  components: {
    Editable,
    DatePicker,
  },
  data() {
    return {
      title: 'Zahlungsübersicht €',
      show: false,
      edit: false,
      selected: new InvoiceCustomer(),
      controller: new InvoiceController(),
      items: <InvoiceCustomer[]>[],
      icons: {
        mdiEyeOutline,
        mdiDelete,
        mdiPencilOutline,
        mdiFileDownloadOutline,
      },
      totals: {
        paypal: 0,
        cash: 0,
        pos: 0,
        total: 0,
      },
      date: moment().format(Configs.dateFormat),
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(InvoiceCustomer);
      headers.splice(headers.length - 2, 0, {
        text: __('Model.PaymentMethod'),
        value: 'payment_method',
      });
      return headers;
    },
  },
  watch: {
    date(n, o) {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
    UI.$on('table.refresh', this.refresh);
  },
  beforeDestroy() {
    UI.$off('table.refresh', this.refresh);
  },
  methods: {
    refresh() {
      this.controller.getInvoiceCustomer(this.date).then((x: InvoiceCustomer[]) => {
        this.items = x;
        this.totals.paypal = _.sumBy(x, e => (e.appointment?.payment_status == PaymentMethodType.Paypal ? e.total : 0));
        this.totals.cash = _.sumBy(x, e => (e.appointment?.payment_status == PaymentMethodType.Cash ? e.total : 0));
        this.totals.pos = _.sumBy(x, e => (e.appointment?.payment_status == PaymentMethodType.POS ? e.total : 0));
        this.totals.total = _.sumBy(x, e => (e.appointment?.payment_status != PaymentMethodType.None ? e.total : 0));
      });
    },
    clearform() {
      this.selected = new InvoiceCustomer();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    open(item: InvoiceCustomer) {
      if (item.pdf) {
        const url = UI.getURLBase(item.pdf.url ?? '');
        DownloadController.downloadURL(url, item.pdf.name, true);
      }
    },
    // update(item: any) {
    //   this.selected = item;
    //   console.log(item, 'Update(item)');
    //   this.edit = true;
    //   this.show = true;
    // },
    // deleting(item: any) {
    //   if (item.default) {
    //     UI.snackOpen('Default cannot delete.', 'warning');
    //     return false;
    //   }
    //   if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
    //     // this.selected = item
    //     this.controller.deleteProduct(item).then(() => {
    //       this.refresh();
    //     });
    //   }
    // },
    // create() {
    //   this.selected = new Product();
    //   this.show = true;
    // },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
  },
});
