







import Vue from 'vue';
import ReservationsTable from './components/reservations-table.vue';

export default Vue.extend({
  name: 'Reservations',
  components: {
    ReservationsTable,
  },

  data: () => ({}),
  mounted() {},
  methods: {},
});
