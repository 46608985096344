import { display, hidden, notMapped } from '@/controller/reflections';
import { ICU, IFormats } from './imodels';

export default class File extends ICU {
  /**
   *
   */
  constructor(baseFile?: globalThis.File) {
    super();
    this.content = baseFile;
    this.name = baseFile?.name;
    this.size = baseFile?.size;
    this.mime = baseFile?.type;
  }

  @hidden()
  public name?: string | undefined;

  @hidden()
  public formats?: IFormats | undefined;

  @hidden()
  public alternativeText = '';

  @hidden()
  public caption = '';

  @display('Model.Width', true)
  public width = 0;

  @display('Model.Height', true)
  public height = 0;

  @hidden()
  public hash?: string | undefined;

  @hidden()
  public ext = '';

  @hidden()
  public mime?: string | undefined;

  @display('Model.Size', true)
  public size?: number | undefined = 0;

  @hidden()
  public url?: string | undefined;

  @hidden()
  public previewUrl: string = '';

  @hidden()
  public provider?: string | undefined;

  @hidden()
  public provider_metadata?: any | undefined;

  @notMapped()
  public content?: globalThis.File | undefined;
}
