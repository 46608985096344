



























































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { TestCenter, User } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiDelete, mdiPencilOutline, mdiPlus, mdiFileChartOutline, mdiEmailOutline } from '@mdi/js';
import AppCustomerDialog from './app-customer-dialog.vue';
import UserController from '@/controller/user';
import EmailController from '@/controller/email';

export default Vue.extend({
  components: {
    Editable,
    AppCustomerDialog,
  },
  data() {
    return {
      title: 'App Kunde',
      show: false,
      edit: false,
      selected: new User(),
      selected_test_center: new TestCenter(),
      controller: new UserController(),
      items: <TestCenter[]>[],
      icons: {
        mdiEmailOutline,
        mdiDelete,
        mdiPencilOutline,
        mdiFileChartOutline,
        mdiPlus,
      },
      email_controller: new EmailController(),
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(TestCenter);
      // headers.splice(headers.length, 0, {
      //   text: 'Invoice',
      //   value: 'createinvoice',
      // });
      headers.splice(headers.length, 0, {
        text: 'Test Counts',
        value: 'testcounts',
      });
      headers.splice(headers.length, 0, {
        text: 'Einladung',
        value: 'invitation',
      });
      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.initAdmin().then((test_centers: TestCenter[]) => {
        this.items = test_centers;
      });
    },
    clearform() {
      this.selected = new User();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: TestCenter) {
      const customer = _.sortBy(item.users, ['created_at']).pop();
      if (customer) {
        this.selected = customer;
        this.selected_test_center = item;
        console.log(item, 'Update(item)');
        this.edit = true;
        this.show = true;
      }
    },
    create() {
      this.selected = new User();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
    async sendInvitation(uid: string) {
      if (confirm('Sind Sie sicher, dass Sie senden möchten')) {
        this.email_controller.sendInvitation(uid, true).then(() => {
          UI.snackOpen('Invitation sended');
        });
      }
    },
    goTestCounts(uid: string) {
      this.$router.push({ name: 'TestCounts', params: { uid: uid } });
    },
    goCreateInvoice(uid: string) {
      this.$router.push({ name: 'InvoiceCreate', params: { uid: uid } });
    },
  },
});
