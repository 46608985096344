import { display, hidden } from '@/controller/reflections';
import { ICU } from './imodels';
import File from './file';
import { RequiredPdfMode } from '@/enums';

export default class Setting extends ICU {
  public email_text: string = '';
  public inform_mail: string = '';
  public inform_error_mail: string = '';
  public auto_send: boolean = false;
  public auto_send_time: string = '00:00:00';
  public phone_is_required: boolean = false;
  public identity_is_required: boolean = false;
  public test_15m_before: boolean = false;
  public required_pdf_mode: RequiredPdfMode = RequiredPdfMode.None;

  @display('Model.TermPdf', true)
  public term_pdf?: File;
  @display('Model.TermUrl', true)
  @hidden()
  public term_url?: string;
  @display('Model.PrivacyPdf', true)
  public privacy_pdf?: File;
  @display('Model.PrivacyUrl', true)
  @hidden()
  public privacy_url?: string;
  @display('Model.RequiredDoc', true)
  public required_pdf?: File;
  public signature_img?: File;

  public costly_text: string = '';
  public free_text: string = '';
  public extra_costly: number = 0;
  public join_costly: number = 3;
  public assumption_cost: boolean = false;
  /**
   * @type {object}
   * !dont do notMapped
   */
  public payment = {
    pay_on_spot: false,
    pay_on_spot_text: 'Bezahlung bar oder elektronisch im Testzentrum',
    status: false,
    tax_number: '',
  };
}
