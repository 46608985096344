export default class Configs {
  public static app = {
    owner: 'Walnut.ist',
    owner_url: 'http://walnut.ist',
    name: 'CoTest24 App',
    company_name: 'CoTest24 GmbH',
    page_url: process.env.NODE_ENV === 'production' ? 'https://cotest24.app' : 'http://localhost:8080',
    landing_page_url:
      process.env.NODE_ENV === 'production' ? 'https://coronatest-software.de' : 'https://coronatest-software.de',
    address: {
      street: 'Bremer Straße 16',
      postalcode: '44135',
      district: 'Dortmund Deutschland',
      phone: '',
      fax: '',
      email: 'info@cotest24.de',
      registergericht: 'Ahmet Kanık',
      registernummer: '',
      taxID: '',
    },
  };
  public static api_base_url: string =
    process.env.NODE_ENV === 'production' ? 'https://api.cotest24.app' : 'http://localhost:1337';
  public static api_url: string =
    process.env.NODE_ENV === 'production' ? 'https://api.cotest24.app/v4' : 'http://localhost:1337/v4';
  public static graphQL_url: string =
    process.env.NODE_ENV === 'production' ? 'https://api.cotest24.app/graphql' : 'http://localhost:1337/graphql';
  public static api_token: string =
    process.env.NODE_ENV === 'production'
      ? '972a524378b4629b5d25607d2b875071356cd4c08976e54573c273bf36dcc0ec6fbc8146e2e194b04bc6c574015724a2d38273e7ca1482ff05d8603e15f1469b359eeee37a93d179f2bd3d50f7e1e05a3351d2d3cac606100a576b391877d4c1ce2a7debb0a2465bc34c9eec126e702fc543c48b527b92b7fd0bc17ec8cbc13c'
      : '972a524378b4629b5d25607d2b875071356cd4c08976e54573c273bf36dcc0ec6fbc8146e2e194b04bc6c574015724a2d38273e7ca1482ff05d8603e15f1469b359eeee37a93d179f2bd3d50f7e1e05a3351d2d3cac606100a576b391877d4c1ce2a7debb0a2465bc34c9eec126e702fc543c48b527b92b7fd0bc17ec8cbc13c';
  public static dateFormat: string = 'DD.MM.YYYY';
  public static dateServerFormat: string = 'YYYY-MM-DD';
  public static timeServerFormat: string = 'HH:mm:ss.SSS';
  public static dateTimeFormat: string = 'DD.MM.YYYY HH:mm';
  public static dateTimeServerFormat: string = 'YYYY-MM-DDTHH:mm:ss.SSS';
  public static timeFormat: string = 'HH:mm';
  public static timeSecondFormat: string = 'HH:mm:ss';
  public static term_url: string = 'http://coronatest-software.de/term.html';
  public static privacy_url: string = 'http://coronatest-software.de/privacy.html';
  public static max_day: number = 30; //? En fazla {max_day} gün ileri tarihe randevu alınabilir
  public static minSearchLen: number = 2; // < 4 den az ise
  public static employeeRoleId: number = 4;
  public static templatePath: string = 'templates/';
  public static ws_api_url: string =
    process.env.NODE_ENV === 'production' ? 'wss://api.cotest24.app/websocket' : 'ws://localhost:12401';
  public static resultURL: string =
    process.env.NODE_ENV === 'production' ? 'https://cotest24.app/result/' : 'http://localhost:8080/result/';
  public static demo_logo_url: string =
    process.env.NODE_ENV === 'production'
      ? 'https://cotest24.app/assets/img/demo.png'
      : 'http://localhost:8080/assets/img/demo.png';
  public static tinymce_key = 'yxfn6ccitw4w9dhu20qrutotn1tlciige541zd3uzqu9qz5l';
}
