



































































































































































import Vue from 'vue';
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiCalendar,
  mdiTestTube,
  mdiStoreOutline,
  mdiMailboxOpenOutline,
  mdiCogOutline,
  mdiFileDocumentOutline,
  mdiCrosshairsGps,
  mdiMapMarkerMultipleOutline,
  mdiMapMarkerRadiusOutline,
  mdiClipboardTextClockOutline,
  mdiAccountGroupOutline,
  mdiReact,
  mdiFile,
  mdiTextBoxSearchOutline,
  mdiAccountFilterOutline,
  mdiUpload,
  mdiDownload,
  mdiNumeric,
  mdiHelpCircleOutline,
  mdiVirusOutline,
  mdiEmailSendOutline,
  mdiPoll,
  mdiAccountLockOpen,
  mdiPackageVariantClosed,
} from '@mdi/js';
import NavMenuSectionTitle from './components/menu-section-title.vue';
import NavMenuGroup from './components/menu-group.vue';
import NavMenuLink from './components/menu-link.vue';
import Session from '@/controller/session';
import DashboardController from '@/controller/dashbord';

export default Vue.extend({
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },

  data: () => ({
    icons: {
      mdiHomeOutline,
      mdiAlphaTBoxOutline,
      mdiEyeOutline,
      mdiCreditCardOutline,
      mdiTable,
      mdiFileOutline,
      mdiFormSelect,
      mdiCalendar,
      mdiTestTube,
      mdiStoreOutline,
      mdiMailboxOpenOutline,
      mdiCogOutline,
      mdiFileDocumentOutline,
      mdiCrosshairsGps,
      mdiMapMarkerMultipleOutline,
      mdiMapMarkerRadiusOutline,
      mdiClipboardTextClockOutline,
      mdiAccountGroupOutline,
      mdiReact,
      mdiFile,
      mdiTextBoxSearchOutline,
      mdiAccountFilterOutline,
      mdiUpload,
      mdiDownload,
      mdiNumeric,
      mdiHelpCircleOutline,
      mdiEmailSendOutline,
      mdiPoll,
      mdiAccountLockOpen,
      mdiVirusOutline,
      mdiPackageVariantClosed,
    },
    positiveBadge: null as any,
  }),
  mounted() {
    if (Session.isTestCenter() && !Session.isDemo()) {
      DashboardController.getPositiveCount()
        .then(x => x.count)
        .then(count => {
          if (count > 0)
            this.positiveBadge = {
              content: count,
              color: 'error',
            };
        });
    }
  },
});
