


















































import Vue from 'vue';

// eslint-disable-next-line object-curly-newline
import {
  mdiCheck,
  mdiPlus,
  mdiLogin,
  mdiContentSaveAllOutline,
  mdiMinus,
  mdiAlertOctagonOutline,
  mdiTimerOutline,
  mdiFilterCheckOutline,
  mdiCancel,
  mdiCurrencyEur,
} from '@mdi/js';
import DashboardController from '@/controller/dashbord';
import { DashboardFilterType } from '@/enums';

export default Vue.extend({
  data: () => ({
    statisticsData: <any[]>[],
    selectedFilter: DashboardFilterType.All,
  }),
  mounted() {
    this.refreshData();
    DashboardController.subscribeCounts(this.refreshData);
  },
  beforeDestroy() {
    DashboardController.unsubscribeCounts(this.refreshData);
  },
  methods: {
    changedFilter(filter: DashboardFilterType) {
      DashboardController.filterType = filter;
      this.selectedFilter = filter;
    },
    refreshData() {
      this.statisticsData = [
        {
          title: 'Anmeldungen',
          total: DashboardController.counts,
          filter: DashboardFilterType.All,
          icon: mdiContentSaveAllOutline,
          color: 'primary',
          activable: true,
        },
        {
          title: 'Positive',
          total: DashboardController.positive,
          filter: DashboardFilterType.Positive,
          icon: mdiPlus,
          color: 'error',
          activable: true,
        },
        {
          title: 'Nicht Eindeutig',
          total: DashboardController.notclear,
          filter: DashboardFilterType.NotClear,
          icon: mdiAlertOctagonOutline,
          color: 'warning',
          activable: true,
        },
        {
          title: 'Negative',
          total: DashboardController.negative,
          filter: DashboardFilterType.Negative,
          icon: mdiMinus,
          color: 'success',
          activable: true,
        },
        {
          title: 'Aktuell Eingecheckt',
          total: DashboardController.checkin,
          filter: DashboardFilterType.Checkin,
          icon: mdiLogin,
          color: 'info',
          activable: true,
        },
        {
          title: 'Durchgeführte Tests',
          total: DashboardController.done,
          filter: DashboardFilterType.Done,
          icon: mdiCheck,
          color: 'secondary',
          activable: true,
        },
        {
          title: 'Laufende Tests',
          total: DashboardController.continues,
          filter: DashboardFilterType.Continues,
          icon: mdiTimerOutline,
          color: 'primary',
          activable: true,
        },
        {
          title: 'Alles Anzeigen',
          total: DashboardController.counts,
          filter: DashboardFilterType.All,
          icon: mdiFilterCheckOutline,
          color: 'secondary',
          activable: false,
        },
        {
          title: 'Kostenlose Tests',
          total: DashboardController.free,
          filter: DashboardFilterType.Free,
          icon: mdiCancel,
          color: 'secondary',
          activable: false,
        },
        {
          title: 'Mit Zuzahlung',
          total: DashboardController.paid,
          filter: DashboardFilterType.Paid,
          icon: mdiCurrencyEur,
          color: 'secondary',
          activable: false,
        },
        {
          title: 'Selbstzahler',
          total: DashboardController.selfpaid,
          filter: DashboardFilterType.SelfPaid,
          icon: mdiCurrencyEur,
          color: 'success',
          activable: false,
        },
      ];
    },
  },
});
