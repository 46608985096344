

























import Vue from 'vue'
import Editable from '@/components/editable.vue'
import { Appointment, Group } from '@/models'
import UI from '@/controller/ui'
import BranchController from '@/controller/branch'
import GroupDialog from './group-dialog.vue'
import _ from 'lodash'

export default Vue.extend({
  components: {
    Editable,
    GroupDialog,
  },
  data() {
    return {
      title: 'Standortgruppen',
      show: false,
      edit: false,
      selected: new Group(),
      controller: new BranchController(),
      items: <Group[]>[],
    }
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Group)
      return headers
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.controller.groupInit().then(x => {
        this.items = x
      })
    },
    clearform() {
      this.selected = new Group()
      // Remove my-component from the DOM
      this.show = true

      this.$nextTick(() => {
        // Add the component back in
        this.show = false
      })
    },
    close() {
      this.clearform()
      this.edit = false
      this.show = false
    },
    save(item: any) {
      this.refresh()
      console.log(item)
      this.close()
    },
    update(item: any) {
      this.selected = item
      console.log(item, 'Update(item)')
      this.edit = true
      this.show = true
    },
    deleting(item: any) {
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        this.controller.removeGroup(item).then(() => {
          this.refresh()
        })
      }
      // this.clearform()
    },
    create() {
      this.selected = new Group()
      this.show = true
    },
    doubleclick(item: any) {
      this.update(item)
    },
  },
})
