import { repoName } from '@/controller/reflections'
import { TestKit } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('TestkitRepository')
export default class TestkitRepository extends Repository<TestKit> {
  constructor() {
    super('testkits')
  }
  public convertType(value: object): TestKit {
    return Object.assign(new TestKit(), value)
  }
}
