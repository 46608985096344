import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import newStringify from './plugins/json';
import i18n from './plugins/translation';
import _, { forEach, LoDashStatic } from 'lodash';
import VuetifyMask from 'vuetify-mask';
import VueApexCharts from 'vue-apexcharts';
import Session from './controller/session';
import UI from './controller/ui';
import Cache from './controller/cache';
// import all scss
import '@/assets/scss/app.scss';
import '@/styles/styles.scss';
import QRCodeReader from 'vue-qrcode-reader';
import Configs from './config/config';
import Env from '@/plugins/env';
// import dotenv from 'dotenv';
// import env from 'raw-loader!@/.env';
// let configs = dotenv.parse(env); // load .env config
// Env.variable = configs;
Vue.use(VueApexCharts);
Vue.use(VuetifyMask);
Vue.use(QRCodeReader);

Vue.mixin({
  computed: {
    AppCache: function () {
      return Cache;
    },
    Session: function () {
      return Session;
    },
    UI: function () {
      return UI;
    },
    Configs: function () {
      return Configs;
    },
    _: function () {
      return _;
    },
    window: function () {
      return window;
    },
    Env: function () {
      return Env;
    },
    isAdmin: function () {
      return Session.isAdmin();
    },
    isDemo: function () {
      return Session.isDemo();
    },
    isPro: function () {
      return Session.isPro();
    },
    isPremium: function () {
      return Session.isPremium();
    },
    isCustomer: function () {
      return Session.isCustomer();
    },
  },
});

// #endregion

Vue.config.productionTip = false;
declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface Vue {
    $eventBus: Vue;
    _: LoDashStatic;
    __: typeof __;
    Session: typeof Session;
    UI: typeof UI;
    AppCache: typeof Cache;
    Configs: typeof Configs;
    window: typeof window;
    Env: typeof Env;
    isAdmin: boolean;
    isDemo: boolean;
    isPro: boolean;
    isPremium: boolean;
    isCustomer: boolean;
  }
}

const vueApp = new Vue({
  vuetify,
  i18n,
  render: h => h(App),
  router,
});

vueApp.$eventBus = new Vue();
(window as any).vueApp = vueApp;
if (Env.isDev) (window as any).Dev = Env;
// ;(JSON.stringify as any) = newStringify
vueApp.$mount('#app');

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('sw.js');
// }
