



























import Configs from '@/config/config';
import moment from 'moment';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: moment().format(Configs.dateFormat),
    },
    rules: {
      type: Array as PropType<Function[]>,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    date: moment().format(Configs.dateFormat),
    mask: '##.##.####',
  }),
  mounted() {
    this.date = this.value ? this.value : this.date;
  },
});
