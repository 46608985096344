



































































import CustomeFieldController from '@/controller/custome-field';
import { FieldStatus, FieldType } from '@/enums';
import { CustomeField } from '@/models';
import { mdiCalendar } from '@mdi/js';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CustomeFields',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      },
    },
    decomposition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FieldStatus,
      FieldType,
      menu: false,
      icons: {
        mdiCalendar,
      },
      data: <any[]>[],
      controller: new CustomeFieldController(),
      fields: new Array<CustomeField>(),
      dateMenu: <boolean[]>[],
      isActive: false,
    };
  },
  watch: {},
  computed: {
    internalValue: {
      get(): any {
        return this.data;
      },
      set(val: any) {
        this.$emit('input', this.data);
      },
    },
  },
  mounted() {
    this.data = this.value;
    this.controller.init().then(x => {
      const active_fields = x.filter(x => x.status != FieldStatus.Deactive);
      this.fields = active_fields;
      this.fields.forEach((value, i) => {
        let isExist = this.data.some(x => x.shortcode == value.shortcode);
        if (!isExist) {
          this.data.push(this.controller.add(value));
        }
        if (!this.isActive)
          if (value.status && this.controller.perm(value, this.decomposition)) {
            this.isActive = true;
          }
      });
    });
  },
  methods: {
    requiredRule(required: boolean) {
      if (required) return (v: any) => !!v || 'Dieses Feld ist erforderlich';
      else return true;
    },
    minRule(min: number) {
      return (v: any) => v >= min || 'this field must be less than ' + min;
    },
    maxRule(max: number) {
      return (v: any) => v <= max || 'this field must be less than ' + max;
    },
    indexOf(item: CustomeField) {
      return this.data.findIndex(x => x.shortcode == item.shortcode);
    },
  },
});
