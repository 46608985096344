import { display, hidden, notMapped } from '@/controller/reflections';
import { ICU } from './imodels';
import TestCenter from './test-center';

export default class Group extends ICU {
  @notMapped()
  @hidden()
  public test_center: TestCenter = <TestCenter>new ICU();
  @display('Model.Name', true)
  public name: string = '';
  @display('Model.Description', true)
  public description: string | null = '';
}
