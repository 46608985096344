








import Vue from 'vue';
export default Vue.extend({
  name: 'Impressum',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      term_url: '',
      privacy_url: '',
    };
  },
  watch: {
    value(n, o) {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.value) {
        this.term_url = this.$router.resolve({ name: 'Impressum', params: { url: this.value } }).href;
        this.privacy_url = this.$router.resolve({ name: 'Privacy', params: { url: this.value } }).href;
      }
    },
  },
});
