




































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { Product } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiEyeOutline, mdiDelete, mdiPencilOutline } from '@mdi/js';
import ProductDialog from './product-dialog.vue';
import ProductController from '@/controller/product';

export default Vue.extend({
  components: {
    Editable,
    ProductDialog,
  },
  data() {
    return {
      title: 'Artikel',
      show: false,
      edit: false,
      selected: new Product(),
      controller: new ProductController(),
      items: <Product[]>[],
      icons: {
        mdiEyeOutline,
        mdiDelete,
        mdiPencilOutline,
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(Product);
      headers.splice(headers.length - 2, 0, {
        text: __('Model.Gross'),
        value: 'gross',
      });
      return headers;
    },
  },
  mounted() {
    this.refresh();
    UI.$on('table.refresh', this.refresh);
  },
  beforeDestroy() {
    UI.$off('table.refresh', this.refresh);
  },
  methods: {
    refresh() {
      this.controller.getProducts().then((x: any) => {
        this.items = x;
      });
    },
    clearform() {
      this.selected = new Product();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (item.default) {
        UI.snackOpen('Default cannot delete.', 'warning');
        return false;
      }
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        this.controller.deleteProduct(item).then(() => {
          this.refresh();
        });
      }
    },
    create() {
      this.selected = new Product();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
  },
});
