import BaseController from './base';
import _ from 'lodash';
import { BranchPermission, File } from '@/models';

/**
 *  @description optimized 19.04.2022
 */
export default class StorageController extends BaseController {
  constructor() {
    super();
  }

  public async upload(file: File, ref?: string, field?: string, refId?: number) {
    if (file.id != 0) {
      return await this.uow.Storage.update(file);
    }
    return await this.uow.Storage.customeInsert(file, ref, field, refId);
  }

  public async uploadLogo(file: File) {
    return await this.upload(file).then(async response => {
      const clone = _.clone(this.testCenter);
      clone.logo = response.compress();
      let clone_packaged = clone.package();
      await this.uow.TestCenters.update(clone_packaged);
    });
  }

  public async uploadPDFSignature(file: File, refId: number) {
    return await this.uow.Storage.customeInsert(file, 'api::setting.setting', 'signature_img', refId);
  }

  public async uploadProfile(file: File) {
    return await this.upload(file).then(async response => {
      const clone = _.clone(this.user);
      const perm = clone.branch_permission;
      if (perm) {
        perm.photo = response.compress();
        await this.uow.BranchPermissions.update({ id: perm.id, photo: perm.photo } as any);
      }
    });
  }

  public async resetProfile() {
    const clone = _.clone(this.user);
    const perm = clone.branch_permission;
    if (perm) {
      await this.uow.BranchPermissions.update({ id: perm.id, photo: null } as any);
    }
  }

  public async resetLogo() {
    await this.uow.TestCenters.update({ id: this.testCenter.id, logo: null } as any);
  }

  public async resetPDFSignature() {
    this.uow.Settings.update({ id: this.testCenter.setting.id, signature_img: null } as any);
  }
}
