import Configs from '@/config/config';
import { AppointmentResult, AppointmentStatus, FilterType, PaymentFreeStatus } from '@/enums';
import FilterHelper from '@/helpers/filter-helper';
import GraphQLHelper from '@/helpers/graphQL-helper';
import { TestCenter } from '@/models';
import StringUtils from '@/utils/string-utils';
import _ from 'lodash';
import moment from 'moment';
import BaseController from './base';
import Cache from './cache';

/**
 *  @description optimized 19.04.2022
 */
export default class TestCountsController extends BaseController {
  constructor() {
    super();
  }

  public async init(test_center_uid?: string) {
    let now = moment().format(Configs.dateServerFormat);
    if (StringUtils.isEmpty(test_center_uid)) {
      return this.getCounts(now, now);
    } else {
      return this.getCounts(now, now, test_center_uid);
    }
  }

  public async getCounts(start: string, end: string, test_center_uid?: string) {
    this.loading = true;
    let uid = !StringUtils.isEmpty(test_center_uid) ? test_center_uid ?? '' : this.testCenter.uid;
    let gql = GraphQLHelper.getTestCountRun(uid, start, end);
    // let filters = new FilterHelper()
    //   .equal('branch.test_center.uid', uid)
    //   .gte('start_date', start)
    //   .or('end_date', end, FilterType.$lte)
    //   .or('end_date', true, FilterType.$null)
    //   .lazyLoad('branch.test_center');
    return await this.uow.Appointments.graphQL(gql)
      .then(x => {
        this.loading = false;
        x = x || [];
        let items = x.map(item => {
          return {
            branch_id: item.branch.id,
            group: item.branch.group?.name ?? '-Kein',
            company_name: item.branch.company_name,
            result: item.result,
            status: item.status,
            payment_free_status: item.payment_free_status,
          };
        });
        let counts = [];
        let list = _.groupBy(items, 'branch_id');
        (window as any).list = list;
        for (const key in list) {
          if (Object.prototype.hasOwnProperty.call(list, key)) {
            const element = list[key];
            const first = element[0];
            counts.push({
              group: first.group,
              company_name: first.company_name,
              appointments: element.length,
              success_appointment: element.filter(x => x.status == AppointmentStatus.Finished).length,
              cancel_appointment: element.filter(x => x.status == AppointmentStatus.Cancel).length,
              negative: element.filter(x => x.result == AppointmentResult.Negative).length,
              notclear: element.filter(x => x.result == AppointmentResult.NotClear).length,
              positive: element.filter(x => x.result == AppointmentResult.Positive).length,
              free: element.filter(x => x.payment_free_status == PaymentFreeStatus.Free).length,
              paid: element.filter(x => x.payment_free_status == PaymentFreeStatus.Paid).length,
              self_paid: element.filter(x => x.payment_free_status == PaymentFreeStatus.SelfPaid).length,
            });
          }
        }
        return counts;
      })
      .catch(x => {
        this.loading = false;
        console.log(x);
        return [];
      });
  }
}
