import Configs from '@/config/config';
import FilterHelper from '@/helpers/filter-helper';
import GraphQLHelper from '@/helpers/graphQL-helper';
import { Branch, TestCenter, User } from '@/models';
import BranchPermission from '@/models/branch-permission';
import { ICU } from '@/models/imodels';
import StringUtils from '@/utils/string-utils';
import _ from 'lodash';
import BaseController from './base';
import BranchController from './branch';
import Cache from './cache';

export default class UserController extends BaseController {
  constructor() {
    super();
  }
  public async init() {
    let filter = new FilterHelper().equal('test_center.id', this.testCenter.id).notEqual('id', Cache.User.id);
    return await this.uow.Users.where(filter, false);
  }

  public async initAdmin() {
    // TODO : Optimize this query
    let filter = new FilterHelper()
      .lazyLoad('users.branch_permission')
      .field('company_name')
      .field('street')
      .field('website')
      .field('email')
      .field('uid')
      .field('account_type')
      .field('post_code')
      .field('district')
      .lazyLoad('logo')
      .field('phone');
    return await this.uow.TestCenters.where(filter).then(x => {
      return x;
    });
  }

  public async insertUser(user: User, permissions: number[]) {
    user.test_center = this.testCenter;
    // user.role = Configs.employeeRoleId as any
    let user_packaged = user.package();
    return this.uow.Users.insert(user_packaged, false).then(async res => {
      let branchPermission = new BranchPermission();
      branchPermission.branches = permissions as any; //* Releation
      branchPermission.user = res.id as any; //* Releation
      branchPermission.name = res.username + ' Permission';
      // TODO: Root Endpoint
      await this.uow.BranchPermissions.insert(branchPermission);
      return res;
    });
  }

  public updateUser(user: User, permissions: number[]) {
    user.test_center = this.testCenter;
    // user.role = Configs.employeeRoleId as any
    let user_packaged = user.package();
    user_packaged.password = StringUtils.isEmpty(user_packaged.password) ? (undefined as any) : user_packaged.password;
    return this.uow.Users.update(user_packaged, false).then(async res => {
      await new BranchController().getPermission(user.id).then(async perm => {
        if (perm) {
          perm.branches = permissions as any[];
          await this.uow.BranchPermissions.update(perm);
        }
      });
      return res;
    });
  }

  public updateUserAdmin(user: User) {
    user.test_center = this.testCenter;
    // user.role = Configs.employeeRoleId as any
    // let user_packaged = user.package()
    let user_packaged = { id: user.id, blocked: user.blocked } as User;
    return this.uow.Users.update(user_packaged, false).then(async res => {
      return res;
    });
  }

  public updateTestCenter(test_center: TestCenter) {
    // user.role = Configs.employeeRoleId as any
    // let user_packaged = user.package()
    let test_center_packaged = { id: test_center.id, account_type: test_center.account_type } as TestCenter;
    return this.uow.TestCenters.update(test_center_packaged).then(async res => {
      return res;
    });
  }

  public deleteUser(user: User) {
    return this.uow.Users.delete(user.id).then(res => {
      return res;
    });
  }
}
