
/**
 *  @description optimized 19.04.2022
 */
export default class Crypto {
  /**
   *
   * @returns c7438f55-fac9-fccd-8d96-9eb3a348315d
   */
  public static GUID() {
    var result, i, j
    result = ''
    for (j = 0; j < 32; j++) {
      if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-'
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toLowerCase()
      result = result + i
    }
    return result
  }

  /**
   *
   * @returns 00f56368-1906-4972-b679-fa13e6e070c2
   */
  public static UUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    let code = s[19].charCodeAt(0) & 0x3
    s[19] = hexDigits.substr(code | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    var uuid = s.join('')
    return uuid.toLowerCase()
  }

  /**
   *
   * @returns NDVhLWY5OTQ
   */
  public static NoSqlId() {
    var chars = '0123456789abcdef'.split('')
    var uuid = [],
      rnd = Math.random,
      r
    uuid[3] = '-'

    for (var i = 0; i <= 7; i++) {
      if (!uuid[i]) {
        r = 0 | (rnd() * 16)

        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r & 0xf]
      }
    }
    var newId = btoa(uuid.join(''))
    return newId.slice(0, -1)
  }

  /**
   *
   * @returns xyx-xyx -> 499-986
   */
  public get () {
    return 'xyx-xyx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}
