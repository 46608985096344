import StringUtils from '@/utils/string-utils';
import IStrapiParam from './istrapi-param';

export default class LazyLoad implements IStrapiParam {
  public paramType: string = 'LazyLoad';
  public name: string = '';
  public value: string = '';
  public index: number = 0;
  public cases: number = 0;

  constructor(name?: string, value?: string, deep: boolean = false, field: boolean = false, index: number = 0) {
    if (StringUtils.isEmpty(name)) {
      this.cases = 0;
      return;
    }
    this.name = name ?? '';
    this.value = value ?? '';
    this.index = index;

    if (deep) {
      this.cases = 3;
      return;
    }

    if (StringUtils.isEmpty(value)) {
      this.cases = 1;
      return;
    }

    if (field) {
      this.cases = 4;
      return;
    }

    this.cases = 2;
  }
  public url(): string {
    //! GET /api/articles?populate[categories][sort][0]=name%3Aasc&populate[categories][filters][name][$eq]=Cars
    switch (this.cases) {
      case 0:
        //* GET /api/articles?populate=*
        return `populate=*`;
      case 1:
        //* GET /api/articles?populate[0]=seoData&populate[1]=seoData.sharedImage&populate[2]=seoData.sharedImage.media
        //* GET /api/articles?populate[0]=categories
        return `populate[${this.index}]=${this.name}`;
      case 2:
        //* GET /api/articles?populate[author][populate][0]=company
        return `populate[${this.name}][populate][${this.index}]=${this.value}`;
      case 3:
        //* GET /api/articles?populate[testDZ][populate]=*
        return `populate[${this.name}][populate]=*`;
      case 4:
        //* GET /api/articles?fields[0]=title&fields[1]=slug&populate[headerImage][fields][0]=name&populate[headerImage][fields][1]=url
        return `populate[${this.name}][fields][${this.index}]=${this.value}`;
      default:
        return `populate=*`;
    }
  }
}
