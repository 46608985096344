import { TestCenter } from '@/models';
import _ from 'lodash';
import BaseController from './base';
import Session from './session';

/**
 *  @description optimized 19.04.2022
 */
export default class TestCenterController extends BaseController {
  constructor() {
    super();
  }

  public async init() {}

  public async update(testCenter: TestCenter) {
    let testCenter_packaged = testCenter.package();
    return await this.uow.TestCenters.update(testCenter_packaged).then(x => {
      Session.reloadTestCenter();
      return x;
    });
  }
}
