





















































































































import Vue, { PropType } from 'vue';
import { Product, TestCenter } from '@/models';
import ProductController from '@/controller/product';
import { mdiInformationOutline } from '@mdi/js';
import _ from 'lodash';

export default Vue.extend({
  name: 'ProductSelector',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<Product>,
    },
    branch: { type: Number },
    test_center: { type: Object as PropType<TestCenter> },
    costly: { type: Number },
    force_selected: { type: Object as PropType<Product> },
  },
  data() {
    return {
      selected: new Product(),
      controller: new ProductController(),
      items: <Product[]>[],
      icons: {
        mdiInformationOutline,
      },
      costly_mode: false,
      extra_costly: 0,
      extra_costly_description: '',
      free_costly: 0,
      free_costly_description: '',
    };
  },
  mounted() {
    let product_id = null as any;
    this.costly_mode = this.test_center.setting.assumption_cost;
    this.extra_costly = this.test_center.setting.extra_costly;
    this.free_costly = this.test_center.setting.join_costly;
    this.extra_costly_description = this.test_center.setting.costly_text;
    this.free_costly_description = this.test_center.setting.free_text;
    if (this.force_selected) {
      this.selected = this.force_selected;
      product_id = this.force_selected.id;
    }
    this.controller.getProducts(this.branch, this.test_center.id, product_id).then((x: any) => {
      this.items = x;
    });
  },
  watch: {
    value(n, o) {
      this.selected = n;
    },
  },
  methods: {
    showExtraCostlyDescription() {
      if (!_.isEmpty(this.extra_costly_description)) alert(this.extra_costly_description);
    },
    showFreeCostlyDescription() {
      if (!_.isEmpty(this.free_costly_description)) alert(this.free_costly_description);
    },
    extraCostly() {
      this.selected = null as any;
      this.$emit('input', null);
      this.$emit('costlyInput', 1);
    },
    freeCostly() {
      this.selected = null as any;
      this.$emit('input', null);
      this.$emit('costlyInput', 2);
    },
    isActive(item: Product) {
      if (!this.selected) return false;
      return item.id == this.selected.id;
    },
    show(item: Product) {
      if (!_.isEmpty(item.description)) alert(item.description);
    },
    choise(item: Product) {
      this.$emit('costlyInput', null);
      if (item == this.selected && !this.force_selected) {
        this.selected = null as any;
        this.$emit('input', null);
        return;
      }
      this.selected = item;
      this.$emit('input', item);
    },
  },
});
