





























































































































import Vue from 'vue';
import { CustomeField } from '@/models';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose } from '@mdi/js';
import { FieldStatus, FieldType } from '@/enums';
import CustomeFieldController from '@/controller/custome-field';

export default Vue.extend({
  name: 'BranchDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<CustomeField>,
      default: () => {
        return new CustomeField();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    FieldType,
    FieldStatus,
    CustomeField,
    icons: {
      mdiClose,
    },
    customeField: new CustomeField(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    controller: new CustomeFieldController(),
    validation: false,
    editmode: false,
  }),
  mounted() {
    if (this.value.id != 0) {
      this.customeField = Object.assign(new CustomeField(), this.value);
      this.editmode = this.customeField.id != 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.group as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }
      if (!this.editmode) {
        this.controller
          .insertCustomeField(this.customeField)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('CustomeField not created : ' + x.message, 'error');
          });
      } else {
        this.controller
          .updateCustomeField(this.customeField)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('CustomeField not updated : ' + x.message, 'error');
          });
      }
    },
  },
});
