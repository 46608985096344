export default class StringUtils {
  public static isEmpty(value?: any | null): boolean {
    if (value == undefined || value == null || value == 'null' || value == '') {
      return true;
    }

    return false;
  }
  public static convertBom(data: string): string {
    if (data.codePointAt(0) == 0xfeff) return data;
    else return '\uFEFF' + data;
  }
}
