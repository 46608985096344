import Configs from '@/config/config';
import { customeColumn, display, hidden, notMapped } from '@/controller/reflections';
import { ImportStatus } from '@/enums';
import moment from 'moment';
import Appointment from './appointment';
import Branch from './branch';
import { ICU } from './imodels';
import JsonObject from './json-object';
import TestKit from './testkit';

export default class Import extends ICU {
  @display('Model.Name', true)
  public name: string = '';
  @display('Branch', true)
  @customeColumn({ path: 'branch.company_name' })
  @notMapped()
  public branch: Branch = new ICU() as Branch;
  @hidden()
  @notMapped()
  public testkit: TestKit = new ICU() as TestKit;
  @display('Model.StartDate', true)
  public start_date: string = moment().format(Configs.dateServerFormat);
  @display('Model.StartTime', true)
  public start_time: string = moment().format(Configs.timeServerFormat);
  @display('Model.Count', true)
  public count: number = 0;
  @display('Model.Status', true)
  public status: ImportStatus = ImportStatus.Processing;
  @notMapped()
  @hidden()
  public appointments: Array<Appointment> = [];
  //! Olası Hata : Not Mapped Yapmayın
  @hidden()
  public content: any;
}
