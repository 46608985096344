var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editable',{attrs:{"deletable":false,"headers":_vm.headers,"items":_vm.items,"title":_vm.title,"loading":_vm.controller.loading},on:{"update":_vm.update,"create":_vm.create,"delete":_vm.deleting,"doubleclick":_vm.doubleclick},scopedSlots:_vm._u([{key:"tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getPercentage(item.tax))+" ")]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getPercentage(item.discount))+" ")]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UI.getCurrency(item.total))+" ")]}},{key:"paid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-semibold",attrs:{"color":_vm.getStatusColor(item)}},[_vm._v(" "+_vm._s(_vm.getStatus(item))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }