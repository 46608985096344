import { repoName } from '@/controller/reflections'
import { PaymentMethod } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('PaymentMethodRepository')
export default class PaymentMethodRepository extends Repository<PaymentMethod> {
  constructor() {
    super('payment-methods')
  }
  public convertType(value: object): PaymentMethod {
    return Object.assign(new PaymentMethod(), value)
  }
}
