



















import Vue from 'vue'
import LayoutBlank from '@/layouts/blank.vue'
import LayoutContent from '@/layouts/content.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'

export default Vue.extend({
  name: 'App',
  components: {
    LayoutBlank,
    LayoutContent,
    UpgradeToPro,
  },

  data: () => ({
    blank: false,
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeOut: 3000,
    resolveLayout: 'layout-blank',
  }),

  mounted() {
    const routeMeta = this.$route.meta || { blank: false }
    this.blank = routeMeta.blank

    this.setupUI()
    this.$root.$eventBus.$on('pageLayout', this.pageLayout)
    this.$root.$eventBus.$on('snackOpen', this.snackOpen)
  },

  beforeDestroy() {
    this.$root.$eventBus.$off('pageLayout', this.pageLayout)
    this.$root.$eventBus.$off('snackOpen', this.snackOpen)
  },

  methods: {
    pageLayout(status: boolean) {
      this.resolveLayout = status ? 'layout-blank' : 'layout-content'
    },
    setupUI() {
      this.resolveLayout = this.blank ? 'layout-blank' : 'layout-content'
    },
    snackOpen(message: string, color = 'success', timeOut?: number): void {
      this.snack = true
      this.snackColor = color
      this.snackText = message
      this.snackTimeOut = timeOut ?? 3000
    },
  },
})
