








































import Vue from 'vue';
import Editable from '@/components/editable.vue';
import { CustomeField } from '@/models';
import UI from '@/controller/ui';
import _ from 'lodash';
import { mdiEyeOutline, mdiDelete, mdiArrowUpThin, mdiArrowDownThin, mdiPencilOutline } from '@mdi/js';
import CustomeFieldDialog from './custome-fields-dialog.vue';
import CustomeFieldController from '@/controller/custome-field';

export default Vue.extend({
  components: {
    Editable,
    CustomeFieldDialog,
  },
  data() {
    return {
      title: __('Model.CustomeField'),
      show: false,
      edit: false,
      selected: new CustomeField(),
      controller: new CustomeFieldController(),
      items: <CustomeField[]>[],
      icons: {
        mdiEyeOutline,
        mdiDelete,
        mdiPencilOutline,
        mdiArrowUpThin,
        mdiArrowDownThin,
      },
    };
  },
  computed: {
    headers() {
      let headers = UI.getModelHeader(CustomeField);
      headers.splice(0, 0, {
        text: __('Model.Name'),
        value: 'name',
      });

      return headers;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.controller.init().then((x: any) => {
        this.items = x;
        this.$emit('preview');
      });
    },
    clearform() {
      this.selected = new CustomeField();
      // Remove my-component from the DOM
      this.show = true;

      this.$nextTick(() => {
        // Add the component back in
        this.show = false;
      });
    },
    close() {
      this.clearform();
      this.edit = false;
      this.show = false;
    },
    save(item: any) {
      this.refresh();
      console.log(item);
      this.close();
    },
    update(item: any) {
      this.selected = item;
      console.log(item, 'Update(item)');
      this.edit = true;
      this.show = true;
    },
    deleting(item: any) {
      if (item.default) {
        UI.snackOpen('Default cannot delete.', 'warning');
        return false;
      }
      if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
        // this.selected = item
        this.controller.deleteCustomeField(item).then(() => {
          this.refresh();
        });
      }
    },
    create() {
      this.selected = new CustomeField();
      this.show = true;
    },
    doubleclick(item: any) {
      // this.update(item)
      //Düzenleme Devredışı
      return false;
    },
    async up(item: any) {
      const index = this.items.indexOf(item);
      const upIndex = index > 1 ? index - 1 : 0;
      const x1 = this.items[index];
      const x2 = this.items[upIndex];
      x1.index = upIndex;
      x2.index = index;
      await this.controller.updateCustomeField(x1);
      await this.controller.updateCustomeField(x2);
      this.refresh();
    },
    async down(item: any) {
      const index = this.items.indexOf(item);
      const downIndex = index < this.items.length - 1 ? index + 1 : this.items.length - 1;
      const x1 = this.items[index];
      const x2 = this.items[downIndex];
      x1.index = downIndex;
      x2.index = index;
      await this.controller.updateCustomeField(x1);
      await this.controller.updateCustomeField(x2);
      this.refresh();
    },
  },
});
