




























































































































































import Vue from 'vue';
import { Branch, User } from '@/models';
import UI from '@/controller/ui';
import { PropType } from 'vue/types/umd';
import _ from 'lodash';
import { mdiClose } from '@mdi/js';
import UserController from '@/controller/user';
import StringUtils from '@/utils/string-utils';
import BranchController from '@/controller/branch';

export default Vue.extend({
  name: 'EmployeeDialog',
  model: { prop: 'value', event: 'input' },
  props: {
    value: {
      type: Object as PropType<User>,
      default: () => {
        return new User();
      },
    },
    fullscreen: {
      default: true,
    },
    show: {
      default: false,
    },
    title: {
      type: String,
      default: 'CreateForm',
    },
  },
  data: () => ({
    User,
    icons: {
      mdiClose,
    },
    employee: new User(),
    requiredRules: [(v: any) => !!v || 'Dieses Feld ist erforderlich'],
    passwordConfirmation: '',
    controller: new UserController(),
    branchController: new BranchController(),
    validation: false,
    editmode: false,
    branchPermissions: <number[]>[],
    branches: <Branch[]>[],
    isActive: true,
  }),
  computed: {
    passwordRules() {
      if (this.editmode && StringUtils.isEmpty(this.employee.password)) {
        return [];
      }
      return [
        (v: string) => !!v || 'Passwort wird benötigt',
        (v: string) => v.length > 7 || 'Passwort muss 8-stellig sein',
      ];
    },
    passwordConfirmRules(): any {
      if (this.editmode && StringUtils.isEmpty(this.employee.password)) {
        return [];
      }
      return [(v: string) => v == this.employee.password || 'Das Passwort muss Ihrem Bestätigungspasswort entsprechen'];
    },
  },
  watch: {
    isActive(n, o) {
      this.employee.blocked = !n;
    },
  },
  mounted() {
    if (this.value.id != 0) {
      this.employee = Object.assign(new User(), this.value);
      this.editmode = this.employee.id != 0;
    }
    this.branchController.init().then(x => {
      this.branches = x.filter(x => x.status);
      if (this.editmode) {
        this.branchController.getPermission(this.employee.id).then(perm => {
          this.branchPermissions =
            perm?.branches?.map(branch => {
              return branch.id;
            }) ?? [];
        });
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      (this.$refs.employee as any).validate();
      if (!this.validation) {
        UI.snackOpen('Formular ist nicht gültig', 'error');
        console.warn('Formular ist nicht gültig');
        return;
      }
      if (!this.editmode) {
        this.controller
          .insertUser(this.employee, this.branchPermissions)
          .then((x: any) => {
            //success
            UI.snackOpen(__('Success'));
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Branch not created : ' + x.message, 'error');
          });
      } else {
        this.controller
          .updateUser(this.employee, this.branchPermissions)
          .then((x: any) => {
            //success
            // UI.snackOpen(__('Success'))
            this.$emit('save', x);
          })
          .catch((x: any) => {
            //error
            UI.snackOpen('Filiale nicht aktualisiert : ' + x.message, 'error');
          });
      }
    },
  },
});
