import BaseController from './base';

/**
 *  @description optimized 19.04.2022
 */
export default class DownloadController extends BaseController {
  constructor() {
    super();
  }

  public static downloadBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d') as any;
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  /**
   * @description allways download on new tab
   * @param base64Data data
   * @param mimeType 'text/plain'
   * @param fileName 'noname'
   * @param convertBom if true then convert UTF8 with BOM
   */
  public static download(
    base64Data: string,
    mimeType: string = 'text/plain',
    fileName: string = 'noname',
    convertBom: boolean = false,
  ) {
    const anchor = document.createElement('a');
    const data = convertBom ? this.convertBom(base64Data) : base64Data;
    anchor.href = 'data:' + mimeType + ';charset=utf-8,' + encodeURIComponent(data);
    anchor.target = '_blank';
    anchor.download = fileName;
    anchor.click();
  }

  public static convertBom(data: string): string {
    if (data.codePointAt(0) == 0xfeff) return data;
    else return '\uFEFF' + data;
  }

  /**
   *
   * @param url download url
   * @param fileName noname
   * @param newWindow if true then window.open else a.click
   */
  public static downloadURL(url: string, fileName: string = 'noname', newWindow: boolean = false) {
    if (newWindow) {
      window.open(url);
    } else {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';
      anchor.download = fileName;
      anchor.click();
    }
  }

  /**
   *
   * @param buffer BlobPart
   * @param mimeType 'text/plain'
   * @param fileName 'noname'
   */
  public static downloadBlob(buffer: any, mimeType: string = 'text/plain', fileName: string = 'noname') {
    var blob = new Blob([buffer], { type: mimeType });
    var objectUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.target = '_blank';
    anchor.download = fileName;
    anchor.click();
  }
}
