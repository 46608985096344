import { repoName } from '@/controller/reflections'
import { Appointment } from '@/models'
import { Repository } from '@/uow/unit-of-work'

@repoName('AppointmentRepository')
export default class AppointmentRepository extends Repository<Appointment> {
  constructor() {
    super('appointments')
  }
  public convertType(value: object): Appointment {
    return Object.assign(new Appointment(), value)
  }
}
