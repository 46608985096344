




















































































































































import Configs from '@/config/config';
import Cache from '@/controller/cache';
import DownloadController from '@/controller/download';
import UI from '@/controller/ui';
import FilterHelper from '@/helpers/filter-helper';
import { Setting, File as FileModel } from '@/models';
import { UnitOfWork } from '@/uow/unit-of-work';
import { RequiredPdfMode } from '@/enums';
import { mdiPaperclip, mdiSync, mdiEyeOutline } from '@mdi/js';
import Editor from '@tinymce/tinymce-vue';
import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'CompanySettingsAdvanced',
  components: { Editor },
  data: () => ({
    RequiredPdfMode,
    term_file: null,
    privacy_file: null,
    required_file: null,
    radio: 0,
    icons: {
      mdiPaperclip,
      mdiEyeOutline,
      mdiSync,
    },
    editorConfig: {
      plugins: ['code save', 'autolink lists', 'image', 'preview table link'],
      save_onsavecallback: function () {
        console.log('Saved');
      },
      height: 400,
      toolbar: [
        'formatselect | fontselect | removeformat | checklist | casechange | link',
        'bold italic strikethrough forecolor backcolor formatpainter | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent ',
      ],
      content_style: `p {
  margin: 5px 0 0 0;
}`,
    },
    setting: new Setting(),
    uow: UnitOfWork.instance,
  }),
  computed: {
    fileRule() {
      const ruleFunction = (v: any) => v.type.indexOf('pdf') > 0 || 'Please upload in pdf format.';
      return [
        (v: any) => !v || ruleFunction(v),
        (value: any) => value?.size < 5000000 || 'File size should be less than 5 MB!',
      ];
    },
  },
  mounted() {
    let filter = new FilterHelper().equal('test_center.id', Cache.testCenter.id).lazyLoad();
    this.uow.Settings.whereOne(filter).then((x: Setting | null) => {
      if (x) {
        this.setting = x;
        if (this.setting.term_pdf) {
          let term_name = this.setting.term_pdf.name ?? '';
          this.term_file = new File([], term_name, { type: 'application/pdf' }) as any;
        }
        if (this.setting.privacy_pdf) {
          let datum_name = this.setting.privacy_pdf.name ?? '';
          this.privacy_file = new File([], datum_name, { type: 'application/pdf' }) as any;
        }
        if (this.setting.required_pdf) {
          let datum_name = this.setting.required_pdf.name ?? '';
          this.required_file = new File([], datum_name, { type: 'application/pdf' }) as any;
        }
      }
    });
  },
  methods: {
    async requiredChange() {
      console.log('AGB Change =>', this.required_file);

      if (_.isNull(this.required_file)) {
        if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
          this.setting.required_pdf = null as any;
          this.save();
        }
        return;
      }

      let file = new FileModel();
      file.name = (this.required_file as any as File).name;
      file.content = this.required_file as any;
      await this.uow.Storage.customeInsert(file, 'api::setting.setting', 'required_pdf', this.setting.id);
    },
    async termChange() {
      console.log('AGB Change =>', this.term_file);

      if (_.isNull(this.term_file)) {
        if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
          this.setting.term_pdf = null as any;
          this.save();
        }
        return;
      }

      let file = new FileModel();
      file.name = (this.term_file as any as File).name;
      file.content = this.term_file as any;
      await this.uow.Storage.customeInsert(file, 'api::setting.setting', 'term_pdf', this.setting.id);
    },
    async privacyChange() {
      console.log('Datenschutz Change =>', this.privacy_file);

      if (_.isNull(this.privacy_file)) {
        if (confirm('Sind Sie sicher, dass Sie löschen möchten')) {
          this.setting.privacy_pdf = null as any;
          this.save();
        }
        return;
      }

      let file = new FileModel();
      file.name = (this.privacy_file as any as File).name;
      file.content = this.privacy_file as any;
      await this.uow.Storage.customeInsert(file, 'api::setting.setting', 'privacy_pdf', this.setting.id);
    },
    save() {
      if (this.setting.email_text.length > 15000) {
        UI.snackOpen('Fehler : Die maximale Zeichenanzahl für Mail beträgt 15000.', 'error');
        return;
      }

      if (this.setting.required_pdf_mode == RequiredPdfMode.Custome && !this.required_file) {
        UI.snackOpen('Einverständniserklärung PDF-Datei erforderlich.', 'error');
        return;
      }

      this.setting = _.omit(this.setting, ['required_pdf', 'privacy_pdf', 'term_pdf']);
      this.uow.Settings.update(this.setting).then(async (x: Setting | null) => {
        UI.snackOpen(__('Success'));

        await this.Session.reloadTestCenter();
        location.reload();
      });
    },
    downloadEUK() {
      DownloadController.downloadURL('docs/Einverständniserklärung und Kundeninformation.pdf', undefined, true);
    },
    async reset() {
      if (confirm('Möchten Sie wirklich zurücksetzen?')) {
        this.setting.email_text = await axios
          .get(`${Configs.app.page_url}/${Configs.templatePath}/default_email.html`)
          .then(x => x.data);
        await this.save();
      }
    },
  },
});
